import { useRemovePromoCodeMutation } from '@pizza-hut-us-development/client-core';

type UseYumAddedCouponType = [{
  handleRemoveYumPromoCode: (code: string) => Promise<void>;
}];

const useYumAddedCoupons = (): UseYumAddedCouponType => {
  const [removePromoCode] = useRemovePromoCodeMutation();

  const handleRemoveYumPromoCode = async (code: string) => {
    try {
      await removePromoCode(code);
    } catch (error) {
      console.error({ error });
    }
  };

  return [
    {
      handleRemoveYumPromoCode
    }
  ];
};

export default useYumAddedCoupons;
