import phdapiLocalizationService from './phdapiLocalizationService';

export function getLocalizationService() : LocalizationService {
  return process.env.REACT_APP_EMBEDDED === 'true'
    ? lazyLoadAngularBackedService()
    : phdapiLocalizationService;
}

function lazyLoadAngularBackedService() : LocalizationService {
  // lazy-requiring this because the angular localization service
  // blows up if Nextjs tries to run it server-side
  // (currently depends on the global window object, which is not available server-side)
  /* eslint-disable  global-require */
  return require('./angularLocalizationService').default;
}

export default getLocalizationService();
