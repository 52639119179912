import isClientSide from '@/common/isClientSide';
import { getOrInitializeOptimizely } from '../optimizely';
import { OPTIMIZELY_USER_ID } from '../constants';

const getCookie = (key: string) => {
  if (!isClientSide()) return '';
  try {
    const encodedKey = `${encodeURIComponent(key)}=`;
    const cookieArray = document.cookie.split('; ');

    for (let i = 0; i < cookieArray.length; i += 1) {
      const cookie = cookieArray[i];

      if (cookie.indexOf(encodedKey) === 0) {
        return decodeURIComponent(cookie.substring(encodedKey.length, cookie.length));
      }
    }

    return '';
  } catch (error) {
    return '';
  }
};

// Helper function to be used when unable to use Optimizely hooks to retrieve flag status.
const isOptimizelyFeatureEnabled = (key: string): boolean => {
  const optimizelyInstance = getOrInitializeOptimizely({ isBrowser: isClientSide() });
  if (optimizelyInstance === null) return false;

  const optimizelyUserId = getCookie(OPTIMIZELY_USER_ID);
  return optimizelyInstance.isFeatureEnabled(key, optimizelyUserId);
};

export default isOptimizelyFeatureEnabled;
