const mockDataAnalytics = {
  page: {
    name: 'home',
    currency: 'USD',
    responsiveLayout: 'medium',
    categorization: {
      level1: 'Menu',
      level2: 'pizza',
      level3: 'popular-pizzas',
      level4: 'home'
    },
    remember: {
      user: true,
      name: true,
      store: '034131',
      occasion: 'C',
      cart: true
    },
    store_search_occasion: 'C',
    experience: 'm_af23bf13760a1bd2bbe8bad797e2bfe7',
    abTestVersion: 'light site'
  },
  user: {
    loginStatus: 'Guest',
    id: 'Guest',
    rewards: {
      member: 'Guest'
    },
    purchases: {
      future: true
    },
    promiseTimes: {
      delivery: {
        initial: 2100
      },
      carryout: {
        initial: 900
      }
    }
  },
  promoImpressions: [
    {
      id: 'lineup',
      name: '$5Lineup_HH_2301x927_M.jpg',
      creative: '$5Lineup_HH_2301x927_M.jpg',
      position: 'Hero Image Slide 1',
      index: 0
    },
    {
      id: 'SMORESCOOKIE',
      name: 'PH_Smores_Cookie_Sidekick_1314x714.jpg',
      creative: 'PH_Smores_Cookie_Sidekick_1314x714.jpg',
      position: 'Sidekick Image Slide 1',
      index: 1
    },
    {
      id: 'https://www.pizzahut.com/index.php#/menu/deals',
      name: 'PH_Local+Deal_Sidekick_1314x714.jpg',
      creative: 'PH_Local+Deal_Sidekick_1314x714.jpg',
      position: 'Sidekick Image Slide 2',
      index: 2
    },
    {
      id: 'https://www.pizzahut.com/#/menu/drinks',
      name: 'Pepsi4PK_SK_MBTB.jpg',
      creative: 'Pepsi4PK_SK_MBTB.jpg',
      position: 'Sidekick Image Slide 3',
      index: 3
    }
  ],
  store: {
    contactlessCurbsideAvailable: '​N',
    id: '034131',
    franchiseeCode: 'AIL',
    city: 'Chicago',
    state: 'IL',
    zip: 60657,
    rewards: true,
    posType: 'SUS',
    dma: '602'
  },
  cart: {
    products: [
      {
        eligibleAsReward: false,
        startRecipeSKU: 'L_H_PS4',
        startPrice: '13.99',
        finalRecipeSKU: 'L_H_PS4',
        id: 'P~L~B~~H',
        name: 'Create Your Own Pizza',
        finalPrice: '13.99',
        quantity: '1',
        size: 'L',
        calories: 290,
        redeemedAsReward: 'no'
      },
      {
        eligibleAsReward: false,
        startRecipeSKU: 'L_H_.B4GP4.M4.O4PK4PS4',
        startPrice: '18.99',
        finalRecipeSKU: 'L_H_.B4GP4.M4.O4PK4PS4',
        id: 'P~L~S~~H',
        name: 'Supreme Pizza',
        finalPrice: '18.99',
        quantity: '1',
        size: 'L',
        calories: 420,
        redeemedAsReward: 'no'
      }
    ]
  }
};

export default mockDataAnalytics;
