import angularHost
  from '../../../../embedded-web2/src/embedding-framework/angular-adapters/angularHost';
import { Occasion } from '../../localization/constants';
import { StoreStatus } from '../../localization/localizationRail/StoreTile/constants';
import promiseWithTimeout
  from '../../graphql/promiseWithTimeout';
import connectionConfig from '../connectionConfig';

const doStoreSearch = async (
  searchMethod: () => Promise<Array<StoreDetail>>,
  transformMethod: (searchResult: Array<StoreDetail>) => StoreSearchResult
) => {
  try {
    const result = await promiseWithTimeout(searchMethod(), connectionConfig.requestTimeoutMs);
    return transformMethod(result);
  } catch (e: any) {
    if (e.message === connectionConfig.requestTimeoutErrorMessage) {
      throw e;
    }

    return { found: false, stores: [] };
  }
};

const angularLocalizationService: LocalizationService = {
  /**
     * Service call to get the CARRYOUT STORE SEARCH results
     * findNearByStoresByAddress() is the Angular method
     * in the LOCALIZATION SERVICE
     */
  async findCarryoutStores(
    carryoutSearchDetails: CarryoutSearchDetails
  ): Promise<StoreSearchResult> {
    const angularService = angularHost.localizationService();

    const result = await doStoreSearch(() => angularService
      .findNearByStoresByAddress(Occasion.CARRYOUT,
        undefined,
        undefined,
        // @ts-ignore
        carryoutSearchDetails.zipcode,
        undefined,
        undefined,
        carryoutSearchDetails.city,
        carryoutSearchDetails.state,
        undefined,
        carryoutSearchDetails.lat,
        carryoutSearchDetails.lng),
      (response: any) => ({ found: true, stores: response.stores.map(transformCarryout) }));

    return result;
  },

  async findCarryoutStoresV2(carryoutSearchDetails: CarryoutSearchDetails): Promise<StoreSearchResult> {
    const angularService = angularHost.localizationService();

    const result = await doStoreSearch(() => angularService
      .findNearByStoresByAddress(Occasion.CARRYOUT,
        undefined,
        undefined,
        // @ts-ignore
        carryoutSearchDetails.zipcode,
        undefined,
        undefined,
        carryoutSearchDetails.city,
        carryoutSearchDetails.state,
        undefined,
        carryoutSearchDetails.lat,
        carryoutSearchDetails.lng),
      (response: any) => ({ found:true, stores: response.stores.map(transformCarryout) }));

    return result;
  },

  async findCarryoutStoresByLatLong(
    carryoutSearchDetails: CarryoutSearchDetails
  ): Promise<StoreSearchResult> {
    const angularService = angularHost.localizationService();

    const result = await doStoreSearch(() => angularService
      .findNearByStoresByLatLng(Occasion.CARRYOUT,
        undefined,
        undefined,
        // @ts-ignore
        carryoutSearchDetails.lat,
        // @ts-ignore
        carryoutSearchDetails.lng),
      (response: any) => ({ found: true, stores: response.stores.map(transformCarryout) }));

    return result;
  },
  async findCarryoutStoresByLatLongV2(
    carryoutSearchDetails: CarryoutSearchDetails
  ): Promise<StoreSearchResult> {
    const angularService = angularHost.localizationService();

    const result = await doStoreSearch(() => angularService
      .findNearByStoresByLatLng(Occasion.CARRYOUT,
        undefined,
        undefined,
        // @ts-ignore
        carryoutSearchDetails.lat,
        // @ts-ignore
        carryoutSearchDetails.lng),
      (response: any) => ({ found: true, stores: response.stores.map(transformCarryout) }));

    return result;
  },
  async findDeliveryStoresByLatLong(
    deliveryAddress: DeliveryAddress,
    skipGeocode = false
  ): Promise<StoreSearchResult> {
    const angularService = angularHost.localizationService();

    const result = await doStoreSearch(() => angularService
      .findNearByStoresByLatLng(Occasion.DELIVERY,
        undefined,
        undefined,
        // @ts-ignore
        deliveryAddress.lat,
        // @ts-ignore
        deliveryAddress.lng,
        // @ts-ignore
        deliveryAddress.zipcode,
        deliveryAddress.address,
        deliveryAddress.address2,
        deliveryAddress.city,
        deliveryAddress.state,
        undefined,
        undefined,
        undefined,
        skipGeocode ? 'Y' : undefined),
      (response: any) => ({ found: true, ...transformDelivery(response) }));

    return result;
  },
  async findDeliveryStores(deliveryAddress: DeliveryAddress): Promise<StoreSearchResult> {
    const angularService = angularHost.localizationService();

    const result = await doStoreSearch(() => angularService
      .findNearByStoresByAddress(
        Occasion.DELIVERY,
        undefined,
        undefined,
        // @ts-ignore
        deliveryAddress.zipcode,
        // @ts-ignore
        deliveryAddress.address,
        // @ts-ignore
        deliveryAddress.address2,
        deliveryAddress.city,
        deliveryAddress.state,
        undefined,
        undefined,
        undefined
      ),
      (response: any) => ({ found: true, ...transformDelivery(response) }));

    return result;
  },
  async findDeliveryStoresByLatLongV2(
    deliveryAddress: DeliveryAddress,
    skipGeocode = false
  ): Promise<StoreSearchResult> {
    const angularService = angularHost.localizationService();

    const result = await doStoreSearch(() => angularService
      .findNearByStoresByLatLng(Occasion.DELIVERY,
        undefined,
        undefined,
        // @ts-ignore
        deliveryAddress.lat,
        // @ts-ignore
        deliveryAddress.lng,
        // @ts-ignore
        deliveryAddress.zipcode,
        deliveryAddress.address,
        deliveryAddress.address2,
        deliveryAddress.city,
        deliveryAddress.state,
        undefined,
        undefined,
        undefined,
        skipGeocode ? 'Y' : undefined),
      (response: any) => ({ found: true, ...transformDelivery(response) }));

    return result;
  },
  async findDeliveryStoresV2(deliveryAddress: DeliveryAddress): Promise<StoreSearchResult> {
    const angularService = angularHost.localizationService();

    const result = await doStoreSearch(() => angularService
      .findNearByStoresByAddress(
        Occasion.DELIVERY,
        undefined,
        undefined,
        // @ts-ignore
        deliveryAddress.zipcode,
        // @ts-ignore
        deliveryAddress.address,
        // @ts-ignore
        deliveryAddress.address2,
        deliveryAddress.city,
        deliveryAddress.state,
        undefined,
        undefined,
        undefined
      ),
      (response: any) => ({ found: true, ...transformDelivery(response) }));

    return result;
  },

  selectCarryoutStore(
    { storeNumber }: { storeNumber: string }
  ): Promise<ClientResult<StoreDetail>> {
    // Angular Localization Service expects an object with the key StoreNumber
    const angularStore = { StoreNumber: storeNumber };
    const angularLocalizationService = angularHost.localizationService();
    const angularOrderService = angularHost.orderService();
    const cartQty = angularOrderService.data.cartQuantity;
    return angularLocalizationService
      .confirmLocation('C', angularStore, null, null, cartQty);
  },

  selectDeliveryStore(
    storeNumber: string, deliveryAddress: DeliveryAddress
  ): Promise<ClientResult<StoreDetail>> {
    const angularStore = { StoreNumber: storeNumber };
    const angularLocalizationService = angularHost.localizationService();
    const angularOrderService = angularHost.orderService();
    const cartQty = angularOrderService.data.cartQuantity;
    const deliveryInfo = {
      address: deliveryAddress.address,
      address_two: deliveryAddress.address2,
      city: deliveryAddress.city,
      state: deliveryAddress.state.toUpperCase(),
      zip: deliveryAddress.zipcode,
      storeNumber,
      zone: undefined,
      dwellCode: undefined,
      locationName: undefined,
      lat: undefined,
      lng: undefined
    };
    return angularLocalizationService
      .confirmLocation('D', angularStore, deliveryInfo, null, cartQty);
  },

  getStoreInfo(store: string): Promise<{ isDragontail: boolean; error: boolean }> {
    throw new Error('This method should only be invoked from standalone');
  },

  getStoreDetails(): Promise<ClientResult<StoreDetail>> {
    throw new Error('This method should only be invoked from standalone');
  },

  updateLocalizationCookie(): Promise<void> {
    throw new Error('This method should only be invoked from standalone');
  },

  deleteLocalizationCookie(): Promise<void> {
    throw new Error('This method should only be invoked from standalone');
  },

  refreshLocalizationCookie(): Promise<RefreshedTokenResponse> {
    throw new Error('This method should only be invoked from standalone');
  }
};

function checkIfStoreStatusInactive(rawStatus: string, onlineStatus: string): string {
  /*
   * Angular API returns the incorrect `storeStatus` when the store is inactive
   * (it returns as 'T' instead of 'I')
   * that's why, we are setting `storeStatus` from 'T' to 'I' explicitly if the store is inactive
   * in the transform layer.
   */
  return rawStatus === StoreStatus.TEMP_CLOSED && onlineStatus === 'inactive'
    ? StoreStatus.INACTIVE
    : rawStatus;
}

function transformCarryout(angularStoreStructure: any): Omit<StoreDetail, 'orderMaxForOccasion' | 'dragonTail'> {
  const status = checkIfStoreStatusInactive(
    angularStoreStructure.info.status.raw,
    angularStoreStructure.info.onlineStatus
  );

  return {
    storeNumber: angularStoreStructure.StoreNumber,
    address: angularStoreStructure.info.address,
    city: angularStoreStructure.info.city,
    state: angularStoreStructure.info.state,
    zipcode: angularStoreStructure.info.zip.toString(),
    landmark: angularStoreStructure.info.landmark?.toString(),
    phoneNumber: angularStoreStructure.info.phone?.toString(),
    promiseTime: angularStoreStructure.info.promiseTimeCo,
    openTime: angularStoreStructure.info.carryout_open,
    closeTime: angularStoreStructure.info.carryout_close,
    splitOpenTime: angularStoreStructure.info.split_carryout_open || undefined,
    splitCloseTime: angularStoreStructure.info.split_carryout_close || undefined,
    storeStatus: status,
    acceptFutureOrders: angularStoreStructure.info.acceptFutureOrders,
    storeClosureReason: angularStoreStructure.info.tmp_offline_msg,
    storeTimezone: angularStoreStructure.info.timezone,
    lat: parseFloat(angularStoreStructure.lat),
    long: parseFloat(angularStoreStructure.long)
  };
}

function transformDelivery(response: any): DeliverySearchResult {
  if (response?.multiple) {
    return {
      deliveryAddresses: response.multiple.map((angularAddressStructure: any) => ({
        address: angularAddressStructure.address,
        address2: angularAddressStructure.address2,
        city: angularAddressStructure.city,
        state: angularAddressStructure.state,
        zipcode: angularAddressStructure.zip,
        country: angularAddressStructure.country,
        lat: parseFloat(angularAddressStructure.latitude),
        lng: parseFloat(angularAddressStructure.longitude)
      }))
    };
  }

  const stores = response.stores.map((angularStoreStructure: any) => {
    const status = checkIfStoreStatusInactive(
      angularStoreStructure.info.status.raw,
      angularStoreStructure.info.onlineStatus
    );

    const includeBeerWarning = status.includes(Occasion.DELIVERY)
      && !response.address.boozeId
      && response.localizingToSameStore
      && angularHost.orderService().getAlcoholItemInCart();

    return {
      storeNumber: angularStoreStructure.StoreNumber,
      address: angularStoreStructure.info.address,
      city: angularStoreStructure.info.city,
      state: angularStoreStructure.info.state,
      zipcode: angularStoreStructure.info.zip.toString(),
      phoneNumber: angularStoreStructure.info.phone?.toString(),
      promiseTime: angularStoreStructure.info.promiseTimeDel,
      promiseTimeStale: angularStoreStructure.info.promiseTimeDelStale,
      openTime: angularStoreStructure.info.delivery_open,
      closeTime: angularStoreStructure.info.delivery_close,
      splitOpenTime: angularStoreStructure.info.split_delivery_open || undefined,
      splitCloseTime: angularStoreStructure.info.split_delivery_close || undefined,
      storeStatus: status,
      acceptFutureOrders: angularStoreStructure.info.acceptFutureOrders,
      storeClosureReason: angularStoreStructure.info.tmp_offline_msg,
      includeBeerWarning
    };
  });

  return {
    stores
  };
}

export default angularLocalizationService;