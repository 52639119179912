import { SelectedRecipe } from './dealTypes';
import isOptimizelyFeatureEnabled from '../../../../optimizely/utils/isOptimizelyFeatureEnabled';

export const dealWithRecipeAdded = (
  recipes: SelectedRecipe[],
  selectedRecipes: SelectedRecipe[]
): SelectedRecipe[] => {
  const stepIdsFromSelectedRecipes = selectedRecipes.map((recipe) => recipe.stepId);
  const indexFromSelectedRecipes = selectedRecipes.map((recipe) => recipe.index);
  const optimizelyMultiStepDealDecision = isOptimizelyFeatureEnabled('fr-web-2326-use-index-multi-step-deal');
  const updatedRecipes = recipes
    ?.filter((recipe, index) => !stepIdsFromSelectedRecipes.includes(recipe.stepId) || (optimizelyMultiStepDealDecision && !indexFromSelectedRecipes.includes(index))) || [];

  return [
    ...updatedRecipes,
    ...selectedRecipes
  ].sort((a, b) => a && b && (a.index - b.index));
};
