// eslint-disable-next-line max-classes-per-file
import phdApiClient, { StoreSearchApiResult } from '../../api/phdApiClient';
import standaloneApiClient from '../../api/standaloneApiClient';
import StandaloneEndpoints from '../../api/standaloneApiClient/endpoints';
import PhdApiEndpoints from '../../api/phdApiClient/endpoints';
import { parseLocalizationToken } from '../../localization/localizationToken';
import { Occasion, States } from '../../localization/constants';
import logger from '../../common/logger';
import { transformPHDStoreToStoreDetail } from '../../api/phdApiClient/transformPHDData';
import { PhdApiStore } from '@/api/phdApiClient/types';
import phdApiV2Client from '@/api/phdApiV2Client';

/* eslint camelcase: "off" */

export class StoreSearchException extends Error { }
export class RefreshTokenException extends Error { }

const handleCarryoutSearchResponse = ({ found, results, errorCode }: StoreSearchApiResult) => {
  if (found && results) {
    return {
      found: true,
      stores: results.map((store: PhdApiStore) => (
        transformPHDStoreToStoreDetail(store, Occasion.CARRYOUT)))
    };
  }
  return { found, results, errorCode };
};

const phdApiLocalizationService: LocalizationService = {
  async findCarryoutStores( carryoutSearchDetails: CarryoutSearchDetails ): Promise<StoreSearchResult> {
    return findStores(carryoutSearchDetails, 'CARRYOUT')
      .then(handleCarryoutSearchResponse);
  },
  findCarryoutStoresV2(carryoutSearchDetails: CarryoutSearchDetails): Promise<StoreSearchResult> {
    return findStoresV2(carryoutSearchDetails, 'CARRYOUT')
      .then(handleCarryoutSearchResponse);
  },
  findCarryoutStoresByLatLong( carryoutSearchDetails: CarryoutSearchDetails ): Promise<StoreSearchResult> {
    return findStores(carryoutSearchDetails, 'CARRYOUT')
      .then(handleCarryoutSearchResponse);
  },
  findCarryoutStoresByLatLongV2( carryoutSearchDetails: CarryoutSearchDetails ): Promise<StoreSearchResult> {
    return findStoresV2(carryoutSearchDetails, 'CARRYOUT')
      .then(handleCarryoutSearchResponse);
  },
  findDeliveryStoresByLatLong(
    deliveryAddress: DeliveryAddress,
    skipGeocode = false
  ): Promise<StoreSearchResult> {
    return findStores(deliveryAddress, 'DELIVERY', skipGeocode)
      .then(({ found, results }) => {
        const transform = transformDeliveryResults(results);

        return {
          found,
          stores: transform.stores,
          deliveryAddresses: transform.deliveryAddresses,
          errorCode: transform.errorCode
        };
      });
  },

  findDeliveryStores(
    deliveryAddress: DeliveryAddress,
    prevStoreNumber?: string,
    hasAlcoholInCart?: boolean
  ): Promise<StoreSearchResult> {
    return findStores(deliveryAddress, 'DELIVERY')
      .then(({ found, results }) => {
        const transform = transformDeliveryResults(results, prevStoreNumber, hasAlcoholInCart);

        return {
          found,
          stores: transform.stores,
          deliveryAddresses: transform.deliveryAddresses,
          errorCode: transform.errorCode
        };
      });
  },
  findDeliveryStoresByLatLongV2(
    deliveryAddress: DeliveryAddress,
    skipGeocode = false
  ): Promise<StoreSearchResult> {
    return findStoresV2(deliveryAddress, 'DELIVERY', skipGeocode)
      .then(({ found, results }) => {
        const transform = transformDeliveryResults(results);

        return {
          found,
          stores: transform.stores,
          deliveryAddresses: transform.deliveryAddresses,
          errorCode: transform.errorCode
        };
      });
  },

  findDeliveryStoresV2(
    deliveryAddress: DeliveryAddress,
    prevStoreNumber?: string,
    hasAlcoholInCart?: boolean
  ): Promise<StoreSearchResult> {
    return findStoresV2(deliveryAddress, 'DELIVERY')
      .then(({ found, results }) => {
        const transform = transformDeliveryResults(results, prevStoreNumber, hasAlcoholInCart);

        return {
          found,
          stores: transform.stores,
          deliveryAddresses: transform.deliveryAddresses,
          errorCode: transform.errorCode
        };
      });
  },
  selectCarryoutStore(
    store: { storeNumber: string; storeToken: string; }
  ): Promise<ClientResult<StoreDetail>> {
    return this.getStoreDetails(store.storeNumber, Occasion.CARRYOUT, store.storeToken);
  },

  selectDeliveryStore(
    storeNumber: string, deliveryAddress: DeliveryAddress, storeToken: string
  ): Promise<ClientResult<StoreDetail>> {
    return this.getStoreDetails(storeNumber, Occasion.DELIVERY, storeToken);
  },

  async getStoreDetails(
    storeNumber: string,
    occasion: Occasion,
    storeToken?: string,
    didLocalizationTokenChange = true
  ): Promise<ClientResult<StoreDetail>> {
    return phdApiClient.getStoreDetails(
      PhdApiEndpoints.GET_STORE,
      occasion,
      didLocalizationTokenChange,
      [storeNumber],
      storeToken
    );
  },
  updateLocalizationCookie(): Promise<void> {
    return standaloneApiClient.post({}, StandaloneEndpoints.UPDATE_LOCALIZATION_COOKIE);
  },
  deleteLocalizationCookie(): Promise<void> {
    return standaloneApiClient.post({}, StandaloneEndpoints.DELETE_LOCALIZATION_COOKIE);
  },
  refreshLocalizationCookie(token): Promise<RefreshedTokenResponse> {
    return phdApiClient.post({ token }, PhdApiEndpoints.REFRESH_LOCALIZATION_TOKEN)
      .then(async ({ response: { token: newToken }, status }) => {
        if (status !== 200) {
          const error = new RefreshTokenException(`Unexpected response when refreshing expired localization token, ${status}`);
          logger.withoutTelemetry.error(error.message);
          throw error;
        }
        await standaloneApiClient.post(
          { token: newToken },
          StandaloneEndpoints.SAVE_LOCALIZATION_TOKEN
        );

        return { success: true, token: newToken };
      });
  },

  async getStoreInfo(store: string): Promise<{ isDragontail: boolean; error: boolean }>  {
    return phdApiClient.getStoreInfo(store);
  }
};

function transformDeliveryResults(
  response: any,
  prevStoreNumber?: string,
  hasAlcoholInCart?: boolean
): DeliverySearchResult {
  if (response?.possible_matches) {
    if (response.possible_matches.length === 0) return { errorCode: response?.error_code };

    return {
      deliveryAddresses: response?.possible_matches.map((deliveryAddress: DeliveryPossibleMatchAddress) => {
        const trimmedState = deliveryAddress.state.trim();
        const state = States.hasOwnProperty(trimmedState) ? States[trimmedState as keyof typeof States] : '';

        return {
          address: deliveryAddress.address.trim(),
          address2: deliveryAddress.address2?.trim(),
          city: deliveryAddress.city.trim(),
          lat: deliveryAddress.latitude?.trim(),
          lng: deliveryAddress.longitude?.trim(),
          state,
          zipcode: deliveryAddress.postal_code.trim(),
        };
      })
    };    
  }

  if (response?.message === 'dta exception') return { errorCode: response?.error_code };

  const getBeerWarningStatus = (storeToken: string, storeNumber: string) => {
    const token = parseLocalizationToken(storeToken);
    return prevStoreNumber === storeNumber
      && token && !token.boozeId
      && hasAlcoholInCart;
  };

  return {
    stores: response.map((store: PhdApiStore) => ({
      ...transformPHDStoreToStoreDetail(store, Occasion.DELIVERY),
      includeBeerWarning: getBeerWarningStatus(store.token, store.store_number)
    }))
  };
}

function findStores(
  searchDetails: CarryoutSearchDetails | DeliveryAddress,
  occasion: string,
  skipGeocode?: boolean
): Promise<StoreSearchApiResult> {
  const body = {
    address1: (searchDetails as DeliveryAddress).address ?? '',
    address2: (searchDetails as DeliveryAddress).address2 ?? '',
    city: searchDetails.city ?? '',
    state: searchDetails.state ?? '',
    postal_code: searchDetails.zipcode ?? '',
    geocode_validate: skipGeocode === undefined ? skipGeocode : !skipGeocode,
    latitude: searchDetails.lat?.toString(),
    longitude: searchDetails.lng?.toString(),
    limit: 0,
    occasion_id: occasion
  };

  return phdApiClient.findStores(body);
}

function findStoresV2(
  searchDetails: CarryoutSearchDetails | DeliveryAddress,
  occasion: string,
  skipGeocode?: boolean,
): Promise<StoreSearchApiResult> {
  const body = {
    address1: (searchDetails as DeliveryAddress).address ?? '',
    address2: (searchDetails as DeliveryAddress).address2 ?? '',
    city: searchDetails.city ?? '',
    state: searchDetails.state ?? '',
    postal_code: searchDetails.zipcode ?? '',
    geocode_validate: skipGeocode === undefined ? skipGeocode : !skipGeocode,
    latitude: searchDetails.lat?.toString(),
    longitude: searchDetails.lng?.toString(),
    limit: 0,
    occasion_id: occasion
  };

  return phdApiV2Client.findStoresV2(body);
}

export default phdApiLocalizationService;
