import { CartItemInput } from '@pizza-hut-us-development/client-core';
import {
  Deal,
  DealData,
  UserSelections
} from '@/builders/deals/slice/dealTypes';
import { DISCOUNT, PIZZA, STEP } from '@/domain/constants';
import { legacyPizzaTransformer } from './legacyPizzaTransformer';
import { BuiltPizza } from '@/builders/pizza/dataTransformers/builderTypes';
import { legacyProductTransformer } from './legacyProductTransformer';
import { Product } from '@/domain/product/types';

const transformUserSelections = (selections: UserSelections): CartItemInput[] => selections.recipes.map((selected) => {
  switch (selected.item.type) {
    case PIZZA:
      return legacyPizzaTransformer(selected.item as BuiltPizza);
    default:
      return legacyProductTransformer(selected.item as Product);
  }
});

const transformDealSteps = (deal: DealData, transformedSelections: CartItemInput[]): CartItemInput[] => {
  // Keep track of previous selections used, need this for deals that have multiple of the same step
  const prevStepSelections: CartItemInput[] = [];

  return deal.steps.map((step) => {
    const stepSelections = transformedSelections.filter(
      (selection) => step.recipeOptions.find((recipe) => selection.id.includes(recipe.id)) !== undefined
    );

    const stepToUse = stepSelections.find((selection) => {
      if (!prevStepSelections.includes(selection)) {
        prevStepSelections.push(selection);
        return true;
      }
      return false;
    });

    const transformedDealStep: CartItemInput = {
      id: step.id,
      name: step.name,
      description: step.description ?? '',
      modifiers: stepToUse === undefined ? [] : [stepToUse],
      type: STEP
    };

    return transformedDealStep;
  });
};

const transformDealId = (deal: Deal): string => {
  const { id } = deal.data;
  const { publicCode } = deal.userSelections.present.dealToDisplay;
  if (!publicCode) {
    return id;
  }
  const name = id.split('/');
  name.pop();
  return `${name.join('/')}/${publicCode}`;
};

export const legacyDealTransformer = (item: Deal): CartItemInput => {
  const { data, userSelections } = item;

  const transformedSelections = transformUserSelections(userSelections.present);
  const modifiers = transformDealSteps(data, transformedSelections);

  const transformedItem: CartItemInput = {
    id: transformDealId(item) ?? '',
    name: data.name,
    type: DISCOUNT,
    quantity: 1,
    modifiers
  };

  return transformedItem;
};
