import React from 'react';
import Grid from '@material-ui/core/Grid';
import CalorieDisplay from '../../../../builders/pizza/setters/CalorieDisplay';
import { DisplayableModifier } from '../../../../domain/product/types';
import useStyles from './styles';

interface RenderNutritionInfoProps {
  productId: string;
  selectedSize?: DisplayableModifier;
  isMenuTile?: boolean;
  selectedAdditionalOption?: DisplayableModifier;
}

const RenderNutritionInfo = (props: RenderNutritionInfoProps): JSX.Element => {
  const {
    productId,
    selectedSize,
    selectedAdditionalOption,
    isMenuTile = true
  } = props;
  const classes = useStyles({ isMenuTile });

  const nutritionInfo = [
    ...(selectedSize?.nutrition ?? []),
    ...(selectedAdditionalOption?.nutrition ?? [])
  ];

  return (
    <Grid item data-testid="nutrition-info" className={classes.nutrition} key={productId}>
      {nutritionInfo.map((nutrition, index) => (
        <CalorieDisplay
          key={`nutrition-${index}`}
          label="nutrition-info"
          nutrition={nutrition}
          includeProductName={nutritionInfo.length > 1}
          isProduct
        />
      ))}
    </Grid>
  );
};

export default RenderNutritionInfo;
