import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line max-len
import {
  CreditCardSavedPayment,
  CustomerAddress,
  CustomerResponse,
  GiftCardSavedPayment,
  Promotion,
  SavedPayment,
  useCustomerInfoQuery
} from '@pizza-hut-us-development/client-core';
import { useEffect } from 'react';
import phdApiAccountManagementService from '@/services/accountManagementService/phdApiAccountManagementService';
import { actions as profileActions, selectors } from '@/account/profile/profile.slice';
import { customerDetailsFailure, customerDetailsSuccess } from '@/header/actions';
import { UseCustomerDetails } from '@/account/profile/hooks/types';
import customerService from '@/services/customerService';
import isOptimizelyFeatureEnabled from '../../../../optimizely/utils/isOptimizelyFeatureEnabled';
import { RootState } from '@/rootStateTypes';
import { transformCustomerInfo, transformPromos } from '@/account/profile/hooks/transformCustomerInfo';
import { userDomainSelectors } from '@/header/userDomainSelectors';
import { CreditCardInfo, GiftCardInfo, PaymentType } from '@/domain/payments/types';
import { ProfileAddress, Promotions, Profile } from '@/domain/Profile';

const transformCustomerAddresses = (customerAddresses: CustomerAddress[]): ProfileAddress[] => customerAddresses.map(
  (address): ProfileAddress => ({
    addressId: address?.customerAddressId ?? address?.addressId ?? '',
    address1: address.address1,
    address2: address.address2,
    city: address.city,
    isDefault: address.isDefault ?? false,
    name: address.name,
    postalCode: address.postalCode,
    state: address.state,
    locationType: address.type,
    deliveryInstructions: address.deliveryInstructions ?? '',
    phone: address.phone ?? ''
  })
);

const transformCreditCard = (payment: SavedPayment): CreditCardInfo | GiftCardInfo => ({
  isDefault: payment.isDefault ?? false,
  paymentId: payment.paymentId,
  name: '',
  paymentType: payment.type as PaymentType.creditcard,
  cardInfo: {
    cardType: payment.type,
    expiration: (payment as CreditCardSavedPayment).metadata.expiration,
    lastFour: (payment as CreditCardSavedPayment).metadata.lastFour || '',
    postalCode: (payment as CreditCardSavedPayment).metadata.postalCode
  }
});

const transformGiftCard = (payment: SavedPayment): GiftCardInfo => ({
  isDefault: payment.isDefault ?? false,
  paymentId: payment.paymentId,
  paymentType: payment.type as PaymentType.giftcard | PaymentType.tpgc,
  cardInfo: {
    number: (payment as GiftCardSavedPayment).metadata.number || '',
    balance: (payment as GiftCardSavedPayment).metadata.balance || 0
  }
});

const transformPromotions = (res: Promotion[]): Promotions[] => res?.map((promotion) => ({
  promotionId: promotion.promotionId,
  birthDate: new Date(promotion.birthDate).toJSON(),
  contactMethods: promotion.contactMethods.map((contact) => ({
    type: contact.type,
    value: contact.value
  }))
}));

const transformPayments = (res: SavedPayment[]): (CreditCardInfo | GiftCardInfo)[] => res?.map((payment) => (payment?.type === 'creditcard' ? transformCreditCard(payment) : transformGiftCard(payment)));

const transformCustomerDetails = (customerDetails: CustomerResponse): Profile => {
  const {
    firstName = '',
    lastName,
    email,
    phone,
    customerAddresses,
    payments,
    loyaltyToken = '',
    promotions
  } = customerDetails;
  return {
    firstName,
    lastName,
    email,
    phone,
    loyaltyToken,
    customerAddresses: transformCustomerAddresses(customerAddresses),
    payments: payments ? transformPayments(payments) : [],
    promotions: promotions ? transformPromotions(promotions) : []
  };
};

const promos = (data: CustomerResponse) => ({
  hutRewardsMember: !!data?.programs?.find((program) => program.programId === '1'),
  birthdaySent: !!data?.promotions?.find((promotion) => promotion.birthDate),
  hutLoversMarketingOptIn: !!data?.promotions?.find((promotion) => promotion?.contactMethods.find((contact) => contact.type === 'EMAIL')),
  textOptIn: !!data?.promotions?.find((promotion) => promotion?.contactMethods.find((contact) => contact.type === 'TEXT'))
});

const useCustomerDetails: UseCustomerDetails = () => {
  const loading = useSelector(selectors.loading);
  const error = useSelector(selectors.error);
  const profile = useSelector(selectors.profile);
  const dispatch = useDispatch();
  const isUseCustomerInfoQueryHookEnabled = isOptimizelyFeatureEnabled('fr-web-3611-use-custmer-info-query-hook');
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);
  const isAuthenticatedUser = useSelector(userDomainSelectors.isAuthenticatedUser);
  const {
    data,
    isLoading,
    isError,
    refetch: refetchCustomerInfo,
    isUninitialized
  } = useCustomerInfoQuery(undefined, {
    skip: !isUseCustomerInfoQueryHookEnabled || !isAuthenticatedUser
  });

  useEffect(() => {
    const getLoyaltyInfo = async () => {
      const loyalty = await customerService.getCustomerLoyalty();
      if (loyalty?.coupons) {
        dispatch(profileActions.updateCoupons(loyalty.coupons));
      }
    };
    if (!isLoading) {
      const result = data && transformCustomerInfo(data);
      if (isError) {
        dispatch(profileActions.error());
        dispatch(customerDetailsFailure());
      }

      if (result && result.email) {
        getLoyaltyInfo();
        dispatch(profileActions.success(result));
        dispatch(
          customerDetailsSuccess({
            ...result,
            id: data.customerId,
            ...promos(data)
          })
        );
      }
    }
  }, [data, dispatch, isError, isLoading]);

  useEffect(() => {
    if (!isUninitialized && isAuthenticatedUser) {
      refetchCustomerInfo();
    }
  }, [isUninitialized, refetchCustomerInfo, isYumEcomm, isAuthenticatedUser]);

  const fetchCustomerDetails = async () => {
    if (isUseCustomerInfoQueryHookEnabled && !isUninitialized) {
      refetchCustomerInfo();
      return;
    }

    dispatch(profileActions.loading());

    const { error: apiError, result } = await phdApiAccountManagementService.myAccount.getCustomer();

    const loyalty = await customerService.getCustomerLoyalty();

    if (apiError) {
      dispatch(profileActions.error());
      dispatch(customerDetailsFailure());
    }

    if (result?.new) {
      dispatch(profileActions.success(result.new));
      if (loyalty?.coupons) {
        dispatch(profileActions.updateCoupons(loyalty.coupons));
      }
      dispatch(
        customerDetailsSuccess({
          ...result.old,
          availablePoints: loyalty?.availablePoints
        })
      );
    }
  };

  return {
    loading,
    error,
    profileData: profile,
    fetchCustomerDetails,
    refetchCustomerInfo
  };
};

export default useCustomerDetails;
