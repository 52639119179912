import { MenuRecipe } from '@/menu/pizza/pizzaMenuTypes';

export const PARENT_DEAL = 'MASTER_DISCOUNT';
export const LINEUP = 'Lineup';
export const UPSELL = 'Upsell';
export const DEAL = 'Deals';

export const SWITCH_STORE_TITLE = 'This deal is not available at this store';
export const SWITCH_STORE_CTA = 'SWITCH STORES';
export const SWITCH_STORE_BODY = "The deal you selected isn't available at this store. Do you want to switch to another?";

export const SWITCH_TO_CARRYOUT_TITLE = 'This deal is for carryout only';
export const SWITCH_TO_CARRYOUT_CTA = 'SWITCH TO CARRYOUT';
export const SWITCH_TO_CARRYOUT_BODY = "The deal you selected isn't available for delivery. Do you want to switch to carryout?";

export const SWITCH_TO_DELIVERY_TITLE = 'This deal is for delivery only';
export const SWITCH_TO_DELIVERY_CTA = 'SWITCH TO DELIVERY';
export const SWITCH_TO_DELIVERY_BODY = "The deal you selected isn't available for carryout. Do you want to switch to delivery?";

export const DEAL_NOT_AVAILABLE_TITLE = 'Deal not available';
export const DEAL_NOT_AVAILABLE_CTA = 'OK';
export const DEAL_NOT_AVAILABLE_BODY = "Oh shoot, that deal isn't available at this store. There are other great deals to choose from.";

export const DEAL_UNEXPECTED_ERROR_TITLE = "We're sorry";
export const DEAL_UNEXPECTED_ERROR_CTA = 'OK';
export const DEAL_UNEXPECTED_ERROR_BODY = 'Something went wrong, but there are other great deals to choose from.';

export const DEAL_SUBMIT_BUTTON_TEXT = {
  desktop: 'add to order',
  mobile: 'add',
  edit: 'save'
};

export const WHITELISTED_DEALS = [
  '1001',
  '1005',
  '1012',
  '1020',
  '1024',
  '1029',
  '1030',
  '1031',
  '1032',
  '1033',
  '1034',
  '1035',
  '1036',
  '1037',
  '1039',
  '1043',
  '1044',
  '1045',
  '1046',
  '1047',
  '1048',
  '1049',
  '1051',
  '1052',
  '1053',
  '1054',
  '1055',
  '1056',
  '1057',
  '1058',
  '1059',
  '1060',
  '1061',
  '1062',
  '1063',
  '1064',
  '1065',
  '1066',
  '1067',
  '1068',
  '1069',
  '1070',
  '1071',
  '1072',
  '1073',
  '1074',
  '1075',
  '1076',
  '1077',
  '1078',
  '1079',
  '108-S',
  '1080',
  '1081',
  '1082',
  '1083',
  '1084',
  '1085',
  '1086',
  '1087',
  '1088',
  '1089',
  '1090',
  '1091',
  '1092',
  '1093',
  '1094',
  '1095',
  '1096',
  '1097',
  '1101',
  '1102',
  '1103',
  '1104',
  '1105',
  '1106',
  '1107',
  '1108',
  '1109',
  '1110',
  '1111',
  '1112',
  '1113',
  '1114',
  '1115',
  '1116',
  '1117',
  '1120',
  '1121',
  '1122',
  '1123',
  '1124',
  '1125',
  '1126',
  '1127',
  '1128',
  '1130',
  '1131',
  '1132',
  '1133',
  '1134',
  '1135',
  '1136',
  '1137',
  '1138',
  '1139',
  '1140',
  '1141',
  '1142',
  '1143',
  '1144',
  '1145',
  '1146',
  '1147',
  '1148',
  '1149',
  '1150',
  '1151',
  '1152',
  '1153',
  '1154',
  '1155',
  '1161',
  '1162',
  '1163',
  '1164',
  '1165',
  '1166',
  '1167',
  '1168',
  '1169',
  '1170',
  '1171',
  '1172',
  '1173',
  '1174',
  '1175',
  '1176',
  '1177',
  '1178',
  '1179',
  '1180',
  '2117',
  '2118',
  '2119',
  '2218',
  '2219',
  '2264',
  '2265',
  '2306',
  '2310',
  '3280',
  '3283',
  '4060',
  '7002',
  '8007',
  '8201',
  '8217',
  '8218',
  '8220',
  '8256',
  '8269',
  '8270',
  '8289',
  '8297',
  '8302',
  '8304',
  '8306',
  '8326',
  '8337',
  '8352',
  '8353',
  '8354',
  '8356',
  '8361',
  '8370',
  '8377',
  '8390',
  '8394',
  '8396',
  '8408',
  '8409',
  '8410',
  '8411',
  '8412',
  '8413',
  '8414',
  '8415',
  '8416',
  '8417',
  '8418',
  '8419',
  '8420',
  '8421',
  '8422',
  '8425',
  '8427',
  '8428',
  '8429',
  '8430',
  '8432',
  '8433',
  '8436',
  '8440',
  '8442',
  '8443',
  '8444',
  '8449',
  '8455',
  '8456',
  'BDBPIZZA',
  'BDBWSBOWINGS',
  'BDBWSTRADWINGS',
  'BIGDINNERBOXMASTER',
  'BLAH',
  'BOGO1TEARLY',
  'CB50',
  'CENTRO1020TEST',
  'CENTRO599TEST',
  'CINNABON',
  'CM',
  'CO899',
  'COLLEGE1',
  'COLLEGE2',
  'CQ',
  'EARLY30',
  'FWMBO',
  'GLUTENFREE',
  'HL',
  'HOLLY',
  'L2T799',
  'POWERLUNCH6',
  'STAR',
  'TF4',
  'TMOBILE',
  '10DAYDEAL',
  '10DAYDEAL2',
  '112',
  '1PZ',
  '275',
  '279',
  '2L29',
  '310',
  '313',
  '315',
  '328',
  '335',
  '398',
  '3L29',
  '3T',
  '404',
  '406',
  '411',
  '422',
  '425',
  '430',
  '431',
  '432',
  '433',
  '439',
  '446',
  '448',
  '451',
  '476',
  '479',
  '480',
  '483',
  '486',
  '491',
  '496',
  '499',
  '4D',
  '50',
  '503',
  '507',
  '516',
  '519',
  '520',
  '523',
  '524CM',
  '525',
  '527',
  '530',
  '531',
  '532',
  '533',
  '534',
  '536',
  '542',
  '543',
  '544',
  '545',
  '546',
  '547',
  '548',
  '558',
  '56',
  '561',
  '574',
  '575',
  '577',
  '579',
  '589',
  '591',
  '597',
  '598',
  '5BUCKS',
  '602',
  '603',
  '604',
  '674',
  '675',
  '899',
  '950',
  'AAA',
  'AP4',
  'AV',
  'BIGDIPPER',
  'BJ',
  'BNI2099',
  'BNI2199',
  'BW',
  'BY',
  'CAT-1',
  'DRAFTXHUTGF',
  'EEOBREADGETCHEESESTK',
  'EWS655DEAL',
  'F0',
  'HT',
  'HUTHUT',
  'I1',
  'IB',
  'IF',
  'IG',
  'IP',
  'LARGECARRYOUTDEAL',
  'LM6',
  'LM7',
  'LS1099',
  'MH999',
  'MM',
  'NC',
  'NEWHANDTOSSED',
  'PAN2L14',
  'PAN2L15',
  'PANOD',
  'PE',
  'PN',
  'QJ',
  'REALDEAL',
  'REALDEAL1',
  'REALDEAL2',
  'S3',
  'STFPAN',
  'T5',
  'TENANY',
  'TRINORMOUS',
  'TW',
  'TWENTYPERCENTOFF',
  'XA',
  '10CP',
  '2292',
  '303',
  '311',
  '312',
  '396',
  '397',
  '4035',
  '424',
  '508',
  'CARRYOUT10',
  'CAT8',
  'JI-1',
  'JI-2',
  'JI-3',
  'JI-6',
  'P799',
  'SHAKE1',
  '9',
  '3021',
  '487',
  '502',
  '6102',
  'CHEESYBITES',
  'ELEVENONLINE',
  '10BOX',
  '70',
  'J1',
  'J3',
  'J5',
  'J7',
  'J9Pick',
  'L0',
  'LL',
  'LM',
  'XP',
  '2241',
  '436',
  '7W',
  'JI-5',
  '524',
  '723',
  '724',
  '595',
  'AP5',
  'IWM',
  'FWM',
  'YWM',
  '11',
  '115',
  '132',
  '245',
  '255',
  '256',
  '324',
  '347',
  '348',
  '349',
  '399',
  '553',
  '492',
  '2C',
  '3J',
  '4070',
  '4080',
  'A5',
  'B250',
  'STICKSPEPSI',
  'V6',
  'V8',
  '615',
  '7',
  '8',
  '131',
  '244',
  '253',
  '254',
  '269',
  '271',
  '274',
  '323',
  '345',
  '346',
  '7',
  'CX',
  'PP1399',
  '167',
  '243',
  '51',
  'M2',
  'M3',
  '356',
  '357',
  '358',
  '526',
  '250',
  '362',
  '363',
  '134',
  '21',
  '20',
  '266',
  'LITERACY2016',
  '136',
  '26',
  '261',
  '262',
  '263',
  '361',
  '277',
  '314',
  'FV',
  '447',
  '2',
  '3',
  '130',
  '242',
  '510',
  '452',
  '270',
  '344',
  '342',
  '343',
  '316',
  '317',
  '318',
  '367',
  '368',
  '383',
  '488',
  '319',
  '462',
  '321',
  '322',
  '14',
  'BDBWINGS',
  '15',
  'BDBROLLERS',
  '247',
  '258',
  '514',
  '517',
  '95',
  '91',
  '93',
  '252',
  '102',
  '365',
  '33',
  '41',
  '25',
  '304',
  '305',
  '306',
  '307',
  '308',
  '139',
  '142',
  '249',
  '259',
  '260',
  '360',
  '64',
  '65',
  '278',
  '272',
  '353',
  '162',
  '369',
  '370',
  '163',
  '265',
  '331',
  '371',
  '381',
  '382',
  '235',
  '107',
  '384',
  '385',
  '392',
  '338',
  '48',
  'IU',
  'CU',
  'CV',
  'QB',
  'QF',
  '419',
  'C3',
  'WFC',
  'BI',
  '00LN',
  '00PD',
  '00PL',
  '00Y3',
  '1002',
  '1003',
  '1004',
  '1006',
  '1007',
  '1008',
  '1009',
  '1010',
  '1011',
  '1013',
  '1014',
  '1015',
  '1016',
  '1017',
  '1018',
  '1019',
  '1021',
  '1022',
  '1023',
  '1025',
  '1026',
  '1027',
  '1038',
  '1041',
  '1042',
  '1050',
  '10ANYSIDES',
  '10OFF',
  '10PIZZA',
  '1118',
  '1160',
  '1199ANY',
  '11ANY',
  '11OL',
  '11PIZZA',
  '1B',
  '1X',
  '1Y',
  '2017',
  '2022',
  '2025',
  '2027',
  '2028',
  '2032',
  '2036',
  '2037',
  '2070',
  '2071',
  '2073',
  '2074',
  '2075',
  '2076',
  '2081',
  '2082',
  '2083',
  '2084',
  '20OFF',
  '20PCT1115',
  '20PCT913',
  '2102',
  '2103',
  '2107',
  '2110',
  '2112',
  '2121',
  '2122',
  '2125',
  '2126',
  '2127',
  '2129',
  '2130',
  '2133',
  '2136',
  '2137',
  '2138',
  '2140',
  '2141',
  '2142',
  '2143',
  '2149',
  '2150',
  '2155',
  '2160',
  '2171',
  '2173',
  '2180',
  '2181',
  '22',
  '2210',
  '2211',
  '2212',
  '2220',
  '2221',
  '2222',
  '2223',
  '2224',
  '2225',
  '2226',
  '2227',
  '2229',
  '2230',
  '2231',
  '2233',
  '2234',
  '2237',
  '2240',
  '2245',
  '2246',
  '2247',
  '2248',
  '2249',
  '2250',
  '2251',
  '2252',
  '2253',
  '2254',
  '2256',
  '2258',
  '2260',
  '2262',
  '2263',
  '2266',
  '2267',
  '2268',
  '2269',
  '2271',
  '2273',
  '2275',
  '2276',
  '2280',
  '2281',
  '2282',
  '2283',
  '2284',
  '2285',
  '2286',
  '2291',
  '2293',
  '2294',
  '2297',
  '2298',
  '2299',
  '2300',
  '2301',
  '2302',
  '2303',
  '2304',
  '2305',
  '2307',
  '2LGADD2SIDE4',
  '2LSTICKS',
  '2T',
  '2U',
  '30',
  '3020',
  '3033',
  '3060',
  '3061',
  '3088',
  '3090',
  '30OFF',
  '30OFF50',
  '3116',
  '3162',
  '3164',
  '3171',
  '3176',
  '3177',
  '3185',
  '3190',
  '3192',
  '3199',
  '32',
  '3202',
  '3228',
  '3229',
  '3236',
  '3237',
  '3251',
  '3252',
  '3254',
  '3255',
  '3256',
  '3257',
  '3259',
  '3260',
  '3261',
  '3262',
  '3263',
  '3264',
  '3265',
  '3266',
  '3269',
  '3270',
  '3271',
  '3272',
  '3273',
  '3274',
  '3275',
  '3276',
  '3277',
  '3278',
  '3281',
  '3300',
  '3CHEESEADDLG1T8',
  '3O',
  '4004',
  '4006',
  '4007',
  '4019',
  '4022',
  '4030',
  '4031',
  '4032',
  '4039',
  '4040',
  '4041',
  '4042',
  '4044',
  '4050',
  '4051',
  '4052',
  '4053',
  '4054',
  '4055',
  '4056',
  '4057',
  '4067',
  '4071',
  '4073',
  '416',
  '418',
  '4M',
  '4P',
  '5001',
  '506',
  '50WING',
  '521',
  '522',
  '537',
  '555DEAL',
  '567',
  '582',
  '583',
  '584',
  '596',
  '599LG2',
  '5E',
  '5K',
  '5P',
  '6013',
  '6014',
  '6015',
  '6016',
  '606',
  '609',
  '6105',
  '612',
  '6120',
  '6122',
  '613',
  '617',
  '6204',
  '621',
  '622',
  '623',
  '624',
  '625',
  '626',
  '627',
  '628',
  '630',
  '632',
  '633',
  '636',
  '637',
  '640',
  '650',
  '670',
  '671',
  '681',
  '682',
  '684',
  '686',
  '690',
  '691',
  '692',
  '693',
  '694',
  '695',
  '696',
  '697',
  '699',
  '700',
  '7001',
  '7003',
  '7004',
  '7005',
  '7008',
  '7009',
  '701',
  '7010',
  '7011',
  '7012',
  '7013',
  '7014',
  '7018',
  '7019',
  '7021',
  '7024',
  '7026',
  '7028',
  '7030',
  '7033',
  '7035',
  '705',
  '7050',
  '7051',
  '7100',
  '7101',
  '7102',
  '7103',
  '7104',
  '7106',
  '7107',
  '7108',
  '7109',
  '7110',
  '7111',
  '7112',
  '7113',
  '7115',
  '7116',
  '7117',
  '7118',
  '7119',
  '712',
  '7129',
  '7130',
  '7133',
  '7134',
  '7137',
  '7141',
  '7143',
  '71TOPCO',
  '720',
  '721',
  '722',
  '730',
  '755',
  '760',
  '761',
  '762',
  '767',
  '76x',
  '770',
  '771',
  '772',
  '774',
  '779',
  '77x',
  '780',
  '781',
  '782',
  '783',
  '786',
  '787',
  '788',
  '789',
  '791',
  '799L2T',
  '799LG2',
  '7A',
  '7P',
  '7Y',
  '8016',
  '8166',
  '8200',
  '8202',
  '8203',
  '8204',
  '8205',
  '8206',
  '8207',
  '8208',
  '8209',
  '8210',
  '8211',
  '8213',
  '8214',
  '8215',
  '8216',
  '8219',
  '8221',
  '8228',
  '8229',
  '8230',
  '8231',
  '8232',
  '8233',
  '8234',
  '8235',
  '8236',
  '8237',
  '8238',
  '8239',
  '8240',
  '8241',
  '8242',
  '8244',
  '8245',
  '8246',
  '8248',
  '8250',
  '8251',
  '8252',
  '8257',
  '8260',
  '8262',
  '8263',
  '8264',
  '8265',
  '8266',
  '8267',
  '8268',
  '8274',
  '8275',
  '8276',
  '8279',
  '8280',
  '8283',
  '8284',
  '8285',
  '8293',
  '8294',
  '8295',
  '8296',
  '8298',
  '8299',
  '8300',
  '8301',
  '8303',
  '8305',
  '8307',
  '8308',
  '8309',
  '8310',
  '8311',
  '8313',
  '8314',
  '8315',
  '8316',
  '8317',
  '8318',
  '8319',
  '8320',
  '8321',
  '8322',
  '8323',
  '8324',
  '8327',
  '8328',
  '8329',
  '8330',
  '8331',
  '8332',
  '8333',
  '8334',
  '8335',
  '8336',
  '8338',
  '8339',
  '8340',
  '8341',
  '8342',
  '8343',
  '8344',
  '8345',
  '8346',
  '8347',
  '8348',
  '8349',
  '8352test',
  '8355',
  '8357',
  '8362',
  '8363',
  '8364',
  '8365',
  '8366',
  '8367',
  '8371',
  '8372',
  '8373',
  '8374',
  '8375',
  '8376',
  '8378',
  '8379',
  '8380',
  '8381',
  '8382',
  '8383',
  '8384',
  '8385',
  '8386',
  '8387',
  '8388',
  '8389',
  '840',
  '8402',
  '842',
  '843',
  '844',
  '8441',
  '846',
  '848',
  '850',
  '852',
  '853',
  '854',
  '855',
  '899COBOX',
  '899L2T',
  '8ANYBOX',
  '8F',
  '8LARGE1TOP',
  '8WINGSFOR5',
  '9159',
  '9174',
  '9183',
  '9184',
  '92',
  '920',
  '921',
  '930',
  '940',
  '980',
  'AA28THGSMFJUIRAYHHAGWQSOTL',
  'ADVANCE',
  'AK',
  'ANY2MED8',
  'ANYBOX',
  'APRTEO',
  'AUGTEO',
  'BACONSTUFFED',
  'BACONSTUFFEDMASTER',
  'BBQPIZZAS',
  'BBQPIZZAS2LITER',
  'BBQPIZZASMASTER',
  'BBQSOCIAL',
  'BIGCHEESE',
  'BOOKIT2LG3T1799',
  'BOOKITADDSTICKS1',
  'BROWNIE599',
  'BUNDLE49',
  'Bulldog30PQ',
  'CENTRO699TEST',
  'CENTRO799TEST',
  'CENTRO899TEST',
  'CHEESYBITESTMNT',
  'CO799',
  'COMFORTINN',
  'COOKIE599',
  'COOKIEBROWNIEMASTER',
  'CRAZYCHEESY',
  'DCTEO2',
  'DECTEO',
  'DENVER',
  'DK',
  'DONATELLO',
  'E99',
  'EEO2L3TPAN8',
  'EEO2LARGE2TFOR9',
  'EEO2LG3T1799',
  'EEO2LG3T899',
  'EEO2LOVERS1750',
  'EEO2LSIDE4',
  'EEO2MD2T599',
  'EEO2MD3T1299',
  'EEO2MD3T599',
  'EEO2PIZZASCHSTKS2',
  'EEO2SIDE',
  'EEO2TSTUFFED10',
  'EEO2TSTUFFED11',
  'EEO5SIDEWMEDPIZZA',
  'EEO5SIDEWPIZZA',
  'EEO5SIDEWPIZZAMASTER',
  'EEO799',
  'EEO8BONEOUTPIZZA4',
  'EEO8WINGSLG3TOP14',
  'EEOADDSTICKS1',
  'EEOCCCLOVERS9',
  'EEOCINNSLIDERS2',
  'EEOCRAZYSTICKS2',
  'EEOFLATCINN2',
  'EEOFREEDUNKERS',
  'EEOLG1T899',
  'EEOLG1T8WINGS14',
  'EEOLG1TCOOKIE14',
  'EEOLGLOV9',
  'EEOLGLOVERS11',
  'EEOLGPIZZA5',
  'EEOLOVERS10GBP',
  'EEOLOVERS10STF',
  'EEOMD3T699',
  'EEOMEDBBQ5',
  'EEOSLIDERSLOVERS9',
  'ELEVANY',
  'ELEVENONLINEBBQ',
  'ELEVENONLINEGP',
  'EMAILWINGSFOR5',
  'ESPNDRAFT',
  'FBTEO1',
  'FBTEO2',
  'FK',
  'FLATBREADS12',
  'FLAVORDIPPER',
  'FLAVOREDUPSTUFFED',
  'FREECIN',
  'FREECINNMDLG',
  'GARLICBREADPIZZA',
  'GARLICKNOTS',
  'GARLICPARM',
  'GBPIZZA',
  'GM',
  'GREATBIGDEAL',
  'GRILLEDCHEESESTUFFED',
  'H2',
  'HCBROWNIE',
  'HEARTPIZZA',
  'HEARTPIZZAWCOOKIEGLUTEN',
  'HLBOGO',
  'HNBBQC',
  'HUTLOVERSDEAL',
  'HWBBQH',
  'IJUSTINE',
  'IS5',
  'J799',
  'JACK',
  'JACK2',
  'JANTEO',
  'JG',
  'JNTEO2',
  'JU',
  'JULTEO',
  'K4',
  'KB',
  'KM',
  'KU2011',
  'KU2052',
  'KU2054',
  'KU8228',
  'KUSTX',
  'KUWG',
  'L3',
  'LE',
  'LEONARDO',
  'LG799',
  'LGLOV8',
  'LITERACY1',
  'LITERACY10',
  'LITERACY2018',
  'LITERACY3',
  'LITERACY5',
  'LOVE',
  'LS2S',
  'LVM579',
  'M0',
  'M8',
  'MAYTEO',
  'MDA-P',
  'MEMDAY15',
  'MICHELANGELO',
  'MN',
  'MP749',
  'MU',
  'MYTEO2',
  'NOVTEO',
  'O9',
  'OCTTEO',
  'ORIGAMIYODA',
  'OVERSTUFFEDPIZZA',
  'OVERSTUFFITAL',
  'OVERSTUFFSUPR',
  'PAN10',
  'PEPPERONI1',
  'PG',
  'PQ',
  'PRIMOMEAT',
  'PZOLO',
  'R8',
  'RAPHAEL',
  'RESIDENT',
  'RG',
  'RH',
  'RO',
  'ROOSTER',
  'ROYALOFFER',
  'RU',
  'RUSSET',
  'SBBQHBP',
  'SC10NVTEO',
  'SC9NVTEO',
  'SEEO2LCHEESESTK',
  'SEPTEO',
  'SMORESBUNDLE',
  'SMORESCOOKIE',
  'TUSCANITUES',
  'TUSCANITUES6',
  'VH',
  'VISAFIVE',
  'WD',
  'WINGWEDNESDAY60BONEOUT',
  'WR'
];

export const HUT_AMERICAN_STORE_NUMBERS = [
  '037986',
  '039642',
  '037961',
  '040004',
  '037979',
  '037985',
  '037944',
  '037972',
  '037949',
  '037974',
  '037950',
  '037967',
  '037952',
  '037953',
  '037975',
  '037976',
  '037980',
  '037984',
  '037954',
  '037968',
  '037971',
  '037970',
  '037977',
  '037943',
  '037987',
  '038327',
  '038318',
  '038322',
  '038319',
  '038323',
  '038330',
  '038317',
  '038443',
  '038418',
  '038331',
  '038378',
  '038380',
  '038375',
  '038332',
  '038417',
  '039643',
  '038438',
  '038435',
  '038422',
  '038423',
  '038439',
  '038424',
  '038432',
  '038433',
  '038446'
];

export const LINEUP_GLOBALID = 'valueLineup';
export const UPDATED_LINEUP_NAME = "$7 Deal Lover's Menu™";
export const UPDATED_LINEUP_IMAGE = 'https://www.pizzahut.com/assets/w/tile/thor/DealLovers_DealTile_App_180x210_v2.jpg';

/**
 * LINEUP_TILE is not a product, it's just a category which routes to the /lineup
 * hardcoding the storeid here as we are only looking for /valueLineup as a last substring for routing
 * */

export const UPDATED_LINEUP_TILE: MenuRecipe = {
  id: 'stores/032451/discounts/valueLineup',
  name: UPDATED_LINEUP_NAME,
  imageURL: UPDATED_LINEUP_IMAGE,
  type: 'DISCOUNT',
  price: undefined,
  outOfStock: false,
  priority: 100000,
  hidden: false,
  allergenDisclaimer: undefined,
  productDescription: '',
  availability: [
    {
      days: [1, 2, 3, 4, 5, 6, 7],
      startTime: '00:00:01',
      endTime: '00:00:00',
      occasion: 'CARRYOUT',
      status: undefined
    },
    {
      days: [1, 2, 3, 4, 5, 6, 7],
      startTime: '00:00:01',
      endTime: '00:00:00',
      occasion: 'DELIVERY',
      status: undefined
    }
  ],
  steps: [],
  available: true
};

export const MY_HUT_BOX_DEAL_TILE = {
  available: true,
  id: 'stores/000001',
  name: 'NEW! My Hut Box - Make it yours with choice of entrée & side',
  type: 'PRODUCT',
  description: "Choose between Melts or a 2-topping Personal Pan Pizza®, then add a side of fries or 4 boneless wings. Don't forget the 20oz drink.",
  imageUrl: 'https://www.pizzahut.com/assets/w/tile/thor/MHB_Maindealtile_175x203.jpg',
  isOutOfStock: false,
  sodiumWarning: false,
  displayOrder: 1999999999,
  priority: 9999999999990,
  sizes: [
    {
      id: 'stores/000001/items/gCQlp3nQMP7uxx5JiVV7Jw/sizes/3tSWkDGufhHogBYWlIlQqg',
      name: 'Order',
      priority: 0,
      isOutOfStock: false,
      sodiumWarning: false,
      type: 'SIZE',
      price: 699,
      nutrition: [],
      modifiers: []
    }
  ],
  additionalOptions: {
    Order: []
  }
};
