export type DebugTypes = 'all' | 'transformations' | 'menu' | 'products' | 'cart' | 'edit' | 'deals' | 'site';
export enum DebugModeOptions {
  ALL = 'all',
  TRANSFORMATIONS = 'transformations',
  MENU = 'menu',
  PRODUCTS = 'products',
  CART = 'cart',
  EDIT = 'edit',
  DEALS = 'deals',
  SITE = 'site'
}
export type DebugStateTypes = {
  enabled: boolean;
  mode: DebugTypes;
};
