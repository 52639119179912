import { DisplayableModifier, DisplayableProduct } from '@/domain/product/types';
import { CartItem, CartItemModifier } from '@/domain/cart/types';
import { SIZE } from '@/domain/constants';
import isOptimizelyFeatureEnabled from '../../../../optimizely/utils/isOptimizelyFeatureEnabled';

interface PreSelectedValues {
  quantity: number;
  selectedSize?: DisplayableModifier;
  selectedAdditionalOption?: DisplayableModifier;
}

interface GetAdditionalOptionProps {
  item: CartItem;
  selectedSize: DisplayableModifier;
  product: DisplayableProduct;
}

const getNonWingsAdditionalOption = ({ item, selectedSize, product }: GetAdditionalOptionProps) => {
  const cartItemAddlOptionGroup = item.modifiers.find((itemModifier) => itemModifier.type !== SIZE);
  const correctCartAddlOption = cartItemAddlOptionGroup?.modifiers?.[0]?.id;

  return product?.additionalOptions?.[selectedSize.name]
    ?.find((productAddlOption) => productAddlOption?.id === correctCartAddlOption);
};

const getWingsAdditionalOption = ({ item, selectedSize, product }: GetAdditionalOptionProps) => {
  const correctCartAddlOption = item.modifiers?.[0]?.modifiers?.[0]?.id;

  return product?.additionalOptions?.[selectedSize.name]
    ?.find((productAddlOption) => productAddlOption?.id === correctCartAddlOption);
};

const getAdditionalOption = ({
  item, selectedSize, product
}: GetAdditionalOptionProps) => getNonWingsAdditionalOption({ item, selectedSize, product })
  || getWingsAdditionalOption({ item, selectedSize, product });

const getSelectedSize = ({ item, product }: Omit<GetAdditionalOptionProps, 'selectedSize'>) => {
  const correctCartSize = item.modifiers
    .find((itemModifier) => itemModifier.type === SIZE)?.id;

  return product?.sizes?.find((productSize) => productSize?.id === correctCartSize);
};

const yumProductSelectedOption = ({ item, product }: Omit<GetAdditionalOptionProps, 'selectedSize'>): PreSelectedValues => {
  const yumPastaTransformFixEnabled = isOptimizelyFeatureEnabled('fr-web-2725-hardcode-pasta-dropdowns-fix');
  const isPasta = product.id.includes('CLSS060PS');

  const { quantity } = item;
  const itemVariantCodesSplit = item?.variantCode?.split('_');

  // For products in cart there is no sizes array.
  // But the products should have selected size id included in variant code as substrings.
  const selectedSize = product?.sizes?.find((size) => {
    const sizeCodesSplit = size.id.split('|');
    return sizeCodesSplit.every((sizeCode) => itemVariantCodesSplit?.some((itemCode) => sizeCode.includes(itemCode)));
  });

  const selectedAdditionalOption = selectedSize && product?.additionalOptions?.[selectedSize.name]?.find(
    (option) => {
      if (isPasta && yumPastaTransformFixEnabled) {
        const sideItemModifiers = item.modifiers.find((modifier) => modifier.id === 'slot_pasta_side_item')?.modifiers as CartItemModifier[];
        return sideItemModifiers?.find((modifier) => modifier.id === option.id);
      }

      return option?.variantCode === item.variantCode;
    }
  );

  return {
    quantity,
    selectedSize,
    selectedAdditionalOption
  };
};

export const findPreSelectedValues = (
  product: DisplayableProduct, item: CartItem
): PreSelectedValues => {
  const { quantity } = item;

  if (item.variantCode) return yumProductSelectedOption({ item, product });

  const selectedSize = getSelectedSize({ item, product });

  if (!selectedSize) return { quantity };

  const selectedAdditionalOption = getAdditionalOption({ item, product, selectedSize });

  return {
    quantity,
    selectedAdditionalOption,
    selectedSize
  };
};
