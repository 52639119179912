import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { AppliedPromotion } from '@pizza-hut-us-development/client-core';
import useYumAddedCoupons from './hooks/useYumAddedCoupons';
import styles from './styles';
import { RemoveCouponIcon } from '../../../../common/RemoveCouponIcon';

type YumAddedCouponProps = {
  coupon: AppliedPromotion;
};

export const YumAddedCoupons = ({ coupon }: YumAddedCouponProps): JSX.Element => {
  const { name, code } = coupon;

  const classes = styles.yumAddedCoupons();

  const [handlers] = useYumAddedCoupons();
  const {
    handleRemoveYumPromoCode
  } = handlers;

  return (
    <Grid className={classes.container}>
      <Typography className={classes.couponName}>{name}</Typography>
      <Button
        onClick={() => handleRemoveYumPromoCode(code)}
        className={classes.removeButton}
      >
        <RemoveCouponIcon />
      </Button>
    </Grid>
  );
};

export default YumAddedCoupons;
