import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useApplyPromoCodeMutation, useRemovePromoCodeMutation, YumCart } from '@pizza-hut-us-development/client-core';
import { useDecision } from '@optimizely/react-sdk';
import { CouponReturn } from '../types';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import {
  GUEST_COUPON_ERROR_MESSAGE,
  POS_SERIALIZED_CODE_LENGTH,
  QO_SERIALIZED_CODE_LENGTH, YUM_COUPON_ERROR_MESSAGE
} from '@/clientCore/cart/constants';
import { onRailCouponEntry } from '@/checkout/checkout.analytics';
import { closeCartRail } from '@/clientCore/redux/rail/CartRailSlice';
import Routes from '@/router/routes';
import { getAlphanumericValue } from '@/clientCore/cart/helpers';
import { RootState } from '@/rootStateTypes';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import YumAddedCoupons from '../../YumAddedCoupons/YumAddedCoupons';
import triggerMutationWrapper from '@/clientCore/helper/triggerMutationWrapper';

const useCoupon = (): CouponReturn => {
  const cart = useSelector(orderSelectors.cart);
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);
  const [{ enabled: useYumAddRemoveDecision }] = useDecision('fr-web-2773-yum-coupon-promotions-add-remove-hooks');
  const [{ enabled: useYumCouponCodeAllowHyphenDecision }] = useDecision('fr-3720-coupon-code-allow-hyphen');

  const [couponId, setCouponId] = useState('');
  const [isExpanded, setIsExpanded] = useState(isYumEcomm && useYumAddRemoveDecision ? ((cart as YumCart)?.appliedPromotions?.length ?? 0) > 0 : false);
  const [errorMessage, setErrorMessage] = useState('');

  const dispatch = useDispatch();
  const router = useRouter();
  const analytics = useAnalytics();

  const [applyYumPromoCode] = useApplyPromoCodeMutation();
  const [removePromoCode] = useRemovePromoCodeMutation();

  const isGuestSingleUseSerializedCode = (coupon: string): boolean => {
    if (
      isYumEcomm
      && (coupon.length === QO_SERIALIZED_CODE_LENGTH
        || (coupon.length === POS_SERIALIZED_CODE_LENGTH && coupon.startsWith('PH' || 'ph')))
    ) {
      setErrorMessage(GUEST_COUPON_ERROR_MESSAGE);
      return true;
    }
    return false;
  };

  const sanitizeCouponId = (coupon: string) => getAlphanumericValue(coupon, isYumEcomm, useYumCouponCodeAllowHyphenDecision);

  const sanitizeAndSetCouponId = (coupon: string) => {
    setErrorMessage('');
    setCouponId(sanitizeCouponId(coupon));
  };
  // used for phd
  const routeToDeals = () => {
    if (couponId.trim().length && !isGuestSingleUseSerializedCode(couponId)) {
      analytics.push(() => onRailCouponEntry(couponId));
      router.push({ pathname: Routes.DEALS, query: { id: couponId } });
      dispatch(closeCartRail());
    }
  };
  // used for yum
  const submitYumPromoCode = async () => {
    if (!couponId) {
      setErrorMessage(YUM_COUPON_ERROR_MESSAGE);
      return;
    }
    const trimmedCouponId = couponId.toUpperCase().trim();

    await triggerMutationWrapper(
      applyYumPromoCode(trimmedCouponId), {
        onSuccess: (reponseCart) => {
          if (reponseCart && 'appliedPromotions' in reponseCart) {
            const isCouponApplied = reponseCart?.appliedPromotions?.every((promotion) => promotion?.code === trimmedCouponId);
            if (!isCouponApplied) {
              setErrorMessage(YUM_COUPON_ERROR_MESSAGE);
            }
          } else {
            setErrorMessage(YUM_COUPON_ERROR_MESSAGE);
          }
        },
        onError: (error) => {
          setErrorMessage(YUM_COUPON_ERROR_MESSAGE);
          console.error({ error });
        }
      }
    );
  };

  const handleRemoveYumPromoCode = async (code: string) => {
    try {
      await removePromoCode(code);
    } catch (error) {
      console.error({ error });
    }
  };

  const createYumDisplayableCoupons = () => ((cart as YumCart)?.appliedPromotions ?? []).map((coupon) => <YumAddedCoupons coupon={coupon} />);

  const yumDisplayableCoupons = createYumDisplayableCoupons();

  return [
    {
      couponId,
      errorMessage,
      isExpanded,
      useYumAddRemoveDecision,
      yumDisplayableCoupons
    },
    {
      applyButtonSubmit: (isYumEcomm && useYumAddRemoveDecision) ? submitYumPromoCode : routeToDeals,
      handleRemoveYumPromoCode,
      sanitizeAndSetCouponId,
      setIsExpanded
    }
  ];
};

export default useCoupon;
