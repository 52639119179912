import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import Label from '@/common/Label';
import {
  COUPON_BUTTON_ACCORDION_LABEL,
  COUPON_BUTTON_LABEL,
  COUPON_CODE_ID,
  COUPON_CODE_LABEL
} from './constants';
import OutlineTextInput from '@/common/OutlineTextInput';
import styles from './Coupon.styles';
import { CtaButton } from '@/coreComponents/buttons/CtaButton/CtaButton';
import LinkButton from '@/cart/cartRail/LinkButton';
import useCoupon from './hooks/useCoupon';
import colors from '@/common/colors';
import { AlertIcon } from '../../../../common/AlertIcon';
import { WarningIcon } from '@/clientCore/cart/components/CartRail/common/WarningIcon';
import { RootState } from '@/rootStateTypes';

const Coupon = (): JSX.Element => {
  const classes = styles.coupon();

  const [data, handlers] = useCoupon();
  const {
    couponId, errorMessage, isExpanded, useYumAddRemoveDecision, yumDisplayableCoupons
  } = data;
  const { applyButtonSubmit, sanitizeAndSetCouponId, setIsExpanded } = handlers;
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);

  return (
    <>
      <div className={classes.borderTop}>
        <LinkButton
          className={classes.link}
          onClick={() => setIsExpanded((prevState) => !prevState)}
          ariaLabel={COUPON_BUTTON_ACCORDION_LABEL}
          isExpandable
          isExpanded={isExpanded}
        >
          {COUPON_BUTTON_ACCORDION_LABEL}
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </LinkButton>
      </div>
      {isExpanded && (
        <>
          <Grid container alignItems="flex-end" className={classes.container}>
            <Grid item xs={12} sm={8}>
              <Label
                htmlFor={COUPON_CODE_ID}
                className={classes.couponFieldLabel}
              >
                <span>{COUPON_CODE_LABEL}</span>
              </Label>
              <OutlineTextInput
                id={COUPON_CODE_ID}
                testId={COUPON_CODE_ID}
                onChange={sanitizeAndSetCouponId}
                value={couponId}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={classes.couponButton}>
              <CtaButton
                labelText={COUPON_BUTTON_LABEL}
                clickHandler={applyButtonSubmit}
                color="secondary"
                disabled={false}
              />
            </Grid>
            <Grid>
              {(isYumEcomm && useYumAddRemoveDecision) && yumDisplayableCoupons}
            </Grid>
          </Grid>
          {errorMessage.length > 0 && (
            <Grid
              container
              alignItems="flex-start"
              className={classes.container}
            >
              <div className={classes.warningIcon}>
                {useYumAddRemoveDecision ? <WarningIcon /> : <AlertIcon color={colors.black} />}
              </div>
              <Typography className={classes.warningText}>
                {errorMessage}
              </Typography>
            </Grid>
          )}
        </>
      )}
      <div className={classes.borderBottom} />
    </>
  );
};

export default Coupon;
