import { Product } from '@pizza-hut-us-development/client-core';
import {
  extractFromOptions,
  extractYumPastaAdditionalOption,
  getDefaultOption,
  getMatchedSizes,
  shapeOneClickDefaults,
  wingsAdditionalOptionsFromCCData
} from '@/clientCore/temporaryTransformationalHooks/useCCGetProducts/helpers/menuProductsData';
import { AvailabilityItem } from '@/graphql/helpers/checkAvailability';
import { DisplayableAdditionalOptions, DisplayableProduct } from '@/domain/product/types';
import isOptimizelyFeatureEnabled from '../../../../optimizely/utils/isOptimizelyFeatureEnabled';

export const transformProduct = (availabilityProduct: AvailabilityItem<Product>): DisplayableProduct => {
  const yumPastaTransformFixEnabled = isOptimizelyFeatureEnabled('fr-web-2725-hardcode-pasta-dropdowns-fix');

  const isWings = availabilityProduct.name.toLowerCase().includes('wing');
  const isPasta = availabilityProduct.id.includes('CLSS060PS');

  let additionalOptions: DisplayableAdditionalOptions | undefined;

  if (isWings) {
    additionalOptions = wingsAdditionalOptionsFromCCData(availabilityProduct);
  } else if (isPasta && yumPastaTransformFixEnabled) {
    additionalOptions = extractYumPastaAdditionalOption(availabilityProduct);
  }

  return ({
    additionalOptions: additionalOptions ?? extractFromOptions(availabilityProduct),
    available: availabilityProduct.available,
    description: availabilityProduct.description || '',
    displayOrder: availabilityProduct.displayOrder,
    id: availabilityProduct.id,
    imageUrl: availabilityProduct.imageURL || '',
    isOutOfStock: availabilityProduct.outOfStock,
    name: availabilityProduct.name,
    preSelectedAdditionalOption: getDefaultOption(
      availabilityProduct.defaultSelectedOption
    ),
    priority: 0,
    sizes: getMatchedSizes(availabilityProduct),
    sodiumWarning: availabilityProduct.sodiumWarning,
    type: availabilityProduct.type || '',
    ...(availabilityProduct.type === 'MELT' && {
      oneClickDefaults: shapeOneClickDefaults(availabilityProduct)
    })
  });
};

const useCCGetDisplayableProduct = () => ({ transformProduct });

export default useCCGetDisplayableProduct;
