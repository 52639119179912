import { Occasion } from '../constants';

/* eslint camelcase: "off" */
export enum ContactlessStatus {
  CURBSIDE = 'Contactless Curbside',
  PICKUP = 'Pickup',
  OPT_OUT = 'Opt Out'
}

export type Metadata = {
  contactless?: string;
  curbsidePU_feature?: boolean;
  curbsidePU_localization_header?: string;
  curbsidePU_localization_image?: string;
  curbsidePU_message?: string;
  curbsideCO_feature?: boolean;
  curbsideCO_localization_header?: string;
  curbsideCO_localization_image?: string;
  curbsideCO_message?: string;
  contactless_message?: string;
  contactless_feature?: string;
  pos_type?: string
}

function getShowDeliveryContactlessModal(metadata?: Metadata) {
  return Boolean(metadata?.contactless_feature
    && metadata?.contactless_feature === '1'
    && metadata?.contactless_message);
}

function getShowCarryoutPickupContactlessModal(metadata?: Metadata) {
  return Boolean((metadata?.curbsideCO_feature || metadata?.curbsidePU_feature)
    && (metadata?.curbsidePU_localization_header
      && metadata?.curbsidePU_localization_image
      && metadata?.curbsidePU_message));
}

function getShowCarryoutCurbsideContactlessModal(metadata?: Metadata) {
  return Boolean(metadata?.curbsideCO_feature
    && metadata?.curbsideCO_localization_header
    && metadata?.curbsideCO_localization_image
    && metadata?.curbsideCO_message);
}

function getPickupType(metadata: Metadata) {
  const hasRequiredFields = getShowCarryoutPickupContactlessModal(metadata);

  return hasRequiredFields ? ContactlessStatus.PICKUP : undefined;
}

function getCurbsideType(metadata: Metadata) {
  const hasRequiredFields = getShowCarryoutCurbsideContactlessModal(metadata);

  return hasRequiredFields ? ContactlessStatus.CURBSIDE : undefined;
}

export default function getContactlessStatus(metadata: Metadata | undefined, occasion: Occasion) {
  if (occasion === Occasion.DELIVERY) {
    return {
      showContactlessModal: getShowDeliveryContactlessModal(metadata),
      modalHeader: 'Contactless delivery',
      modalMessage: metadata?.contactless_message
    };
  }

  switch (metadata?.contactless) {
    case ContactlessStatus.PICKUP:
      return {
        showContactlessModal: getShowCarryoutPickupContactlessModal(metadata),
        modalHeader: metadata?.curbsidePU_localization_header,
        modalImageURL: metadata?.curbsidePU_localization_image,
        modalMessage: metadata?.curbsidePU_message,
        type: getPickupType(metadata)
      };
    case ContactlessStatus.CURBSIDE:
      return {
        showContactlessModal: getShowCarryoutCurbsideContactlessModal(metadata),
        modalHeader: metadata?.curbsideCO_localization_header,
        modalImageURL: metadata?.curbsideCO_localization_image,
        modalMessage: metadata?.curbsideCO_message,
        type: getCurbsideType(metadata)
      };
    default:
      return {
        showContactlessModal: false
      };
  }
}
