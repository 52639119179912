import angularGetUserAnalytics from './angularGetUserAnalytics';
import standaloneGetUserAnalytics from './standaloneGetUserAnalytics';

export function getUserAnalytics(dataModel?: {}) {
  return process.env.REACT_APP_EMBEDDED === 'true'
    ? angularGetUserAnalytics()
    : standaloneGetUserAnalytics(dataModel);
}

export default getUserAnalytics;
