import angularDataModel from '../dataModel';

const getUserAnalytics = () => {
  const dataModel = angularDataModel();

  return {
    /* eslint-disable camelcase */
    user_login_status: dataModel.user?.loginStatus,
    user_promiseTime_delivery_initial: dataModel.user?.promiseTimes?.delivery?.initial,
    user_promiseTime_carryout_initial: dataModel.user?.promiseTimes?.carryout?.initial,
    user_purchases_future: dataModel.user?.purchases?.future?.toString(),
    user_localized: dataModel.page?.store_search_occasion ? 'Yes' : 'No',
    user_id: dataModel.user?.id,
    store_dma: dataModel.store?.dma,
    store_id: dataModel.store?.id,
    store_POS_type: dataModel.store?.posType,
    store_franchisee_code: dataModel.store?.franchiseeCode,
    store_city: dataModel.store?.city,
    store_state: dataModel.store?.state,
    store_zip: dataModel.store?.zip?.toString(),
    store_search_occasion: dataModel.page?.store_search_occasion,
    session_remember_occasion: dataModel.page?.store_search_occasion,
    session_remember_cart: dataModel.page?.remember?.cart ? 'true' : 'false',
    session_remember_name: dataModel.page?.remember?.name ? 'true' : 'false',
    recognized_member: dataModel.page?.remember?.name ? 'Yes' : 'No',
    nfl_gameplan_subscription: dataModel.user?.rewards?.nfl_game_plan ? 'true' : 'false',
    nfl_team: dataModel.user?.rewards?.nfl_game_plan_team,
    user_rewards_member: dataModel.user?.rewards?.member,
    user_hutLovers_marketing_opt_in: dataModel.user?.hutLoversMember,
    page_responsive_layout: dataModel.page?.responsiveLayout,
    screen_level1: dataModel.page?.categorization?.level1,
    screen_level2: dataModel.page?.categorization?.level2,
    screen_level3: dataModel.page?.categorization?.level3,
    screen_level4: dataModel.page?.categorization?.level4,
    screen_name: dataModel.page?.name
    /* eslint-enable camelcase */
  };
};

export default getUserAnalytics;
