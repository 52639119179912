import angularHost from '../../../../embedded-web2/src/embedding-framework/angular-adapters/angularHost';

const angularAccountService : AccountService = {
  getUserSavedAddresses(): Promise<any> {
    const angularAccountService = angularHost.accountService();

    const account = angularAccountService.get();

    if (account.isAuthenticated) {
      return angularAccountService
        .get_login_user_data()
        .then(({ savedLocations }) => savedLocations)
        .then((savedLocation) => savedLocation.map(transformUserData));
    }

    return Promise.resolve([]);
  }
};

function transformUserData(angularUserData: any): SavedAddress {
  return {
    address: angularUserData.address,
    addressTwo: angularUserData.address_two,
    index: angularUserData.idx,
    state: angularUserData.state,
    zipcode: angularUserData.zip,
    city: angularUserData.city,
    name: angularUserData.name
  };
}

export default angularAccountService;
