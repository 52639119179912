import { useDispatch, useSelector } from 'react-redux';
import { DiningOccasion, OccasionDetail } from '@pizza-hut-us-development/client-core';
import { StoreStatus } from '@/localization/localizationRail/StoreTile/constants';
import { transformCarryoutPromiseTime, transformDeliveryPromiseTime } from '@/localization/common/transformPromiseTime';
import { closeCartRail, openCartRail } from '@/clientCore/redux/rail/CartRailSlice';
import { formatCCOpenTime } from '@/clientCore/helper/timeHelpers';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import { CartLocalizationReturn, PromiseTimeTextReturn } from '../types';
import useOpenLocalizationWithWarningModal from '@/header/useOpenLocalizationWithWarningModal';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import { onChangeLocalizedStore } from '@/checkout/checkout.analytics';
import { cartItemsToLegacy, getCityStateAndZipcode } from '@/clientCore/cart/helpers';
import { customerSelectors } from '@/clientCore/redux/selectors/customerSelectors';
import { CityStateAndZipCodeArgs } from '@/clientCore/cart/types';
import { Occasion } from '@/clientCore/checkout/types';

export const useCartLocalization = (): CartLocalizationReturn => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const storeDetails = useSelector(orderSelectors.store);
  const cart = useSelector(orderSelectors.cart);
  const searchAddress = useSelector(customerSelectors.searchAddress);
  const { occasions, status, availability } = storeDetails ?? {};
  const { occasionId } = cart ?? {};

  const currentlyLocalizedAddress = occasionId === DiningOccasion.DELIVERY ? searchAddress : storeDetails;
  const cityStateAndZipcode = getCityStateAndZipcode(currentlyLocalizedAddress as CityStateAndZipCodeArgs);

  const displayableOccasion = occasions?.find((occasion: OccasionDetail) => occasionId === occasion.occasionId)?.name;
  const displayableAddress = currentlyLocalizedAddress?.address1 ?? '';
  const waitTime = occasions?.find((occasion) => occasion.occasionId === occasionId)?.serviceTime;
  const storeStatus = status || '';
  const openTime = formatCCOpenTime(availability?.find(() => occasionId)?.intervalStartTime);

  // Populates promiseTime text
  const determinePromiseTimeText = ({ occasion, promiseTime }: PromiseTimeTextReturn): string => {
    if (!storeDetails) return '';

    let promiseTimeText;

    if (occasion === Occasion.CARRYOUT) {
      promiseTimeText = transformCarryoutPromiseTime(promiseTime);
    } else {
      promiseTimeText = transformDeliveryPromiseTime(promiseTime);
    }

    switch (storeStatus) {
      case StoreStatus.CARRYOUT_ONLY: {
        if (occasion === Occasion.DELIVERY) return 'Store Closed';

        return promiseTimeText;
      }

      case StoreStatus.DELIVERY_ONLY: {
        if (occasion === Occasion.CARRYOUT) return 'Store Closed';

        return promiseTimeText;
      }

      case StoreStatus.DELIVERY_AND_CARRYOUT:
        return promiseTimeText;
      case StoreStatus.TEMP_CLOSED_WITH_REASON:
      case StoreStatus.PERM_OFFLINE:
        return 'Store Closed';
      default: {
        const openTimeText = openTime ? `Open ${openTime}` : '';
        return `${openTimeText}`.trim();
      }
    }
  };

  // Applies css class for promiseTime
  const assignPromiseTimeClass = () => {
    const isStoreOpen = storeStatus === StoreStatus.DELIVERY_AND_CARRYOUT
    || (storeStatus === StoreStatus.DELIVERY_ONLY && displayableOccasion === Occasion.DELIVERY)
    || (storeStatus === StoreStatus.CARRYOUT_ONLY && displayableOccasion === Occasion.CARRYOUT);

    const storeOpenClasses = isStoreOpen ? 'storeOpen' : 'storeClosed';

    const promiseTime = storeStatus === StoreStatus.ONLINE_BUT_OUTSIDE_STORE_HOURS
      ? 'storeWillOpen' : storeOpenClasses;

    return promiseTime;
  };

  const onCancel = () => dispatch(openCartRail());

  const openLocalizationWithWarningModal = useOpenLocalizationWithWarningModal({ onCancel, origin: 'Cart', clientCore: true });
  const handleChangeLocalizedStore = () => {
    if (cart?.items) {
      analytics.push(() => onChangeLocalizedStore('Cart', cartItemsToLegacy(cart.items)));
    }
    dispatch(closeCartRail());
    openLocalizationWithWarningModal();
  };

  return [{
    displayableAddress,
    displayableOccasion,
    displayablePromiseTime: determinePromiseTimeText({ occasion: displayableOccasion, promiseTime: waitTime }),
    addressLine2: currentlyLocalizedAddress?.address2 ?? '',
    cityStateAndZipcode,
    promiseTimeClasses: assignPromiseTimeClass()
  },
  {
    handleChangeLocalizedStore
  }
  ];
};
