import { Deal, DealData } from '../slice/dealTypes';
import {
  AddableCartItem,
  AddableCartItemKinds
} from '@/api/phdApiV2Client/request.types';
import { CartItemInfo } from '@/domain/cart/types';
import { useCCGetDealBuilderQuery } from '@/clientCore/temporaryTransformationalHooks/useCCGetDealBuilderQuery';
// import { useCCGetMultipleDealBuildersQuery } from '@/clientCore/temporaryTransformationalHooks/useCCGetMultipleDealBuildersQuery';

export enum Status {
  Loading,
  Failure,
  Unavailable,
  AvailableInOtherOccasion
}

export const isDealData = (
  value: ReturnType<typeof useDeal>
): value is DealData => !Status[value as unknown as keyof typeof Status];

export const isDealType = (
  item: AddableCartItem | CartItemInfo
): item is Deal => {
  const hasProperty = 'userSelections' in item;
  return hasProperty && item?.data.kind === AddableCartItemKinds.DEAL;
};

const useDeal = (dealId: string): DealData | Status => {
  // const [subDealIds, setSubDealIds] = useState([]);
  // TODO: Wire this up to filter OOS sub-deals when on CC
  // useCCGetMultipleDealBuildersQuery({ itemIds: subDealIds });

  const {
    data: ccData,
    loading
  } = useCCGetDealBuilderQuery({
    itemId: dealId
  });

  // useEffect(() => {
  //   if (ccData?.steps) {
  //     setSubDealIds(ccData.steps.map((step) => step.id));
  //   }
  // }, [ccData])

  const ccDealData = ccData ? { deal: ccData } : undefined;
  const data = ccDealData;

  if (loading) return Status.Loading;

  if (!data) return Status.Unavailable;

  const { deal } = data;

  const modifiedDeal = {
    ...deal,
    steps:
      deal?.steps.map((step) => ({
        ...step,
        outOfStock: step.outOfStock
      })) || []
  };

  const isDealUnavailableInBothOccasions = !modifiedDeal.available && !modifiedDeal.availableInOtherOccasion;
  if (
    !modifiedDeal
    || isDealUnavailableInBothOccasions
    || modifiedDeal.outOfStock
  ) {
    return Status.Unavailable;
  }

  if (!modifiedDeal.available && modifiedDeal.availableInOtherOccasion) {
    return Status.AvailableInOtherOccasion;
  }

  return modifiedDeal;
};

export default useDeal;
