import phdapiAccountService from './phdapiAccountService';

export function getAccountService() : AccountService {
  return process.env.REACT_APP_EMBEDDED === 'true'
    ? lazyLoadAngularBackedService()
    : phdapiAccountService;
}

function lazyLoadAngularBackedService() : AccountService {
  // lazy-requiring this because the angular localization service
  // blows up if Nextjs tries to run it server-side
  // (currently depends on the global window object, which is not available server-side)
  /* eslint-disable  global-require */
  return require('./angularAccountService').default;
}

export default getAccountService();
