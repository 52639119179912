import { useEffect } from 'react';
import { useDecision } from '@optimizely/react-sdk';

/*
This module adds the usablenet sticky icon,
 which when clicked, enables accessibility mode or "usability mode" for the site
*/

const UsabilityMode = (): null => {
  const [{ enabled: shouldShowUsableNetStickyIcon }] = useDecision('fr-dtg-633-usablenet-sticky-icon');

  useEffect(() => {
    const script = document.createElement('script');

    script.id = 'usntA40start';
    script.src = 'https://a40.usablenet.com/pt/c/pizzahut/cs-start';
    script.async = true;

    if (shouldShowUsableNetStickyIcon) {
      document.body.appendChild(script);
    }

    return () => {
      if (shouldShowUsableNetStickyIcon) {
        document.body.removeChild(script);
      }
    };
  }, [shouldShowUsableNetStickyIcon]);

  return null;
};

export default UsabilityMode;
