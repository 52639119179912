import { createStyles, makeStyles } from '@material-ui/core';
import colors from '@/common/colors';

const yumAddedCoupons = makeStyles(() => createStyles({
  container: {
    marginTop: '17px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid',
    borderRadius: 25,
    color: colors.red
  },
  couponName: {
    padding: '0 12px'
  },
  removeButton: {
    minWidth: '10px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));

const styles = { yumAddedCoupons };

export default styles;
