import React, { useEffect } from 'react';

type TemporaryCCContextType = {
  isOptimizelyApiLoading: boolean;

  // Used to validate when the Optimizely Api has finished loading
  // Useful for the initial load prior to the API call firing off
  isOptimizelyApiLoaded: boolean;

  setIsOptimizelyApiLoading: (newVal: boolean) => void;
  setIsOptimizelyApiLoaded: (newVal: boolean) => void;
};

export const TemporaryCCContext = React.createContext({
  isOptimizelyApiLoading: false,
  isOptimizelyApiLoaded: false,
  setIsOptimizelyApiLoading: () => null,
  setIsOptimizelyApiLoaded: () => null
} as TemporaryCCContextType);

export const useTemporaryCCContext = () => React.useContext(TemporaryCCContext);

export const TemporaryCCProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [isOptimizelyApiLoading, setIsOptimizelyApiLoading] = React.useState(false);
  const [isOptimizelyApiLoaded, setIsOptimizelyApiLoaded] = React.useState(false);

  useEffect(() => {
    if (isOptimizelyApiLoading) {
      setIsOptimizelyApiLoaded(false);
    }
  }, [isOptimizelyApiLoading, setIsOptimizelyApiLoaded]);

  const value: TemporaryCCContextType = {
    isOptimizelyApiLoading,
    isOptimizelyApiLoaded,
    setIsOptimizelyApiLoading,
    setIsOptimizelyApiLoaded
  };

  return (
    <TemporaryCCContext.Provider value={value}>
      {children}
    </TemporaryCCContext.Provider>
  );
};
