import { Dispatch } from 'redux';
import authService, { YumRefreshTokenData } from '@/services/authService';
import telemetry from '@/telemetry';
import { getSelectors, OfSelectorsAndSuch } from '@/common/logout/actions';
import {
  authTokenExpired,
  refreshAuthTokenAfterSessionExpiry
} from '@/auth/actions';
import { AuthState } from '@/auth/userStates';
import logger from '@/common/logger';
import { RootState } from '@/rootStateTypes';
import { UserAuthentication } from '@/common/pageSetup';
import Time from '@/common/time';
import { StoreState } from '@/store';
import { setIsAuthenticated } from '@/../optimizely/utils/attributeHelpers';
import { getOrInitializeOptimizely } from '@/../optimizely/optimizely';
import { YUM_PAYMENT_TOKEN_KEY } from '@/configuration/constants';
import { setAuthState } from '@/header/actions';

export const AUTH_TOKEN_UPDATED_EVENT = 'ph/authTokenUpdated';

// We want to refresh preemptively before the token expires - this is
// how much earlier than expiration:
export const millisecondsToRefreshBeforeExpiration = Time.minutesToMilliseconds(2);
export const millisecondsBeforeConsideredInactive = Time.minutesToMilliseconds(45);
export const maxGuestTokenLength = 500;

let yumRefreshTimeoutId: NodeJS.Timeout | undefined;
let yumPaymentRefreshTimeoutId: NodeJS.Timeout | undefined;

export type InitializeAuthTokenHelpersProps = {
  getState: () => RootState;
  dispatch: Dispatch<StoreState>;
  localized: boolean;
  initLocalizationToken?: string;
  userAuthentication?: UserAuthentication;
  shouldCreateCart?: boolean;
  shouldGetCart?: boolean;
  selectors?: OfSelectorsAndSuch;
  yumAuthRefreshData?: YumRefreshTokenData;
};

export type InitializeAuthTokenHelpersPropsWithSelectors = {
  selectors: OfSelectorsAndSuch;
} & InitializeAuthTokenHelpersProps;

const msToRefreshBeforeExpire = 1_000 * 60;

const refreshLogger = (tokenExpAt: number, timeoutMs: number) => {
  const now = Date.now();
  logger.withoutTelemetry.debug('====================================');
  logger.withoutTelemetry.debug(`Token expires in: ${Math.floor((tokenExpAt - now) / msToRefreshBeforeExpire)} minutes`);
  logger.withoutTelemetry.debug(`Token will refresh in: ${Math.floor(timeoutMs / msToRefreshBeforeExpire)} minutes`);
  logger.withoutTelemetry.debug(`Token will refresh at: ${new Date((new Date()).getTime() + timeoutMs).toString()}`);
  logger.withoutTelemetry.debug('====================================');
};

export const fetchExpiryTime = async (expiresAt?: number, tokenName?: string): Promise<number> => {
  if (expiresAt) return expiresAt;
  const { result } = await authService.getYumAccessTokenExpiration(tokenName);
  return result || 0;
};

export const refreshTokenAndReinitialize = async (props: InitializeAuthTokenHelpersPropsWithSelectors): Promise<number | null | undefined> => {
  const { result: newTokenExpAt } = await authService.refreshYumAccessToken();
  if (newTokenExpAt) {
    if (props?.userAuthentication?.authState !== AuthState.GUEST) {
      props.dispatch(refreshAuthTokenAfterSessionExpiry());
    }
    telemetry.addCustomEvent('yum-access-token-refreshed');
  }
  return newTokenExpAt;
};

export const handleTokenRefreshFailure = async (props: InitializeAuthTokenHelpersPropsWithSelectors): Promise<void> => {
  props.dispatch(authTokenExpired());
  props.dispatch(setAuthState(AuthState.GUEST));
  telemetry.addCustomAttribute('authState', AuthState.GUEST);
  await initializeAuthTokenHelpers({ ...props, shouldGetCart: false, userAuthentication: { authState: AuthState.GUEST } });
};

export const handleYumTokenRefresh = async (expiresAt: number | undefined, props: InitializeAuthTokenHelpersPropsWithSelectors, refreshPaymentToken = false): Promise<void> => {
  logger.withoutTelemetry.debug('handleYumTokenRefresh');
  telemetry.addCustomEvent('yum-access-token-refresh-timeout-set');
  const optimizely = getOrInitializeOptimizely();
  const yumPaymentAccessTokenDecision = optimizely?.isFeatureEnabled('fr-web-3427-access-token-yum-payment-sdk');
  const isGuest = props?.userAuthentication?.authState === AuthState.GUEST;
  const tokenExpAt = await fetchExpiryTime(expiresAt);
  const timeoutMs = tokenExpAt ? tokenExpAt - Date.now() - msToRefreshBeforeExpire : 0;

  refreshLogger(tokenExpAt, timeoutMs);
  if (yumRefreshTimeoutId) {
    clearTimeout(yumRefreshTimeoutId);
  }

  window.dispatchEvent(new CustomEvent(AUTH_TOKEN_UPDATED_EVENT));

  yumRefreshTimeoutId = setTimeout(async () => {
    const newTokenExpAt = await refreshTokenAndReinitialize(props);

    if (!newTokenExpAt) {
      if (isGuest) {
        logger.withoutTelemetry.debug('Token Refresh failure. NOT retrying...');
        logger.error(new Error('Unable to refresh Yum guest token. NOT retrying.'));
      } else {
        logger.error(new Error('Unable to refresh Yum token. Falling back to guest.'));
        await handleTokenRefreshFailure(props);
      }
      return;
    }
    if (refreshPaymentToken && !yumPaymentAccessTokenDecision) {
      authService.createYumPaymentToken();
    }
    handleYumTokenRefresh(newTokenExpAt, props, refreshPaymentToken);
  }, timeoutMs);
};

export const handleYumPaymentTokenRefresh = async (expiresAt?: number): Promise<void> => {
  const optimizely = getOrInitializeOptimizely();
  const yumPaymentAccessTokenDecision = optimizely?.isFeatureEnabled('fr-web-3427-access-token-yum-payment-sdk');
  if (yumPaymentAccessTokenDecision) {
    return;
  }

  telemetry.addCustomEvent('yum-payment-token-refresh-timeout-set');
  const tokenExpAt = await fetchExpiryTime(expiresAt, YUM_PAYMENT_TOKEN_KEY);
  const timeoutMs = tokenExpAt ? tokenExpAt - Date.now() - msToRefreshBeforeExpire : 0;

  refreshLogger(tokenExpAt, timeoutMs);
  if (yumPaymentRefreshTimeoutId) {
    clearTimeout(yumPaymentRefreshTimeoutId);
  }

  yumPaymentRefreshTimeoutId = setTimeout(async () => {
    logger.withoutTelemetry.debug('Refreshing payment token');
    const { result: newTokenExpAt } = await authService.refreshYumPaymentToken();

    if (!newTokenExpAt) {
      logger.error(new Error('Unable to refresh Yum payment token'));
      return;
    }
    handleYumPaymentTokenRefresh(newTokenExpAt);
  }, timeoutMs);
};

export async function initializeAuthTokenHelpers(props: InitializeAuthTokenHelpersProps) : Promise<void> {
  const {
    getState,
    dispatch,
    initLocalizationToken,
    userAuthentication,
    shouldGetCart = false
  } = props;
  const defaultProps = {
    shouldCreateCart: false,
    shouldGetCart: false,
    selectors: getSelectors(getState)
  };
  const propsWithDefaults: InitializeAuthTokenHelpersPropsWithSelectors = { ...defaultProps, ...props };

  const optimizely = getOrInitializeOptimizely();
  const yumPaymentAccessTokenDecision = optimizely?.isFeatureEnabled('fr-web-3427-access-token-yum-payment-sdk');

  if (yumRefreshTimeoutId) clearTimeout(yumRefreshTimeoutId);
  if (yumPaymentRefreshTimeoutId && !yumPaymentAccessTokenDecision) clearTimeout(yumPaymentRefreshTimeoutId);

  try {
    const response = await optimizely?.onReady({ timeout: 2_000 });
    if (!response?.success) {
      throw new Error('Optimizely not ready after 2000ms timeout');
    }
  } catch (err) {
    const error = err instanceof Error ? err : new Error(typeof err === 'string' ? err : 'Unknown error');
    telemetry.addNoticeError(error);
  }

  let isAuthenticated = false;
  let paymentRefreshNeeded = true;
  const { authState } = userAuthentication || {};

  logger.withoutTelemetry.debug(`User state is: ${authState}`);
  switch (authState) {
    case AuthState.LOGGED_IN:
      handleYumTokenRefresh(userAuthentication?.expirationDate, propsWithDefaults);
      isAuthenticated = true;
      break;
    case AuthState.GUEST:
      paymentRefreshNeeded = false;
      logger.withoutTelemetry.debug('Creating Yum guest token');
      await authService.createYumGuestToken();
      logger.withoutTelemetry.debug('Handling Yum guest token refresh');
      handleYumTokenRefresh(userAuthentication?.expirationDate, propsWithDefaults, true);
      break;
    case AuthState.EXPIRED:
      logger.withoutTelemetry.debug('Attempting to refresh Yum token');
      handleYumTokenRefresh(0, propsWithDefaults);
      isAuthenticated = true;
      break;
    default:
      break;
  }

  setIsAuthenticated(isAuthenticated);

  if (yumPaymentAccessTokenDecision) {
    return;
  }

  logger.withoutTelemetry.debug('Creating Yum payment token');
  authService.createYumPaymentToken().then(() => {
    if (paymentRefreshNeeded) {
      logger.withoutTelemetry.debug('Initializing Yum payment token refresh');
      handleYumPaymentTokenRefresh();
    }
  });
}

type ReInitializePaymentProps = {
  localizationToken: string | undefined;
  isGuest: boolean;
  getState: () => RootState;
  dispatch: Dispatch<StoreState>;
};
export async function reInitializeYumGuestOrPayment(props: ReInitializePaymentProps): Promise<void> {
  logger.withoutTelemetry.debug('reInitializeYumGuestOrPayment');

  const { isGuest } = props;
  const authState = isGuest ? AuthState.GUEST : AuthState.LOGGED_IN;
  const removePaymentTokensOnly = !isGuest;
  const tokenHelperProps = {
    getState: props.getState,
    dispatch: props.dispatch,
    localized: Boolean(props.localizationToken),
    shouldCreateCart: true,
    shouldGetCart: true,
    initLocalizationToken: props.localizationToken,
    userAuthentication: { authState, expirationDate: 0 }
  };

  await authService.removeYumTokens(removePaymentTokensOnly);
  initializeAuthTokenHelpers(tokenHelperProps);
}
