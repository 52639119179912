import { Drawer } from '@material-ui/core';
import React from 'react';
import { useDecision, useFeature } from '@optimizely/react-sdk';
import { CartRailStyles } from '@/clientCore/cart/components/CartRail/CartRail.styles';
import { CCCartOperationsBoard } from '@/clientCore/cart/components/TempDevComponents/CCCartOperationsBoard';
import CartHeader from '@/clientCore/cart/components/CartHeader';
import EmptyCart from '@/clientCore/cart/components/CartRail/components/EmptyCart/EmptyCart';
import { useCartRail } from '@/clientCore/cart/components/CartRail/hooks/useCartRail';
import CartContent from '@/clientCore/cart/components/CartRail/components/CartContent';
import CartCheckout from '@/clientCore/cart/components/CartRail/components/CartCheckout';
import CartInfoAndWarningView from '@/clientCore/cart/components/CartRail/components/CartInfoAndWarningView';
import CartLocalization from '@/clientCore/cart/components/CartLocalization/CartLocalization';
import EditCart from '@/clientCore/cart/components/CartRail/components/EditCart';
import { MaxOrderLimitAlert } from '@/clientCore/cart/components/CartRail/components/MaxOrderLimitAlert/MaxOrderLimitAlert';
import Checkout7DLWarning from '@/clientCore/cart/components/CartRail/components/Checkout7DLWarning';
import Inline7DLWarning from '@/clientCore/cart/components/CartRail/components/Inline7DLWarning/Inline7DLWarning';
import CartChangedAlert from '@/clientCore/cart/components/CartRail/components/CartChangedAlert';
import CartRailBody from '@/clientCore/cart/components/CartRail/components/RailBody';

const CartRail = (): JSX.Element => {
  const railClasses = CartRailStyles();
  const [cartOperationsFlag] = useFeature('ops-web-1634-cc-cart-operations');
  const [data, handlers] = useCartRail();
  const {
    cart,
    isCartRailOpen,
    isEmptyCart,
    isLocalized,
    showInfoAndWarningView,
    alertMessageText,
    showEditCart,
    show7DLInlineWarning,
    show7DLCheckoutWarning,
    cartChangedAlert
  } = data;

  const { handleRailClose, setShow7DLCheckoutWarning } = handlers;
  const [{ enabled: useYumAddRemoveDecision }] = useDecision('fr-web-2773-yum-coupon-promotions-add-remove-hooks');

  const RailBody = () => {
    if (show7DLCheckoutWarning) {
      return (
        <Checkout7DLWarning
          items={cart?.items ?? []}
          setShow7DLCheckoutWarning={setShow7DLCheckoutWarning}
        />
      );
    }

    // if (shouldShowCartItemEditBuilderError) return <CartItemEditBuilderError />;

    if (showEditCart) {
      return <EditCart />;
    }

    // I want to use this for all message views making it redux driven.
    if (showInfoAndWarningView) return <CartInfoAndWarningView />;

    return (
      <>
        {isLocalized && (
          <div className={railClasses.localizationPromiseTime}>
            <CartLocalization />
          </div>
        )}
        {cartChangedAlert?.displayAlert && (
          <CartChangedAlert cartChangedAlert={cartChangedAlert} />
        )}
        {alertMessageText && (
          <MaxOrderLimitAlert>{alertMessageText}</MaxOrderLimitAlert>
        )}
        {show7DLInlineWarning && <Inline7DLWarning />}

        {isEmptyCart && <EmptyCart isLocalized={Boolean(isLocalized)} />}
        {isLocalized && cart?.items?.length ? (
          <>
            <CartContent />
            {!isEmptyCart && (
              <CartCheckout
                setShow7DLCheckoutWarning={setShow7DLCheckoutWarning}
              />
            )}
          </>
        ) : null}
      </>
    );
  };

  return (
    <Drawer
      classes={{ paper: railClasses.paper }}
      anchor="right"
      onClose={handleRailClose}
      open={isCartRailOpen}
      data-testid="cart-rail"
    >
      {cartOperationsFlag && <CCCartOperationsBoard />}
      <CartHeader handleRailClose={handleRailClose} />
      {useYumAddRemoveDecision
        ? (
          <CartRailBody
            cart={cart}
            isEmptyCart={isEmptyCart}
            isLocalized={isLocalized}
            showInfoAndWarningView={showInfoAndWarningView}
            alertMessageText={alertMessageText}
            showEditCart={showEditCart}
            show7DLInlineWarning={show7DLInlineWarning}
            show7DLCheckoutWarning={show7DLCheckoutWarning}
            cartChangedAlert={cartChangedAlert}
            setShow7DLCheckoutWarning={setShow7DLCheckoutWarning}
          />
        ) : <RailBody />}

    </Drawer>
  );
};

export default CartRail;
