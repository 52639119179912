import { useSelector } from 'react-redux';
import { selectors } from './salesExperience.slice';
import { WEB1 } from './constants';
import { UrlTransformer, UseSalesExperience } from './types';
import { web2Replacer } from './web2Replacer';

const web1Replacer: UrlTransformer = (uri) => uri;

const useSalesExperience: UseSalesExperience = () => {
  const experience = useSelector(selectors.experience);

  return {
    linkForSalesExperience: experience === WEB1 ? web1Replacer : web2Replacer
  };
};

export {
  useSalesExperience
};
