let retryCount = 0;
const TOTAL_MOCK_FAILURES = 1;
const mockAngularHost = {
    currentUiState(): AngularUiRouterState | undefined {
        if(retryCount < TOTAL_MOCK_FAILURES) {
            retryCount++;
            return undefined;
        }

        return { name: 'index.home' };
    },
    goToUiState() : void {
        // no-op in mock mode
    },
    onUiStateChangeStart(handler: AngularStateChangeHandler) {
        // no-op in mock mode
    },
    onUiStateChangeSuccess(handler: AngularStateChangeHandler) {
        // no-op in mock mode
    },
    onWeb1LocalizationUpdate(handler: AngularStateChangeHandler) {
        // no-op in mock mode
    },
    getWeb1InitLocalizedStore: () => undefined,
    localizationService(): any {
        return mockLocalizationService;
    },
    orderService(): any {
        return mockOrderService;
    },
    accountService(): any {
        return mockAccountService;
    },
    menuService(): any {
        return mockMenuService;
    }
}

const mockAccountService = {
    get: mockReturnedValue(require('./accountService/get.stub').default),
    get_login_user_data: mockResolvedValue(require('./accountService/get_login_user_data.stub').default),
}

const mockLocalizationService = {
    findNearByStoresByAddress: mockResolvedValue(require('./localizationService/findNearByStoresByAddress.stub').default),
    confirmLocation: mockResolvedValue(require('./localizationService/confirmLocation.stub').default),
    findNearByStoresByLatLng : mockResolvedValue(require('./localizationService/findNearByStoresByAddress.stub').default),
    get: mockResolvedValue({ storeNumber: '55555'})
}

const mockOrderService = {
    data: {
        cartQuantity: 0
    },
    getAlcoholItemInCart: () => true
}

const mockMenuService = {
    removeAlcohol: mockResolvedValue(require('./menuService/removeAlcohol.stub').default)
}

function mockReturnedValue(value: any) {
    if (typeof(jest) === 'undefined') {
        // When using this mock in our dev server, jest doesn't exist.
        // Handroll a mock instead.
        return () => value;
    }

    return jest.fn().mockReturnValue(value);
}

function mockResolvedValue(value: any) {
    if (typeof(jest) === 'undefined') {
        // When using this mock in our dev server, jest doesn't exist.
        // Handroll a mock instead.
        return () => new Promise(resolve => resolve(value));
    }

    return jest.fn().mockResolvedValue(value);
}

export default mockAngularHost;
