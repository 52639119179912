import loggingFetch from '../common/loggingFetch';
import { shouldMockAPIResponse } from '@/testUtils/shouldMockAPIResponse';

export interface ParsedResponse {
  response: any;
  status: number;
  headers: Headers;
}

export interface MockResponseProps {
  url: string;
  headers: HeadersInit,
  body: any
}

export const checkResponseStatus = (rawResponse: Response)  => {
  if (rawResponse.ok) {
    return rawResponse;
  }

  throw new Error;
};

export const parseJson = async (rawResponse: Response): Promise<ParsedResponse> => {
  let response;
  try {
    response = await rawResponse.clone().json();
  } catch (error) {
    // explicitly check for invalid JSON error
    response = await rawResponse.text();
  }
  return { response, status: rawResponse.status, headers: rawResponse.headers };
};

export function post(
  url: string,
  headers: HeadersInit,
  body?: any,
  mockApiResponse?: (props: MockResponseProps) => Promise<Response>
): Promise<Response> {
  if (mockApiResponse && shouldMockAPIResponse()) {
    return mockApiResponse({url, headers, body});
  }

  return loggingFetch(url, {
    method: 'POST', headers, body: JSON.stringify(body), credentials: 'include'
  });
}
export function get(
  url: string,
  headers: HeadersInit,
  mockApiResponse?: (props: MockResponseProps) => Promise<Response>
): Promise<Response> {
  if (mockApiResponse && shouldMockAPIResponse()) {
    return mockApiResponse({url, headers, body: undefined});
  }

  return loggingFetch(url, { method: 'GET', headers, credentials: 'include' });
}
export function put(
  url: string,
  headers: HeadersInit,
  body: any,
  mockApiResponse?: (props: MockResponseProps) => Promise<Response>
): Promise<Response> {
  if (mockApiResponse && shouldMockAPIResponse()) {
    return mockApiResponse({url, headers, body});
  }

  return loggingFetch(url, {
    method: 'PUT', headers, body: JSON.stringify(body), credentials: 'include'
  });
}

// eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle
export function _delete(
  url: string,
  headers: HeadersInit,
  body = {},
  mockApiResponse?: (props: MockResponseProps) => Promise<Response>
): Promise<Response> {
  if (mockApiResponse && shouldMockAPIResponse()) {
    return mockApiResponse({url, headers, body});
  }

  return loggingFetch(url, {
    method: 'DELETE', headers, body: JSON.stringify(body), credentials: 'include'
  });
}

export default {
  post,
  get,
  put,
  parseJson,
  delete: _delete
};
