import { useGetDealBuilderQuery } from '@pizza-hut-us-development/client-core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { OccasionApi } from '@/localization/constants';
import { selectOccasion } from '@/localization/selectors';
import { DealBuilderWithAvailability, transformGetDealBuilderData } from './transformGetDealBuilderData';
import { useTemporaryCCContext } from '../temporaryCCContext';
import { RootState } from '@/rootStateTypes';
import ProductId from '@/common/ProductId';
import useProduct from '@/graphql/hooks/variables/useProductItem';
import { transformationDebugLogging } from '@/clientCore/debuggerLogging';
import { debugModeSelectors } from '@/clientCore/redux/selectors/debugSelectors';
import { DebugModeOptions } from '@/clientCore/redux/debug/types';

export const useCCGetDealBuilderQuery = ({ itemId = '', skip = false }) => {
  const storeDetails = useSelector(localizationSelectors.storeDetails);
  const storeOccasion = useSelector(selectOccasion) as keyof typeof OccasionApi;
  const occasion = OccasionApi[storeOccasion] as OccasionApi;
  const [storeNumber, setStoreNumber] = useState<string>('');
  const [storeTimezone, setStoreTimeZone] = useState<string | undefined>(undefined);
  const debugEnabled = useSelector(debugModeSelectors.enabled);
  const debugMode = useSelector(debugModeSelectors.mode);

  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);
  const { isOptimizelyApiLoading } = useTemporaryCCContext();

  useEffect(() => {
    if (storeDetails?.storeNumber) {
      setStoreNumber(storeDetails.storeNumber);
    }
    if (storeDetails?.storeTimezone) setStoreTimeZone(storeDetails.storeTimezone);
  }, [storeDetails]);

  const { globalId } = new ProductId(itemId);
  const storeSpecificId = useProduct('discounts', itemId).variables?.itemID;
  const idToUse = isYumEcomm ? globalId : storeSpecificId;

  const {
    data, isLoading, isFetching, error
  } = useGetDealBuilderQuery(
    { storeNumber, itemId: idToUse },
    { skip: !storeNumber || skip || (isOptimizelyApiLoading && !isYumEcomm) }
  );

  if (skip || error) {
    return {
      data: null,
      loading: false,
      error: null,
      storeID: null
    };
  }

  if (isLoading || isFetching || !storeNumber || (isOptimizelyApiLoading && !isYumEcomm)) {
    return {
      data: undefined,
      loading: true,
      error: null,
      storeID: null
    };
  }

  const transformedData = data && storeTimezone
    ? transformGetDealBuilderData(data as DealBuilderWithAvailability, occasion, storeTimezone || '', isYumEcomm)
    : undefined;

  if (debugEnabled) {
    transformationDebugLogging('useCCGetDealBuilderQuery', useGetDealBuilderQuery.name, data, transformedData, debugMode, [
      DebugModeOptions.TRANSFORMATIONS,
      DebugModeOptions.MENU,
      DebugModeOptions.DEALS
    ]);
  }

  return {
    data: transformedData,
    loading: isLoading,
    storeID: storeNumber
  };
};
