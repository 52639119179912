import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  Cart,
  CartItem,
  useDeleteCartItemMutation
} from '@pizza-hut-us-development/client-core';
import Routes from '@/router/routes';
import triggerMutationWrapper from '@/clientCore/helper/triggerMutationWrapper';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import { toggleCartLoadingStatus } from '@/clientCore/redux/cart/CartSlice';
import { removeSortedItemPointer } from '@/clientCore/redux/rail/CartRailSlice';

export const useCartDeleteItem = () => {
  const dispatch = useDispatch();
  // Tools
  const router = useRouter();
  // CC Selectors
  const cartId = useSelector(orderSelectors.cartId);
  // Delete Setup
  const [deleteCartItem, { isLoading: isDeletingProduct }] = useDeleteCartItemMutation();

  const handleDeleteCartItem = useCallback((cartItem: CartItem, onSuccess?: (response: Cart) => void) => {
    dispatch(toggleCartLoadingStatus({ loadingState: true }));

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    triggerMutationWrapper(
      deleteCartItem({ cartId, cartItemId: cartItem?.cartItemId }),
      {
        onSuccess: async (response) => {
          if (onSuccess) onSuccess(response);
          dispatch(toggleCartLoadingStatus({ loadingState: false }));
          dispatch(
            removeSortedItemPointer({
              cartItemId: cartItem.cartItemId,
              itemId: cartItem.id
            })
          );
          // If there are no items in cart, we will reroute users to homepage if they are on the checkout route
          if (
            !response.items
              || (response.items
                && response.items.length === 0
                && router.pathname === Routes.CHECKOUTW2)
          ) {
            await router.push(Routes.HOME);
          }
        },
        onError: (e) => {
          dispatch(toggleCartLoadingStatus({ loadingState: false }));
          // TODO: Do we have some existing error modal we dispatch?
          console.error('Unable to delete cart item', e);
        }
      }
    );
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [cartId, deleteCartItem]);

  return { handleDeleteCartItem, isDeletingProduct };
};
