/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { UserAuthentication } from '@/common/pageSetup';
import useCustomerDetails from '@/account/profile/hooks/useCustomerDetails';
import { userDomainSelectors } from '@/header/userDomainSelectors';
import { AuthState } from '@/auth/userStates';
import { authRefreshRequestReceived } from '@/auth/actions';

interface WithCustomerDetailsProps {
  userAuth: UserAuthentication;
}

const WithCustomerDetails = (props: WithCustomerDetailsProps): JSX.Element => {
  const { userAuth } = props;
  const { fetchCustomerDetails } = useCustomerDetails();
  const shouldRefreshAuth = useSelector(userDomainSelectors.shouldRefreshAuth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userAuth?.authState === AuthState.LOGGED_IN) {
      fetchCustomerDetails();
    }
  }, []);

  useEffect(() => {
    if (shouldRefreshAuth) {
      fetchCustomerDetails();
      dispatch(authRefreshRequestReceived());
    }
  }, [shouldRefreshAuth]);

  return <></>;
};

export default WithCustomerDetails;
