import { compose } from '@reduxjs/toolkit';
import { RootState } from '@/rootStateTypes';
import { DebugTypes } from '../debug/types';

interface ClientCoreDebugModeStoreType {
  enabled: boolean;
  mode: DebugTypes;
}

const domain = (state: RootState): ClientCoreDebugModeStoreType => state.debugMode;

const enabled = (
  ccStore: ClientCoreDebugModeStoreType
): boolean => ccStore.enabled;
const mode = (
  ccStore: ClientCoreDebugModeStoreType
): DebugTypes => ccStore.mode;

export const debugModeSelectors = {
  enabled: compose(enabled, domain),
  mode: compose(mode, domain)
};
