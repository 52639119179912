import angularHost from '../../../../embedded-web2/src/embedding-framework/angular-adapters/angularHost';

const angularCartService : CartServiceLegacy = {
  removeAlcoholFromCart(): Promise<any> {
    return angularHost.menuService().removeAlcohol();
  },
  clear(): Promise<any> {
    // clear cart is handled through selecting store, no changes needed for embedded
    return Promise.resolve();
  },
  getCartInfo(): Promise<any> {
    // Putting this in to get compile-time checking working again
    const promiseThatNeverResolves = new Promise(() => {});
    return promiseThatNeverResolves;
  }
};

export default angularCartService;
