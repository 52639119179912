import { capitalize, startCase } from '@/utils';
import { AddableCartItemKinds } from '@/api/phdApiV2Client/request.types';
import {
  commonDealBuilderScreenAnalytics,
  commonPizzaMenuOnDealAnalytics
} from '@/builders/deals/analytics/analytics';
import { isDealType } from '@/builders/deals/hooks/useDeal';
import {
  ChildDealStep,
  Deal,
  DealStep,
  SelectedRecipe
} from '@/builders/deals/slice/dealTypes';
import { BuiltPizza } from '@/builders/pizza/dataTransformers/builderTypes';
import { PizzaOptionsAnalytics } from '@/builders/pizza/hooks/usePizzaOptions';
import ProductId from '@/common/ProductId';
import { joinText } from '@/common/string-formatter';
import {
  onUpsellToastCheckoutClick,
  onUpsellToastView
} from '@/dataAnalytics/upsell/upsellAnalytics';
import { LINEUP, UPSELL } from '@/deals/constants';
import { MODIFIER, PIZZA, SIZE } from '@/domain/constants';
import {
  DisplayableMenuItem,
  DisplayableProductWithSubcategory,
  isPizzaProduct,
  Product
} from '@/domain/product/types';
import { UpsellProducts } from '@/graphql/queries/upsell/upsell';
import { OccasionString, OccasionStringValues } from '@/localization/constants';
import { MenuRecipe } from '@/menu/pizza/pizzaMenuTypes';
import { DealAnalytics, PizzaAnalytics, Promo } from '@/rootStateTypes';
import mapObjectToAddPrefix from '../common/mapObjectToAddPrefix';
import {
  BaseAnalytics,
  BaseItemsAnalytics,
  BaseProductsAnalytics,
  BasePromosAnalytics,
  BaseSiteAnalytics,
  BaseStoreSearchAnalytics,
  BaseWarningCart,
  BeaconIds,
  BuilderSectionAction,
  CommonBaseAnalytics,
  CommonPizzaBulderAnalytics,
  DealBaseAnalytics,
  DragonTailIframeAnalytics,
  ErrorAnalytics,
  ItemAnalytics,
  ItemAsProductAnalytics,
  ItemInputAnalytics,
  MenuProductAnalytics,
  NoScreenLevelAnalytics,
  PageViewAnalytics,
  PageViewWithItemsAnalytics,
  PizzaProductAnalytics,
  PromoAnalytics,
  PromoInputAnalytics,
  SCCFSectionAction,
  ScreenLevelAnalytics, SearchResultsAnalytics,
  ToppingsAction,
  ToppingsSelectionAnalytics
} from './analyticsTypes';
import angularDataModel from './dataModel';
import {
  onLineupBackArrowClick,
  onLineupContinueClick,
  onLineupToastCheckoutClick,
  onLineupToastView
} from './lineup/lineupAnalytics';

interface AddressType {
  zipcode?: string;
  city?: string;
  state?: string;
}

export const getMenuName = (isLocalized: boolean, isDeal = false): string => {
  if (isLocalized) {
    return isDeal ? 'Builder > Deal Builder' : 'Menu';
  }
  return 'National Menu';
};

/* PARTIALS from BaseAnalytics */

export const commonLocalizedCategory = (
  category: string,
  dealName?: string
): string => {
  const isDeal = category === 'Deals';

  const menuName = getMenuName(true, !!dealName);
  const menuAndCategory = `${menuName} > ${category}`;

  return category && !isDeal ? menuAndCategory : menuName;
};

export const productInfoSlideModifierAnalytics = (
  category: string,
  isLocalized = true,
  dealName?: string,
  dealId?: string
): ScreenLevelAnalytics => {
  const screenString = getMenuName(isLocalized, !!dealName);
  const screenCategory = `${screenString}`;
  const screenLevel1 = dealName && isLocalized ? 'Builder' : screenString;
  const commonScreenLevel = 'Builder > Deal Builder';

  const common = {
    screen_name: screenCategory,
    screen_level3: `${screenCategory} >  Product Info Slide`,
    screen_level4: `${screenCategory} >  Product Info Slide`,
    level3_aggregation: screenCategory
  };

  if (dealId) {
    return {
      screen_name: screenCategory,
      screen_level1: screenLevel1,
      screen_level2: commonScreenLevel,
      screen_level3: `${commonScreenLevel}`,
      screen_level4: `${commonScreenLevel}`,
      level2_breadcrumb: 'Deals > Deal Builder',
      level3_aggregation: 'Deal Builder',
      deal_id: new ProductId(dealId).globalId,
      deal_name: dealName
    };
  }

  return {
    ...common,
    screen_level1: screenString,
    screen_level2: screenCategory,
    level2_breadcrumb: screenCategory
  };
};

export const commonMenuBaseAnalytics = (
  category: string,
  isLocalized = true,
  dealName?: string,
  dealId?: string
): ScreenLevelAnalytics => {
  const screenString = getMenuName(isLocalized, !!dealName);
  const screenCategory = `${screenString} > ${category}`;

  const common = {
    screen_name: screenCategory,
    screen_level3: screenCategory,
    screen_level4: screenCategory,
    level3_aggregation: screenCategory
  };

  if (dealId && dealName) {
    const screenLevel1 = dealName && isLocalized ? 'Builder' : screenString;

    return {
      ...common,
      screen_level1: screenLevel1,
      screen_level2: screenString,
      level2_breadcrumb: screenString,
      deal_id: new ProductId(dealId).globalId,
      deal_name: dealName
    };
  }

  return {
    ...common,
    screen_level1: screenString,
    screen_level2: screenCategory,
    level2_breadcrumb: screenCategory
  };
};

export const commonUpsellAnalytics = (
  dealId?: string,
  dealName?: string
): Omit<CommonBaseAnalytics, 'event'> => ({
  screen_name: 'Upsell',
  screen_level1: 'Upsell',
  screen_level2: 'Upsell',
  screen_level3: 'Upsell',
  screen_level4: 'Upsell',
  level2_breadcrumb: 'Upsell',
  level3_aggregation: 'Upsell',
  ...(dealId
    && dealName && {
    deal_id: new ProductId(dealId).globalId,
    deal_name: dealName
  })
});

export const commonMenuPageLoadAnalytics = (
  category: string
): CommonBaseAnalytics => ({
  event: 'screen_view',
  screen_name: `Menu > ${category}`,
  screen_level1: 'Menu',
  screen_level2: `Menu > ${category}`,
  screen_level3: `Menu > ${category}`,
  screen_level4: `Menu > ${category}`,
  level2_breadcrumb: `Menu > ${category}`,
  level3_aggregation: `Menu > ${category}`
});

export const commonPizzaBuilderClickAnalytics = (
  pizzaName: string,
  dealName?: string,
  dealId?: string,
  isLineup = false
): CommonPizzaBulderAnalytics => {
  const common = {
    event: 'button_click',
    screen_level1: 'Builder',
    form_name: 'Pizza Builder',
    product_name_hit: pizzaName
  };

  if ((dealName && dealId) || isLineup) {
    return {
      ...common,
      screen_name: `Builder > Deal Builder > Pizza Builder${isLineup ? ` > ${LINEUP}` : ''
      }`,
      screen_level2: 'Builder > Deal Builder',
      screen_level3: 'Builder > Deal Builder > Pizza Builder',
      screen_level4: `Builder > Deal Builder > Pizza Builder > ${isLineup ? `${LINEUP}` : dealName
      }`,
      level2_breadcrumb: 'Deals > Pizza Builder',
      level3_aggregation: `Pizza Builder${isLineup ? ` > ${LINEUP}` : ''}`,
      deal_id: isLineup ? LINEUP : new ProductId(dealId || '').globalId,
      deal_name: isLineup ? LINEUP : dealName
    };
  }

  return {
    ...common,
    screen_name: 'Builder > Pizza Builder',
    screen_level2: 'Builder > Pizza Builder',
    screen_level3: 'Builder > Pizza Builder',
    screen_level4: `Builder > Pizza Builder > ${pizzaName}`,
    level2_breadcrumb: 'Menu > Pizza Builder',
    level3_aggregation: 'Menu > Pizza Builder'
  };
};

export const commonDealPizzaBuilderClickAnalytics = (
  pizzaName: string,
  dealName: string,
  dealId: string
): Partial<BaseAnalytics & ScreenLevelAnalytics> => ({
  event: 'button_click',
  screen_name: 'Builder > Deal Builder > Pizza Builder',
  screen_level1: 'Builder',
  screen_level2: 'Builder > Deal Builder',
  screen_level3: 'Builder > Deal Builder > Pizza Builder',
  screen_level4: `Builder > Deal Builder > Pizza Builder > ${dealName}`,
  level2_breadcrumb: 'Deals > Pizza Builder',
  level3_aggregation: 'Pizza Builder',
  form_name: 'Pizza Builder',
  product_name_hit: pizzaName,
  deal_name: dealName,
  deal_id: dealId
});

export const commonAddToDealOrCartAnalytics = (
  dealName: string
): Partial<ScreenLevelAnalytics & BaseAnalytics> => ({
  event_category: 'Builder > Deal Builder',
  screen_name: 'Builder > Deal Builder > Menu',
  screen_level1: 'Builder',
  screen_level2: 'Builder > Deal Builder',
  screen_level3: 'Builder > Deal Builder > Menu',
  screen_level4: `Builder > Deal Builder > Menu > ${dealName}`,
  level2_breadcrumb: 'Deals > Deal Builder',
  level3_aggregation: 'Menu > Deal Builder'
});

/* PARTIALS from BaseAnalytics */

const getLocalizationWidgetScreenNameDetails = (
  occasion: string,
  storeSearchStage: string,
  level2Breadcrumb: string
): ScreenLevelAnalytics => ({
  screen_name: `Localization Widget > ${occasion} > Store Search ${storeSearchStage}`,
  screen_level1: 'Localization Widget',
  screen_level2: `Localization Widget > ${occasion}`,
  screen_level3: `Localization Widget > ${occasion} > Store Search ${storeSearchStage}`,
  screen_level4: `Localization Widget > ${occasion} > Store Search ${storeSearchStage}`,
  level2_breadcrumb: `Localization Widget > ${level2Breadcrumb}`,
  level3_aggregation: `Store Search ${storeSearchStage}`
});

export const onRibbonInitialLoad = (): BaseAnalytics => ({
  event: 'react_widget_impression',
  screen_name: 'home',
  event_category: 'home',
  event_action: 'localization impression',
  event_label: 'home > localization impression',
  screen_level1: 'home',
  screen_level2: 'home',
  screen_level3: 'home',
  screen_level4: 'home',
  level2_breadcrumb: 'home',
  level3_aggregation: 'home',
  beacon_id: BeaconIds.HOMEPAGE.RIBBON.INITIAL_LOAD
});

export const onRibbonClick = (): BaseAnalytics => ({
  event: 'button_click',
  screen_name: 'home',
  event_category: 'home',
  event_action: 'localization click',
  event_label: 'home > localization click',
  screen_level1: 'home',
  screen_level2: 'home',
  screen_level3: 'home',
  screen_level4: 'home',
  level2_breadcrumb: 'home',
  level3_aggregation: 'home',
  beacon_id: BeaconIds.HOMEPAGE.RIBBON.CLICK
});

export const onInitialHomepagePageLoadPromoInView = (
  label: string,
  promo: PromoAnalytics
): BasePromosAnalytics => ({
  beacon_id: BeaconIds.HOMEPAGE.LOAD_PROMO,
  event: 'promo_impression',
  screen_name: 'Home',
  event_category: 'Home',
  event_action: `${label} > Promo Impression`,
  event_label: `Home > ${label} > Promo Impression`,
  screen_level1: 'Home',
  screen_level2: 'Home',
  screen_level3: 'Home',
  screen_level4: 'Home',
  level2_breadcrumb: 'Home',
  level3_aggregation: 'Home',
  promos: [promo]
});

const isItemAnalytics = (
  item: ItemAnalytics | ItemInputAnalytics
): item is ItemAnalytics => 'item_name' in item;

export const createItem = (
  item: ItemInputAnalytics | ItemAnalytics,
  addItemAction = false
): ItemAnalytics | undefined => {
  if (!item) {
    return undefined;
  }

  const result = isItemAnalytics(item)
    ? item
    : {
      item_index: item.itemIndex,
      item_list: item.itemList,
      item_price: item.itemPrice,
      item_name: item.itemName,
      item_category: item.itemCategory,
      item_position: item.itemPosition,
      item_stock: item.outOfStock ? 'Out of Stock' : 'In Stock'
    };

  if (addItemAction === true) {
    return { ...result, item_action: 'click' };
  }

  return result;
};

const createItemAsProduct = (
  item: ItemInputAnalytics
): ItemAsProductAnalytics => ({
  product_index: item.itemIndex,
  product_list: item.itemList,
  product_price: item.itemPrice,
  product_name: item.itemName,
  product_category: item.itemCategory,
  product_position: item.itemPosition
});

function getHomePageItems(
  items: ItemInputAnalytics[]
): (ItemAnalytics | undefined)[] {
  return items.map((item) => createItem(item));
}

export const onInitialHomePageScrollToHutFavorites = (
  label: string,
  item: ItemInputAnalytics
): BaseItemsAnalytics => ({
  beacon_id: BeaconIds.HOMEPAGE.PAGE_SCROLL_TO_HUT_FAVORITES,
  event: 'product_impression',
  screen_name: 'Home',
  event_category: 'Home',
  event_action: `${label} > Product Impression`,
  event_label: `Home > ${label} > Product Impression`,
  screen_level1: 'Home',
  screen_level2: 'Home',
  screen_level3: 'Home',
  screen_level4: 'Home',
  level2_breadcrumb: 'Home',
  level3_aggregation: 'Home',
  items: [createItem(item)]
});

export const onHutFavoriteOneClick = (
  label: string,
  item: ItemInputAnalytics,
  isAddToCartPressed: boolean
): BaseProductsAnalytics<ItemAsProductAnalytics> => ({
  beacon_id: BeaconIds.HOMEPAGE.HUT_FAVORITE_CLICK,
  event: 'product_click',
  screen_name: 'Home',
  event_category: 'Home',
  event_action: label,
  event_label: `Home > ${item?.itemPosition} > ${item?.itemName} > ${isAddToCartPressed ? 'Add-to-Cart' : 'Customize'}`,
  screen_level1: 'Home',
  screen_level2: 'Home',
  screen_level3: 'Home',
  screen_level4: 'Home',
  level2_breadcrumb: 'Home',
  level3_aggregation: 'Home',
  product_action: 'homepage_click',
  products: [createItemAsProduct(item)]
});

export const onHutFavoriteClick = (
  label: string,
  item: ItemInputAnalytics
): BaseProductsAnalytics<ItemAsProductAnalytics> => ({
  beacon_id: BeaconIds.HOMEPAGE.HUT_FAVORITE_CLICK,
  event: 'product_click',
  screen_name: 'Home',
  event_category: 'Home',
  event_action: label,
  event_label: `Home > ${item?.itemPosition} > ${item?.itemName}`,
  screen_level1: 'Home',
  screen_level2: 'Home',
  screen_level3: 'Home',
  screen_level4: 'Home',
  level2_breadcrumb: 'Home',
  level3_aggregation: 'Home',
  product_action: 'homepage_click',
  products: [createItemAsProduct(item)]
});

export const onPromoClick = (
  label: string,
  promo: PromoAnalytics
): BasePromosAnalytics => ({
  beacon_id: BeaconIds.HOMEPAGE.PROMO_CLICK,
  event: 'promo_click',
  screen_name: 'Home',
  event_category: 'Home',
  event_action: `${label} > Promo Click`,
  event_label: `Home > ${label} > Promo Click`,
  screen_level1: 'Home',
  screen_level2: 'Home',
  screen_level3: 'Home',
  screen_level4: 'Home',
  level2_breadcrumb: 'Home',
  level3_aggregation: 'Home',
  promos: [promo]
});

export const onOpenLocalizationRail = (
  isSavedAddress = false
): Partial<BaseAnalytics> => ({
  ...getLocalizationWidgetScreenNameDetails(
    'Carryout',
    'Form',
    `Carryout ${isSavedAddress ? '> Saved Address' : ''}`.trim()
  ),
  event: 'screen_view',
  form_name: 'Carryout Store Search Form',
  beacon_id: BeaconIds.STORE.OPEN_LOCALIZATION
});

export enum SearchFormAnalyticsActionType {
  CLOSE = 'close',
  USE_GPS = 'use gps',
  SWITCH_FROM_CARRYOUT = 'switch from carryout',
  SWITCH_FROM_DELIVERY = 'switch from delivery'
}

export const storeSearchFormAnalytics = (
  occasion: string,
  actionType: SearchFormAnalyticsActionType,
  isSavedAddress = false
): BaseAnalytics => {
  let eventAction;
  let eventLabel;
  let eventOccasion;
  if (actionType === SearchFormAnalyticsActionType.USE_GPS) {
    eventAction = 'Use my location';
    eventLabel = 'Use GPS';
    eventOccasion = occasion;
  } else if (actionType === SearchFormAnalyticsActionType.CLOSE) {
    eventAction = 'close';
    eventLabel = 'close';
    eventOccasion = occasion;
  } else {
    eventAction = occasion;
    eventLabel = occasion;
    eventOccasion = actionType === SearchFormAnalyticsActionType.SWITCH_FROM_CARRYOUT
      ? OccasionString.C
      : OccasionString.D;
  }

  return {
    ...getLocalizationWidgetScreenNameDetails(
      eventOccasion,
      'Form',
      `${eventOccasion} ${isSavedAddress ? '> Saved Address' : ''}`.trim()
    ),
    event: 'button_click',
    event_action: eventAction,
    event_category: `Localization Widget > ${eventOccasion}`,
    event_label: `Localization widget > ${eventOccasion} > ${eventLabel}`,
    beacon_id: BeaconIds.STORE.SEARCH.FORM
  };
};

export const storeSearchSuccessAnalytics = (
  occasion: string,
  isSavedAddress = false
): BaseAnalytics => ({
  ...getLocalizationWidgetScreenNameDetails(
    occasion,
    'Form',
    `${occasion} ${isSavedAddress ? '> Saved Address' : ''}`.trim()
  ),
  event: 'button_click',
  screen_name: 'Localization',
  event_action: 'Store Search Success',
  event_category: `Localization Widget > ${occasion}`,
  event_label: `Localization Widget > ${occasion} > Store Search Success`,
  form_name: `${occasion} Store Search Form`,
  beacon_id: BeaconIds.STORE.SEARCH.SUCCESS
});

const getStoreSearchTerm = (
  occasion: OccasionStringValues,
  address: AddressType
): string | undefined => {
  if (occasion === 'Delivery') {
    return address.zipcode;
  }
  if (occasion === 'Carryout') {
    if (address.city && address.state) {
      return `${address.city}, ${address.state}`;
    }
    if (address.zipcode) {
      return address.zipcode;
    }
    return 'GPS';
  }
  return undefined;
};

export const storeSearchNoResultsAnalytics = (
  occasion: OccasionStringValues,
  address: AddressType,
  isSavedAddress = false
): BaseStoreSearchAnalytics => ({
  ...getLocalizationWidgetScreenNameDetails(
    occasion,
    'Form',
    `${occasion} ${isSavedAddress ? '> Saved Address' : ''}`.trim()
  ),
  event: 'button_click',
  event_action: 'Popup',
  event_category: `Localization Widget > ${occasion} > Store Search No Results`,
  event_label: `Localization Widget > ${occasion} > Store Search No Results > Popup`,
  store_search_term: getStoreSearchTerm(occasion, address),
  store_search_number_results: '0',
  form_name: `${occasion} Store Search Form`,
  beacon_id: BeaconIds.STORE.SEARCH.NO_RESULT
});

export const storeSearchNoResultsActionAnalytics = (
  occasion: string,
  userAction: string,
  isSavedAddress = false
): BaseSiteAnalytics => ({
  ...getLocalizationWidgetScreenNameDetails(
    occasion,
    'Form',
    `${occasion} ${isSavedAddress ? '> Saved Address' : ''}`.trim()
  ),
  event: 'button_click',
  event_action: userAction,
  event_category: `Localization Widget > ${occasion} > Store Search No Results`,
  event_label: `Localization Widget > ${occasion} > Store Search No Results > ${userAction}`,
  site_type: 'React',
  code_version: 'v1.00',
  form_name: `${occasion} Store Search Form`,
  beacon_id: BeaconIds.STORE.SEARCH.NO_RESULT_ACTION
});

export const storeResultsAnalytics = (
  occasion: OccasionStringValues,
  address: AddressType,
  numOfStoreResults: number,
  promiseTime?: string,
  deliveryStore?: StoreDetail
): Partial<BaseStoreSearchAnalytics> => ({
  ...getLocalizationWidgetScreenNameDetails(occasion, 'Results', occasion),
  event: 'screen_view',
  event_name: 'page_view',
  store_search_term: getStoreSearchTerm(occasion, address),
  store_search_number_results: numOfStoreResults.toString(),
  beacon_id: BeaconIds.STORE.SEARCH.RESULTS,
  delivery_initial_promise_time_range: occasion === 'Delivery' ? promiseTime : undefined,
  store_search_results_store_id: deliveryStore?.storeNumber
});

export const storeTileCtaAnalytics = (
  occasion: string,
  linkText: string
): BaseAnalytics => ({
  ...getLocalizationWidgetScreenNameDetails(occasion, 'Results', occasion),
  event: 'button_click',
  event_action: linkText,
  event_category: `Localization Widget > ${occasion} > Store Search Results`,
  event_label: `Localization Widget > ${occasion} > Store Search Results > ${linkText}`,
  beacon_id: BeaconIds.STORE.TILE_CTA
});

export const storeResultsViewButtonAnalytics = (
  occasion: string,
  linkText: string
): BaseAnalytics => ({
  ...getLocalizationWidgetScreenNameDetails(occasion, 'Results', occasion),
  event: 'button_click',
  event_action: linkText,
  event_category: `Localization Widget > ${occasion} > Store Search Results`,
  event_label: `Localization Widget > ${occasion} > Store Search Results > ${linkText}`,
  beacon_id: BeaconIds.STORE.BUTTON
});

const getScreenLevel = (level: number, screenNames: string[] | undefined) => screenNames
  ?.map((name, i) => (i ? ` > ${name}` : name))
  .slice(0, level)
  .join('');

const getScreenDetails = (
  screenName: string,
  occasion?: string,
  isSavedAddress?: boolean,
  storeSearchStage?: string
): ScreenLevelAnalytics => {
  if (screenName === 'localization') {
    return {
      ...getLocalizationWidgetScreenNameDetails(
        occasion || '',
        storeSearchStage || '',
        `${occasion} ${isSavedAddress ? '> Saved Address' : ''}`.trim()
      )
    };
  }

  if (screenName === 'web1') {
    const dataModel = angularDataModel();

    return {
      screen_name: dataModel.page?.name,
      screen_level1: dataModel.page?.categorization?.level1,
      screen_level2: dataModel.page?.categorization?.level2,
      screen_level3: dataModel.page?.categorization?.level3,
      screen_level4: dataModel.page?.categorization?.level4
    };
  }
  const screenNameLevel = screenName
    .match(/(?:(?!\/[A-Z]+).)*/gi)
    ?.filter((path) => path)
    ?.map(startCase);

  return {
    screen_name: getScreenLevel(2, screenNameLevel),
    screen_level1: getScreenLevel(1, screenNameLevel),
    screen_level2: getScreenLevel(2, screenNameLevel),
    screen_level3: getScreenLevel(3, screenNameLevel),
    screen_level4: getScreenLevel(4, screenNameLevel),
    level2_breadcrumb: getScreenLevel(2, screenNameLevel),
    level3_aggregation: getScreenLevel(3, screenNameLevel)
  };
};

export const onSearchCarryoutAnalytics = (version: string): Partial<SearchResultsAnalytics> => ({
  beacon_id: '80.21',
  event: 'carryout_version',
  event_action: 'Localization Version',
  event_label: version,
  event_category: ' Localization Widget > Carryout',
  event_name: 'carryout_version',
  localization_version: version
});

export const modalErrorPopupAnalytics = (
  modalTitle: string,
  modalMessage: string,
  screenName: string,
  errorCode?: string,
  occasion?: string,
  isSavedAddress?: boolean,
  storeSearchStage?: string
): ErrorAnalytics => ({
  ...getScreenDetails(screenName, occasion, isSavedAddress, storeSearchStage),
  error_code: errorCode,
  event: 'error_message',
  event_action: modalTitle,
  event_category: 'Site Pop Up Messages',
  event_label: modalMessage,
  error_message: modalMessage,
  beacon_id: BeaconIds.MODALS.ERROR
});

export const modalErrorPopupClickAnalytics = (
  modalTitle: string,
  modalMessage: string,
  buttonText: string,
  screenName: string,
  occasion?: string,
  isSavedAddress?: boolean,
  storeSearchStage?: string
): ErrorAnalytics => ({
  ...getScreenDetails(screenName, occasion, isSavedAddress, storeSearchStage),
  event: 'error_message',
  event_action: `${modalTitle} > ${buttonText}`,
  event_category: 'Site Pop Up Messages',
  event_label: `${modalMessage} > ${buttonText}`,
  error_message: modalMessage,
  beacon_id: BeaconIds.MODALS.CLICK_ERROR
});

export const createPromo = (promo: PromoInputAnalytics): PromoAnalytics => ({
  promo_name: promo?.promoName,
  promo_creative: promo?.promoCreative,
  promo_type: promo?.promoType,
  promo_position: promo?.promoPosition,
  promo_id: promo?.promoID
});

function getHomePagePromos(promo: Promo) {
  const {
    breakingNews,
    heroes = [],
    sidekicks = [],
    marketingBanners = []
  } = promo;

  const assignIndexToPromo = (item: PromoAnalytics, index: number) => ({
    promo_index: index,
    ...item
  });

  const promos = [
    ...heroes.map(createPromo),
    ...sidekicks.map(createPromo),
    ...marketingBanners.map(createPromo)
  ];
  if (breakingNews) promos.unshift(createPromo(breakingNews));

  return promos.map(assignIndexToPromo);
}

export const onHomepageInitialLoadAnalytics = ({
  promo,
  items
}: {
  promo: Promo;
  items: ItemInputAnalytics[];
}): Partial<BasePromosAnalytics> & {
    items: (ItemAnalytics | undefined)[];
  } => ({
  ...getScreenDetails('Home'),
  promos: [...getHomePagePromos(promo)],
  items: [...getHomePageItems(items)],
  tile_alignment: `${promo?.heroes?.length}-${promo?.sidekicks?.length}-${items?.length}-${promo?.marketingBanners?.length}`,
  beacon_id: BeaconIds.HOMEPAGE.INITIAL_LOAD,
  event: 'screen_view'
});

export const createPizzaAnalytics = (
  pizza: PizzaAnalytics | DealAnalytics | null
): PizzaProductAnalytics => mapObjectToAddPrefix(pizza || {}, 'product');

const onPizzaBuilderLoadEventInView = (
  label: string,
  recipe: string
): Partial<BaseAnalytics> => ({
  screen_name: `Builder > ${label}`,
  screen_level1: 'Builder',
  screen_level2: `Builder > ${label}`,
  screen_level3: `Builder > ${label}`,
  screen_level4: `Builder > ${label} > ${recipe}`,
  level2_breadcrumb: `Pizza > ${label}`,
  level3_aggregation: `Builder > ${label}`
});

export const onLineupPizzaBuilderPageview = (
  recipe: PizzaOptionsAnalytics
): Partial<BaseProductsAnalytics<PizzaProductAnalytics>> => ({
  beacon_id: BeaconIds.BUILDER.INITIAL_CASE,
  product_action: 'Detail',
  product_name_hit: recipe?.name || '',
  products: [
    {
      product_id: recipe?.id ?? '',
      product_name: recipe?.name || '',
      product_price: (recipe.price || 0) / 100,
      product_sauce: recipe?.sauce?.name || '',
      product_cheese: recipe?.cheese?.name || '',
      product_crust: recipe?.crust?.name || '',
      product_finisher: recipe?.finisher?.name || '',
      product_veggies: joinText(recipe.veggieToppings?.map(({ name }) => name)),
      product_meats: joinText(recipe.meatToppings?.map(({ name }) => name)),
      product_category: LINEUP,
      product_index: 0,
      product_deal_id: LINEUP,
      product_deal_name: LINEUP
    }
  ],
  event: 'screen_view',
  form_name: 'Pizza Builder',
  screen_name: 'Builder > Deal Builder > Pizza Builder > Lineup',
  screen_level1: 'Builder',
  screen_level2: 'Builder > Deal Builder',
  screen_level3: 'Builder > Deal Builder > Pizza Builder',
  screen_level4: 'Builder > Deal Builder > Pizza Builder > Lineup',
  level2_breadcrumb: 'Deals > Pizza Builder',
  level3_aggregation: 'Pizza Builder > Lineup',
  deal_id: LINEUP,
  deal_name: LINEUP
});

export const onPizzaBuilderInitialLoadAnalytics = (
  label: string,
  recipe: PizzaOptionsAnalytics,
  lineup = false
): Partial<BaseProductsAnalytics<PizzaProductAnalytics>> => {
  if (lineup) return onLineupPizzaBuilderPageview(recipe);

  const isDeal = !!recipe?.deal_id;
  const dealId = recipe?.deal_id ?? '';
  const dealName = recipe?.deal_name ?? '';
  return {
    ...(isDeal
      ? commonDealBuilderScreenAnalytics('Pizza Builder', dealName, dealId)
      : onPizzaBuilderLoadEventInView(label, recipe?.name ?? '')),
    beacon_id: BeaconIds.BUILDER.INITIAL_CASE,
    event: 'screen_view',
    form_name: 'Pizza Builder',
    product_action: 'Detail',
    product_name_hit: recipe?.name || '',
    products: [
      {
        product_id: recipe?.id ?? '',
        product_name: recipe?.name || '',
        product_price: (recipe.price || 0) / 100,
        product_sauce: recipe?.sauce?.name || '',
        product_cheese: recipe?.cheese?.name || '',
        product_crust: recipe?.crust?.name || '',
        product_finisher: recipe?.finisher?.name || '',
        product_veggies: joinText(
          recipe.veggieToppings?.map(({ name }) => name)
        ),
        product_meats: joinText(recipe.meatToppings?.map(({ name }) => name)),
        product_category: recipe.deal_name ? 'Deals' : 'Pizza',
        product_index: 0,
        ...(isDeal && {
          product_deal_id: new ProductId(dealId).globalId,
          product_deal_name: dealName
        })
      }
    ]
  };
};

const createMenuItem = (
  item: MenuRecipe,
  index: number,
  label: string
): ItemAnalytics => ({
  item_list: label,
  item_index: index,
  item_id: item.fullId ?? item.id,
  item_price: label === 'Pizza' ? (item?.price || 0) / 100 : 0,
  item_name: item.name,
  item_category: label,
  item_position: `${index + 1}`,
  item_stock: item.outOfStock ? 'Out of Stock' : 'In Stock'
});

const getUpsellItemPrice = (item: UpsellProducts): number => {
  const firstSize = item.sizes?.[0] || { price: 0, name: '' };
  const { additionalOptions } = item;
  let price = firstSize?.price ?? 0;
  if (additionalOptions && additionalOptions[firstSize.name]) {
    price += additionalOptions[firstSize.name]?.[0]?.price ?? 0;
  }
  return price / 100;
};

const createUpsellItem = (
  item: UpsellProducts,
  index: number,
  label: string
): ItemAnalytics => ({
  item_list: label,
  item_index: index,
  item_id: item.id,
  item_price: getUpsellItemPrice(item),
  item_name: item.name,
  item_category: 'Upsell',
  item_position: `${index + 1}`
});

const createItemsFromMenuPage = (
  item: DisplayableProductWithSubcategory | DisplayableMenuItem,
  index: number,
  category: string
): ItemAnalytics => ({
  item_list:
    'subcategory' in item ? `${category} > ${item.subcategory}` : category,
  item_index: index,
  item_id: item.id,
  item_name: item.name,
  item_category: category,
  item_position: `${index + 1}`,
  item_stock: `${item.isOutOfStock ? 'Out of Stock' : 'In Stock'}`,
  ...(isPizzaProduct(item)
    ? {
      item_price: (item.price ?? 0) / 100
    }
    : {
      item_price: (item.sizes?.[0]?.price ?? 0) / 100,
      item_size: item.sizes?.[0]?.name ?? '',
      item_modifier: item.additionalOptions?.[item.sizes?.[0].name || 0]?.[0]?.name
    })
});

const createChildDealItem = (
  item: ChildDealStep,
  index: number
): ItemAnalytics => ({
  item_list: 'Deals',
  item_index: index,
  item_id: item.id,
  item_price: (item.price ?? 0) / 100,
  item_name: item.name,
  item_category: 'Deals',
  item_position: `${index + 1}`,
  item_stock: item.outOfStock ? 'Out of Stock' : 'In Stock'
});

export const getProductsAsItems = (
  items: (DisplayableProductWithSubcategory | DisplayableMenuItem)[],
  category: string
): ItemAnalytics[] => items.map((item, index) => createItemsFromMenuPage(item, index, category));

export const getMenuItems = (
  items: MenuRecipe[],
  label: string
): ItemAnalytics[] => items.map((item, index) => createMenuItem(item, index, label));

export const getUpsellItems = (
  items: UpsellProducts[],
  label: string
): ItemAnalytics[] => items.map((item, index) => createUpsellItem(item, index, label));

export const getChildDealItems = (items: ChildDealStep[]): ItemAnalytics[] => items.map((item, index) => createChildDealItem(item, index));

export const getItemsWithoutSizeAndModifiers = (
  items: ItemAnalytics[]
): Omit<ItemAnalytics, 'item_modifier' | 'item_size'>[] => items.map(
  ({ item_modifier, item_size, ...restAnalyticsItems }) => restAnalyticsItems
);

export const onMenuInitialLoadAnalytics = (
  label: string,
  items: ItemAnalytics[],
  dealId?: string,
  dealName?: string
): PageViewWithItemsAnalytics => {
  if (dealId && dealName) {
    return {
      ...commonPizzaMenuOnDealAnalytics(dealName, dealId),
      event: 'screen_view',
      beacon_id: BeaconIds.DEAL.PIZZA_MENU_PAGE_LOAD,
      items: [...items]
    };
  }

  return {
    ...commonMenuPageLoadAnalytics(label),
    beacon_id: BeaconIds.MENU.INITIAL_PAGE_LOAD,
    items: [...items]
  };
};

export const onNationalClickGetStarted = (category?: string): BaseAnalytics => {
  const pageLocation = `National Menu > ${category || 'Pizza'}`;

  return {
    event: 'button_click',
    screen_name: pageLocation,
    event_category: pageLocation,
    event_action: 'Get Started',
    event_label: `${pageLocation} > Get Started`,
    screen_level1: 'National Menu',
    screen_level2: pageLocation,
    screen_level3: pageLocation,
    screen_level4: pageLocation,
    level2_breadcrumb: pageLocation,
    level3_aggregation: pageLocation,
    beacon_id: BeaconIds.MENU.NATIONAL_GET_STARTED
  };
};

export const onPizzaSizeClick = (
  pizzaSize: string | null | undefined,
  pizzaName: string,
  isLineup?: boolean
): Partial<BaseAnalytics & ScreenLevelAnalytics> => {
  if (isLineup) {
    return {
      ...commonPizzaBuilderClickAnalytics(pizzaName, LINEUP, LINEUP, isLineup),
      beacon_id: BeaconIds.BUILDER.SIZE,
      event_category: 'Builder > Deal Builder',
      event_action: `Crust > Size > ${pizzaSize}`,
      event_label: `Builder > Deal Builder > Crust > Size > ${pizzaSize}`
    };
  }

  return {
    ...commonPizzaBuilderClickAnalytics(pizzaName),
    beacon_id: BeaconIds.BUILDER.SIZE,
    event_action: `Size > ${pizzaSize}`,
    event_category: 'Builder > Pizza Builder > Crust',
    event_label: `Builder > Pizza Builder > Crust > Size > ${pizzaSize}`
  };
};

export const onSauceSelect = (
  sauce: string | null | undefined,
  portion: string | undefined,
  pizzaName: string,
  isExpanded: boolean,
  isLineup: boolean
): Partial<BaseAnalytics & ScreenLevelAnalytics> => {
  if (isLineup) {
    return ({
      ...commonPizzaBuilderClickAnalytics(pizzaName, '', '', true),
      beacon_id: BeaconIds.BUILDER.SAUCE,
      event_category: 'Builder > Deal Builder > Pizza Builder > Sauce',
      event_action: `${sauce}, Amount > ${capitalize(portion)}${!isExpanded ? ', Collapse' : ''}`,
      event_label: `Builder > Deal Builder > Pizza Builder > Sauce > ${sauce}, Amount > ${capitalize(
        portion
      )}${!isExpanded ? ', Collapse' : ''}`
    });
  }

  return {
    ...commonPizzaBuilderClickAnalytics(pizzaName),
    beacon_id: BeaconIds.BUILDER.SAUCE,
    event_action: `${sauce}, Amount > ${capitalize(portion)}${!isExpanded ? ', Collapse' : ''
    }`,
    event_category: 'Builder > Pizza Builder > Sauce',
    event_label: `Builder > Pizza Builder > Sauce > ${sauce}, Amount > ${capitalize(
      portion
    )}${!isExpanded ? ', Collapse' : ''}`
  };
};

export const onPizzaCheeseOptionsClick = (
  pizzaName: string,
  portion: string,
  isExpanded: boolean,
  isLineup = false
): BaseAnalytics => {
  const collapseAction = `Amount > ${portion}${isExpanded ? '' : ', Collapse'}`;
  return {
    ...commonPizzaBuilderClickAnalytics(pizzaName, undefined, undefined, isLineup),
    beacon_id: BeaconIds.BUILDER.CHEESE,
    event_action: collapseAction,
    event_category: 'Builder > Pizza Builder > Cheese',
    event_label: `Builder > Pizza Builder > Cheese > ${collapseAction}`
  };
};

export const onPizzaCrustTypeClick = (
  pizzaName: string,
  crustType: string | null | undefined,
  isLineup = false
): BaseAnalytics => ({
  ...commonPizzaBuilderClickAnalytics(
    pizzaName,
    undefined,
    undefined,
    isLineup
  ),
  beacon_id: BeaconIds.BUILDER.CRUST_TYPE,
  event_action: `Crust Type > ${crustType}`,
  event_category: 'Builder > Pizza Builder > Crust',
  event_label: `Builder > Pizza Builder > Crust > Crust Type > ${crustType}`
});

export const onPizzaCrustFlavorClick = (
  pizzaName: string,
  crustFlavor: string | null | undefined,
  collapse: boolean,
  isLineup: boolean
): BaseAnalytics => {
  if (isLineup) {
    return ({
      ...commonPizzaBuilderClickAnalytics(pizzaName, '', '', true),
      beacon_id: BeaconIds.BUILDER.CRUST_FLAVOR,
      event_category: 'Builder > Deal Builder',
      event_action: `Crust Flavor > ${crustFlavor}${collapse ? ', Collapse' : ''}`,
      event_label: `Builder > Deal Builder > Crust Flavor > ${crustFlavor}${collapse ? ', Collapse' : ''}`
    });
  }

  return ({
    ...commonPizzaBuilderClickAnalytics(pizzaName),
    event_category: 'Builder > Pizza Builder > Crust Flavor',
    event_action: `Crust Flavor > ${crustFlavor}${collapse ? ', Collapse' : ''}`,
    event_label: `Builder > Pizza Builder > Crust Flavor > ${crustFlavor}${collapse ? ', Collapse' : ''}`,
    beacon_id: BeaconIds.BUILDER.CRUST_FLAVOR
  });
};

export const onPizzaToppingClick = (
  pizzaName: string,
  topping: string | null | undefined,
  action: ToppingsAction,
  isLineup?: boolean
): BaseAnalytics => {
  const beaconId = action === ToppingsSelectionAnalytics.ADD
    ? BeaconIds.BUILDER.TOPPINGS.ADD
    : BeaconIds.BUILDER.TOPPINGS.DEFAULT;

  if (isLineup) {
    return {
      beacon_id: beaconId,
      ...commonPizzaBuilderClickAnalytics(pizzaName, LINEUP, LINEUP, true),
      event_category: 'Builder > Deal Builder',
      event_action: `Toppings > ${topping} > ${action}`,
      event_label: `Builder > Deal Builder > Toppings > ${topping} > ${action}`
    };
  }

  return {
    beacon_id: beaconId,
    ...commonPizzaBuilderClickAnalytics(pizzaName),
    event_category: 'Builder > Pizza Builder > Toppings',
    event_action: `${topping} > ${action}`,
    event_label: `Builder > Pizza Builder > Toppings > ${topping} > ${action}`
  };
};

export const onPizzaToppingSeeAllClick = (
  pizzaName: string,
  topping: string,
  action: string,
  isLineup?: boolean
): BaseAnalytics => {
  if (isLineup) {
    return {
      ...commonPizzaBuilderClickAnalytics(pizzaName, LINEUP, LINEUP, isLineup),
      event_category: 'Builder > Deal Builder',
      event_action: `Toppings > ${topping} > ${action}`,
      event_label: `Builder > Deal Builder > Toppings > ${topping} > ${action}`,
      beacon_id: BeaconIds.BUILDER.TOPPINGS.SEE_ALL
    };
  }

  return {
    ...commonPizzaBuilderClickAnalytics(pizzaName),
    event_category: 'Builder > Pizza Builder > Toppings',
    event_action: `${topping} > ${action}`,
    event_label: `Builder > Pizza Builder > Toppings > ${topping} > ${action}`,
    beacon_id: BeaconIds.BUILDER.TOPPINGS.SEE_ALL
  };
};

export const onCrustContinueClick = (
  pizzaName: string,
  deal_id?: string,
  deal_name?: string,
  isLineup?: boolean
): BaseAnalytics => {
  if (isLineup) {
    return {
      ...commonPizzaBuilderClickAnalytics(pizzaName, LINEUP, LINEUP, true),
      beacon_id: BeaconIds.BUILDER.CRUST.CONTINUE,
      event_category: 'Builder > Deal Builder',
      event_action: 'Crust > Continue',
      event_label: 'Builder > Deal Builder > Crust > Continue'
    };
  }

  return {
    ...commonPizzaBuilderClickAnalytics(pizzaName, deal_name, deal_id),
    beacon_id: BeaconIds.BUILDER.CRUST.CONTINUE,
    event_category: 'Builder > Pizza Builder > Crust',
    event_action: 'Continue',
    event_label: 'Builder > Pizza Builder > Crust > Continue'
  };
};

const transformPizzaAnalytics = (item: BuiltPizza): PizzaAnalytics => ({
  id: item.id,
  name: item.name,
  quantity: item.quantity,
  price: (item.price || 0) / 100,
  sauce: item.sauce?.name,
  cheese: item.cheese?.name,
  crust: item.crust?.name,
  size: item.size?.name,
  finisher: item.finisher?.name,
  veggies: joinText(item.veggieToppings?.map(({ name }) => name)),
  meats: joinText(item.meatToppings?.map(({ name }) => name))
});

function itemIsBuiltPizza(item: BuiltPizza | Product): item is BuiltPizza {
  return (item as BuiltPizza).type === PIZZA;
}

export const convertSelectedItemIntoProductAnalytics = (
  recipe: SelectedRecipe,
  steps: DealStep[],
  dealId: string,
  dealName: string
): PizzaProductAnalytics | MenuProductAnalytics => {
  const { item } = recipe;
  const step = steps.find((dealStep) => dealStep.id === recipe.stepId);
  const productIndex = step ? steps.indexOf(step) : 0;

  if (itemIsBuiltPizza(item)) {
    const pizzaAnalytics = transformPizzaAnalytics(item);
    return {
      ...createPizzaAnalytics(pizzaAnalytics),
      product_id: item.id ?? '',
      product_category: 'Deals',
      product_added_location: 'Builder > Deal Builder',
      product_deal_id: `${dealId}`,
      product_deal_name: `${dealName}`,
      product_index: productIndex
    };
  }
  return {
    product_price: null,
    product_name: item.name,
    product_index: productIndex,
    product_category: 'Deals',
    product_position: `${productIndex + 1}`,
    product_added_location: 'Builder > Deal Builder',
    product_deal_id: `${dealId}`,
    product_deal_name: `${dealName}`,
    product_id: item.id ?? '',
    product_quantity: item.quantity,
    product_action_list: 'Deals',
    product_size:
      item.selectedModifiers.find((modifier) => modifier.type === SIZE)?.name
      ?? null,
    product_modifier:
      item.selectedModifiers.find((modifier) => modifier.type === MODIFIER)
        ?.name ?? null
  };
};

const composePizza = (item: BuiltPizza | Deal): BuiltPizza | Product => {
  if (isDealType(item)) {
    return item.userSelections.present.recipes[0].item;
  }
  return item;
};

const onAddLineupPizzaToOrderSuccess = (
  recipe: PizzaAnalytics | null
): BaseProductsAnalytics<PizzaProductAnalytics> => ({
  beacon_id: BeaconIds.BUILDER.SELECTOR,
  event: 'cart_add',
  screen_name: 'Builder > Deal Builder > Pizza Builder > Lineup',
  event_category: 'Builder > Deal Builder',
  event_action: 'Add to Order',
  event_label: 'Builder > Deal Builder > Add to Order',
  screen_level1: 'Builder',
  screen_level2: 'Builder > Deal Builder',
  screen_level3: 'Builder > Deal Builder > Pizza Builder',
  screen_level4: 'Builder > Deal Builder > Pizza Builder > Lineup',
  level2_breadcrumb: 'Deals > Pizza Builder',
  level3_aggregation: 'Pizza Builder > Lineup',
  form_name: 'Pizza Builder',
  deal_id: LINEUP,
  deal_name: LINEUP,
  product_action: 'Add',
  product_name_hit: recipe?.name || '',
  products: [
    {
      ...createPizzaAnalytics(recipe),
      product_category: LINEUP,
      product_added_location: 'Builder > Pizza Builder > Lineup',
      product_index: 0,
      product_deal_id: LINEUP,
      product_deal_name: LINEUP
    }
  ]
});

export const onAddToOrderButtonSuccess = (
  items: BuiltPizza | Deal,
  isLineup = false
):
| BaseProductsAnalytics<PizzaProductAnalytics>
| Partial<BaseProductsAnalytics<PizzaProductAnalytics>> => {
  const item = composePizza(items);
  let recipe: PizzaAnalytics | null = null;
  if (item.kind === AddableCartItemKinds.BUILT_PIZZA) {
    recipe = transformPizzaAnalytics(item);
  }
  const isDeal = isDealType(items);
  const { id: dealId, name: dealName } = isDeal
    ? items.data
    : { id: '', name: '' };
  const dealGlobalId = new ProductId(dealId).globalId;

  const levels = `Builder ${isDeal ? '> Deal Builder ' : ''}> Pizza Builder`;

  if (isLineup) return onAddLineupPizzaToOrderSuccess(recipe);

  return {
    ...(isDeal
      ? {
        ...commonDealPizzaBuilderClickAnalytics(
          recipe?.name || '',
          dealName,
          dealId
        )
      }
      : {
        ...commonPizzaBuilderClickAnalytics(recipe?.name || ''),
        level2_breadcrumb: 'Deals > Pizza Builder',
        level3_aggregation: 'Pizza Builder',
        form_name: 'Pizza Builder'
      }),
    beacon_id: BeaconIds.BUILDER.SELECTOR,
    event: 'cart_add',
    event_category: levels,
    event_action: 'Add to Order',
    event_label: `${levels} > Add to Order`,
    product_action: 'Add',
    ...(isDeal && {
      deal_id: dealGlobalId,
      deal_name: dealName
    }),
    products: [
      {
        ...createPizzaAnalytics(recipe),
        product_index: 0,
        product_category: isDeal ? 'Deals' : 'Pizza',
        product_added_location: levels,
        ...(isDeal && {
          product_deal_id: dealGlobalId,
          product_deal_name: dealName
        })
      }
    ]
  };
};

export const onPizzaBuilderXButtonClick = (
  pizzaName: string,
  deal_id?: string,
  deal_name?: string,
  isLineup?: boolean
): DealBaseAnalytics => {
  if (isLineup) {
    return {
      ...commonPizzaBuilderClickAnalytics(pizzaName, LINEUP, LINEUP, isLineup),
      beacon_id: BeaconIds.BUILDER.X_BUTTON,
      event: 'link_click',
      event_category: 'Builder > Deal Builder',
      event_action: 'Close',
      event_label: 'Builder > Deal Builder > Close'
    };
  }
  return {
    ...commonPizzaBuilderClickAnalytics(pizzaName, deal_name, deal_id),
    beacon_id: BeaconIds.BUILDER.X_BUTTON,
    event_category: 'Builder > Pizza Builder',
    event_action: 'Close',
    event_label: 'Builder > Pizza Builder > Close'
  };
};

export const onPizzaBuilderSectionClick = (
  pizzaName: string,
  deal_id: string,
  deal_name: string,
  action: BuilderSectionAction,
  expand = true,
  isLineup = false
): DealBaseAnalytics => {
  const common = {
    beacon_id: expand
      ? BeaconIds.BUILDER.EXPAND_SECTION
      : BeaconIds.BUILDER.COLLAPSE_SECTION,
    event: 'button_click',
    screen_level1: 'Builder',
    form_name: 'Pizza Builder',
    product_name_hit: pizzaName
  };

  if (isLineup) {
    return {
      ...common,
      event_category: 'Builder > Deal Builder',
      event_action: expand ? action : `${action} > Collapse`,
      event_label: expand
        ? `Builder > Deal Builder > ${action}`
        : `Builder > Deal Builder > ${action} > Collapse`,
      screen_name: 'Builder > Deal Builder > Pizza Builder > Lineup',
      screen_level2: 'Builder > Deal Builder',
      screen_level3: 'Builder > Deal Builder > Pizza Builder',
      screen_level4: 'Builder > Deal Builder > Pizza Builder > Lineup',
      level2_breadcrumb: 'Deals > Pizza Builder',
      level3_aggregation: 'Pizza Builder > Lineup',
      deal_id: 'Lineup',
      deal_name: 'Lineup'
    };
  }

  if (deal_name && deal_id) {
    return {
      ...common,
      event_category: `Builder > Deal Builder > Pizza Builder${!expand ? ` > ${action}` : ''
      }`,
      event_action: expand ? action : 'Collapse',
      event_label: expand
        ? `Builder > Deal Builder > Pizza Builder > ${action}`
        : `Builder > Deal Builder > Pizza Builder${!expand ? ` > ${action} > Collapse` : ''
        }`,
      screen_name: 'Builder > Deal Builder > Pizza Builder',
      screen_level2: 'Builder > Deal Builder',
      screen_level3: 'Builder > Deal Builder > Pizza Builder',
      screen_level4: `Builder > Deal Builder > Pizza Builder > ${deal_name}`,
      level2_breadcrumb: 'Deals > Pizza Builder',
      level3_aggregation: 'Pizza Builder',
      deal_id: new ProductId(deal_id).globalId,
      deal_name
    };
  }

  return {
    ...common,
    screen_name: 'Builder > Pizza Builder',
    screen_level2: 'Builder > Pizza Builder',
    screen_level3: 'Builder > Pizza Builder',
    screen_level4: `Builder > Pizza Builder > ${pizzaName}`,
    level2_breadcrumb: 'Menu > Pizza Builder',
    level3_aggregation: 'Menu > Pizza Builder',
    event_category: `Builder > Pizza Builder${!expand ? ` > ${action}` : ''}`,
    event_action: expand ? `${action}` : 'Collapse',
    event_label: expand
      ? `Builder > Pizza Builder > ${action}`
      : `Builder > Pizza Builder > ${action} > Collapse`
  };
};

export const onSCCFContinueButtonClick = (
  pizzaName: string,
  deal_id?: string,
  deal_name?: string,
  isLineUp = false
): BaseAnalytics => {
  if (isLineUp) return onLineupContinueClick(pizzaName);
  return {
    ...commonPizzaBuilderClickAnalytics(pizzaName, deal_name, deal_id),
    beacon_id: BeaconIds.BUILDER.SCCF.CONTINUE,
    event_category: 'Builder > Pizza Builder > Sauce, Cheese',
    event_action: 'Continue',
    event_label: 'Builder > Pizza Builder > Sauce, Cheese > Continue'
  };
};

const onLineupSCCFEditButtonClick = (
  pizzaName: string,
  section: SCCFSectionAction
): DealBaseAnalytics => ({
  beacon_id: BeaconIds.BUILDER.SCCF.EDIT,
  event: 'button_click',
  product_name_hit: pizzaName,
  screen_name: 'Builder > Deal Builder > Pizza Builder > Lineup',
  event_category: 'Builder > Deal Builder',
  event_action: `Sauce, Cheese > ${section} > Edit`,
  event_label: `Builder > Deal Builder > Sauce, Cheese > ${section} > Edit`,
  screen_level1: 'Builder',
  screen_level2: 'Builder > Deal Builder',
  screen_level3: 'Builder > Deal Builder > Pizza Builder',
  screen_level4: 'Builder > Deal Builder > Pizza Builder > Lineup',
  level2_breadcrumb: 'Deals > Pizza Builder',
  level3_aggregation: 'Pizza Builder > Lineup',
  deal_id: LINEUP,
  deal_name: LINEUP
});

export const onSCCFEditButtonClick = (
  pizzaName: string,
  action: SCCFSectionAction,
  dealName?: string,
  dealId?: string,
  isLineup = false
): BaseAnalytics => {
  if (isLineup) return onLineupSCCFEditButtonClick(pizzaName, action);
  return {
    ...commonPizzaBuilderClickAnalytics(pizzaName, dealName, dealId, isLineup),
    beacon_id: BeaconIds.BUILDER.SCCF.EDIT,
    event_category: `Builder > ${isLineup ? 'Deal Builder' : 'Pizza Builder > Sauce, Cheese'
    }`,
    event_action: `${action} > Edit`,
    event_label: `Builder > ${isLineup ? 'Deal Builder' : 'Pizza Builder'
    } > Sauce, Cheese > ${action} > Edit`
  };
};

export const onBackBtnClick = (
  pizzaName: string,
  category: string,
  deal_id?: string,
  deal_name?: string,
  isLineup = false
): BaseAnalytics | DealBaseAnalytics => {
  if (isLineup) return onLineupBackArrowClick(pizzaName, category);

  return {
    ...commonPizzaBuilderClickAnalytics(pizzaName, deal_name, deal_id),
    beacon_id: BeaconIds.BUILDER.SCCF.BACK,
    event_category: `Builder > Pizza Builder > ${category}`,
    event_action: 'Back',
    event_label: `Builder > Pizza Builder > ${category} > Back`
  };
};

export const onToppingsContinueButtonClick = (
  pizzaName: string,
  deal_id?: string,
  deal_name?: string,
  isLineup?: boolean
): BaseAnalytics => {
  if (isLineup) {
    return {
      ...commonPizzaBuilderClickAnalytics(pizzaName, LINEUP, LINEUP, true),
      beacon_id: BeaconIds.BUILDER.TOPPINGS.CONTINUE,
      event_category: 'Builder > Deal Builder',
      event_action: 'Toppings > Continue',
      event_label: 'Builder > Deal Builder > Toppings > Continue'
    };
  }

  return {
    ...commonPizzaBuilderClickAnalytics(pizzaName, deal_name, deal_id),
    beacon_id: BeaconIds.BUILDER.TOPPINGS.CONTINUE,
    event_category: 'Builder > Pizza Builder > Toppings',
    event_action: 'Continue',
    event_label: 'Builder > Pizza Builder > Toppings > Continue'
  };
};

export const onMenuCategoryClick = (
  category: string,
  isLocalized: boolean
): BaseAnalytics => ({
  beacon_id: BeaconIds.MENU.CATEGORY_CLICK,
  screen_name: getMenuName(isLocalized),
  event: 'button_click',
  event_category: getMenuName(isLocalized),
  event_action: `${capitalize(category)}`,
  event_label: `${getMenuName(isLocalized)} > ${capitalize(category)}`,
  screen_level1: getMenuName(isLocalized),
  screen_level2: getMenuName(isLocalized),
  screen_level3: getMenuName(isLocalized),
  screen_level4: getMenuName(isLocalized),
  level2_breadcrumb: getMenuName(isLocalized),
  level3_aggregation: getMenuName(isLocalized)
});

export const onMenuCategoryCTAClick = (category: string): BaseAnalytics => ({
  beacon_id: BeaconIds.MENU.CATEGORY_CLICK,
  screen_name: 'Menu',
  event: 'button_click',
  event_category: 'Menu',
  event_action: `${capitalize(category)}`,
  event_label: `Menu > ${capitalize(category)}`,
  screen_level1: 'Menu',
  screen_level2: 'Menu',
  screen_level3: 'Menu',
  screen_level4: 'Menu',
  level2_breadcrumb: 'Menu',
  level3_aggregation: 'Menu'
});

export const onMenuCategoryPageLoad = (
  isLocalized: boolean
): PageViewAnalytics => ({
  beacon_id: BeaconIds.MENU.CATEGORIES_PAGE_VIEW,
  event: 'screen_view',
  screen_name: getMenuName(isLocalized),
  screen_level1: getMenuName(isLocalized),
  screen_level2: getMenuName(isLocalized),
  screen_level3: getMenuName(isLocalized),
  screen_level4: getMenuName(isLocalized),
  level2_breadcrumb: getMenuName(isLocalized),
  level3_aggregation: getMenuName(isLocalized)
});

export const onToastView = (
  category: string,
  item: ItemAnalytics,
  dealId?: string,
  dealName?: string
): Partial<BaseAnalytics> => {
  if (category === UPSELL) {
    return onUpsellToastView(item.item_name);
  }
  if (category === LINEUP) {
    return onLineupToastView(item.item_name);
  }

  const eventCategory = commonLocalizedCategory(category, dealName);
  return {
    beacon_id: BeaconIds.MENU.TOAST.INITIAL_LOAD,
    event: 'screen_view',
    screen_level1: dealId ? 'Builder' : 'Menu',
    screen_level2: `${eventCategory}`,
    screen_level3: `${eventCategory} > Added to Order Toast`,
    screen_level4: `${eventCategory} > Added to Order Toast`,
    screen_name: dealId ? `${eventCategory} > Added to Order Toast` : 'Menu',
    level2_breadcrumb: dealId ? 'Deals > Deal Builder' : `${eventCategory}`,
    product_name_hit: `${item?.item_name}` || dealName || category,
    level3_aggregation: dealId ? 'Added to Order Toast' : `${eventCategory}`,
    ...(dealId && {
      deal_id: dealId ? new ProductId(dealId).globalId : '',
      deal_name: dealName
    })
  };
};

export const onDealToastCheckoutClick = ({
  deal_id,
  deal_name
}: {
  deal_id?: string;
  deal_name?: string;
}): PageViewAnalytics => ({
  beacon_id: BeaconIds.DEAL.TOAST.CHECKOUT_CLICK,
  event: 'button_click',
  event_action: 'Checkout',
  event_label: 'Builder > Deal Builder > Added to Order Toast > Checkout',
  screen_name: 'Builder > Deal Builder > Added to Order Toast',
  event_category: 'Builder > Deal Builder',
  screen_level1: 'Builder',
  screen_level2: 'Builder > Deal Builder',
  screen_level3: 'Builder > Deal Builder > Added to Order Toast',
  screen_level4: 'Builder > Deal Builder > Added to Order Toast',
  level2_breadcrumb: 'Deals > Deal Builder',
  level3_aggregation: 'Added to Order Toast',
  deal_id: deal_id ? new ProductId(deal_id).globalId : '',
  deal_name: deal_name ?? ''
});

export const onToastCheckoutClick = (
  category: string,
  hasDeal: boolean,
  deal: { deal_id?: string; deal_name?: string } = {}
): PageViewAnalytics => {
  if (category === LINEUP) {
    return onLineupToastCheckoutClick();
  }

  if (category === UPSELL) {
    return onUpsellToastCheckoutClick();
  }

  if (hasDeal && category !== LINEUP) {
    return onDealToastCheckoutClick(deal);
  }

  const eventCategory = commonLocalizedCategory(category);

  return {
    ...commonMenuBaseAnalytics(category, true),
    beacon_id: BeaconIds.MENU.TOAST.CHECKOUT_CLICK,
    event: 'button_click',
    event_action: 'Checkout',
    screen_name: `${eventCategory} > Added to Order Toast`,
    event_category: `${eventCategory} > Added to Order Toast`,
    screen_level3: `${eventCategory} > Added to Order Toast`,
    screen_level4: `${eventCategory} > Added to Order Toast`,
    event_label: `${eventCategory} > Added to Order Toast > Checkout`
  };
};

export const onMenuDropdownCategoryClick = (
  category: string
): NoScreenLevelAnalytics => ({
  beacon_id: BeaconIds.GLOBAL.MENU_DROPDOWN_CATEGORY_CLICK,
  event: 'global_link',
  event_category: 'Global Header',
  event_action: category,
  event_label: `Global Header > ${category}`
});

export const onHeaderDealsClick = (): NoScreenLevelAnalytics => ({
  beacon_id: BeaconIds.GLOBAL.DEALS_CLICK,
  event: 'global_link',
  event_category: 'Global Header',
  event_action: 'Deals',
  event_label: 'Global Header > Deals'
});

export const onSignInSignOutClick = (
  action: string
): NoScreenLevelAnalytics => ({
  beacon_id: BeaconIds.GLOBAL.SIGN_IN_SIGN_OUT_CLICK,
  event: 'global_link',
  event_category: 'Global Header',
  event_action: action,
  event_label: `Global Header > ${action}`
});

export const onHeaderChangeLocationClick = (): NoScreenLevelAnalytics => ({
  beacon_id: BeaconIds.GLOBAL.CHANGE_LOCATION,
  event: 'global_link',
  event_category: 'Global Header',
  event_action: 'Change Location',
  event_label: 'Global Header > Change Location'
});

export const onHeaderCartClick = (): NoScreenLevelAnalytics => ({
  beacon_id: BeaconIds.GLOBAL.CART_CLICK,
  event: 'global_link',
  event_category: 'Global Header',
  event_action: 'Cart',
  event_label: 'Global Header > Cart'
});

export const onRailStartOrderClick = (): BaseAnalytics => ({
  beacon_id: BeaconIds.CHECKOUT.CART.START_YOUR_ORDER,
  event: 'button_click',
  event_category: 'Checkout > Cart',
  event_action: 'Start Your Order',
  event_label: 'Checkout > Cart > Start Your Order',
  screen_name: 'Checkout > Cart',
  screen_level1: 'Checkout',
  screen_level2: 'Checkout > Cart',
  screen_level3: 'Checkout > Cart',
  screen_level4: 'Checkout > Cart',
  level2_breadcrumb: 'Checkout > Cart',
  level3_aggregation: 'Checkout > Cart'
});

export const onSignInRailSuccess = (): BaseAnalytics => ({
  beacon_id: BeaconIds.SIGN_IN_RAIL.SIGN_IN.SUCCESS,
  event: 'button_click',
  screen_name: 'Sign In Form',
  event_category: 'Sign In Form',
  event_action: 'Sign In Success',
  event_label: 'Sign In Form > Sign In Success',
  screen_level1: 'Sign In Form',
  screen_level2: 'Sign In Form',
  screen_level3: 'Sign In Form',
  screen_level4: 'Sign In Form',
  level2_breadcrumb: 'Sign In Form',
  level3_aggregation: 'Sign In Form'
});

export const onAccountRewardsClickRedeem = (): BaseAnalytics => ({
  beacon_id: BeaconIds.ACCOUNT_MANAGEMENT.HUT_REWARDS.CLICK_REDEEM,
  event: 'link_click',
  screen_name: 'My Account > Hut Rewards',
  event_category: 'My Account > Hut Rewards',
  event_action: 'Redeem',
  event_label: 'My Account > Hut Rewards > Redeem',
  screen_level1: 'My Account',
  screen_level2: 'My Account > Hut Rewards',
  screen_level3: 'My Account > Hut Rewards',
  screen_level4: 'My Account > Hut Rewards',
  level2_breadcrumb: 'My Account > Hut Rewards',
  level3_aggregation: 'My Account > Hut Rewards'
});

export const onAccountRewardsPageView = (): PageViewAnalytics => ({
  beacon_id: BeaconIds.ACCOUNT_MANAGEMENT.HUT_REWARDS.ACCOUNT_JOIN_PAGE_VIEW,
  event: 'screen_view',
  screen_name: 'My Account > Join',
  screen_level1: 'My Account',
  screen_level2: 'My Account > Hut Rewards',
  screen_level3: 'My Account > Hut Rewards',
  screen_level4: 'My Account > Hut Rewards',
  level2_breadcrumb: 'My Account > Hut Rewards',
  level3_aggregation: 'My Account > Hut Rewards'
});

export const onAccountRewardsClickSignIn = (): BaseAnalytics => ({
  beacon_id: BeaconIds.ACCOUNT_MANAGEMENT.HUT_REWARDS.SIGN_IN_CLICK,
  event: 'button_click',
  screen_name: 'My Account > Join',
  event_category: 'My Account > Join',
  event_action: 'Sign In',
  event_label: 'My Account > Join > Sign In',
  screen_level1: 'My Account',
  screen_level2: 'My Account > Hut Rewards',
  screen_level3: 'My Account > Hut Rewards',
  screen_level4: 'My Account > Hut Rewards',
  level2_breadcrumb: 'My Account > Hut Rewards',
  level3_aggregation: 'My Account > Hut Rewards'
});

export const onAccountRewardsClickJoinNow = (): BaseAnalytics => ({
  beacon_id: BeaconIds.ACCOUNT_MANAGEMENT.HUT_REWARDS.JOIN_NOW_CLICK,
  event: 'button_click',
  screen_name: 'My Account > Join',
  event_category: 'My Account > Join',
  event_action: 'Join Now',
  event_label: 'My Account > Join > Join Now',
  screen_level1: 'My Account',
  screen_level2: 'My Account > Hut Rewards',
  screen_level3: 'My Account > Hut Rewards',
  screen_level4: 'My Account > Hut Rewards',
  level2_breadcrumb: 'My Account > Hut Rewards',
  level3_aggregation: 'My Account > Hut Rewards'
});

export const onReorderError = (
  message: string
): BaseAnalytics | Partial<BaseWarningCart> => ({
  beacon_id: BeaconIds.ACCOUNT_MANAGEMENT.ORDERS.ERROR, // 24.09.02
  event: 'modal_messages',
  screen_name: 'My Account > Orders',
  event_category: 'Site Pop Up Messages',
  event_action: 'Reorder Error',
  event_label: 'My Account > Orders > Reorder Error',
  screen_level1: 'My Account',
  screen_level2: 'My Account > Orders',
  screen_level3: 'My Account > Orders',
  screen_level4: 'My Account > Orders',
  level2_breadcrumb: 'My Account > Orders',
  level3_aggregation: 'My Account > Orders',
  modal_message: message
});

export const onDeleteFavoriteClick = (): BaseAnalytics => ({
  beacon_id: BeaconIds.ACCOUNT_MANAGEMENT.ORDERS.FAVORITES.DELETE,
  event: 'button_click',
  screen_name: 'My Account > Orders',
  event_category: 'My Account > Orders',
  event_action: 'Delete',
  event_label: 'My Account > Orders > Delete',
  screen_level1: 'My Account',
  screen_level2: 'My Account > Orders',
  screen_level3: 'My Account > Orders',
  screen_level4: 'My Account > Orders',
  level2_breadcrumb: 'My Account > Orders',
  level3_aggregation: 'My Account > Orders'
});

export const onDeleteFavoriteConfirm = (): BaseAnalytics => ({
  beacon_id: BeaconIds.ACCOUNT_MANAGEMENT.ORDERS.FAVORITES.DELETE_CONFIRM,
  event: 'button_click',
  screen_name: 'My Account > Orders',
  event_category: 'My Account > Orders',
  event_action: 'Remove Favorite > Yes, Remove',
  event_label: 'My Account > Orders > Remove Favorite > Yes, Remove',
  screen_level1: 'My Account',
  screen_level2: 'My Account > Orders',
  screen_level3: 'My Account > Orders',
  screen_level4: 'My Account > Orders',
  level2_breadcrumb: 'My Account > Orders',
  level3_aggregation: 'My Account > Orders'
});

export const onDeleteFavoriteCancel = (): BaseAnalytics => ({
  beacon_id: BeaconIds.ACCOUNT_MANAGEMENT.ORDERS.FAVORITES.DELETE_CANCEL,
  event: 'button_click',
  screen_name: 'My Account > Orders',
  event_category: 'My Account > Orders',
  event_action: 'Remove Favorite > Cancel',
  event_label: 'My Account > Orders > Remove Favorite > Yes, Remove',
  screen_level1: 'My Account',
  screen_level2: 'My Account > Orders',
  screen_level3: 'My Account > Orders',
  screen_level4: 'My Account > Orders',
  level2_breadcrumb: 'My Account > Orders',
  level3_aggregation: 'My Account > Orders'
});

export const onRenameFavSaveClick = (): BaseAnalytics => ({
  beacon_id: BeaconIds.ACCOUNT_MANAGEMENT.ORDERS.FAVORITES.RENAME_SAVE,
  event: 'button_click',
  screen_name: 'My Account > Orders',
  event_category: 'My Account > Orders',
  event_action: 'Rename Favorite > Save',
  event_label: 'My Account > Orders > Rename Favorite > Save',
  screen_level1: 'My Account',
  screen_level2: 'My Account > Orders',
  screen_level3: 'My Account > Orders',
  screen_level4: 'My Account > Orders',
  level2_breadcrumb: 'My Account > Orders',
  level3_aggregation: 'My Account > Orders'
});

export const onRenameFavCancelClick = (): BaseAnalytics => ({
  beacon_id: BeaconIds.ACCOUNT_MANAGEMENT.ORDERS.FAVORITES.RENAME_CANCEL,
  event: 'button_click',
  screen_name: 'My Account > Orders',
  event_category: 'My Account > Orders',
  event_action: 'Rename Favorite > Cancel',
  event_label: 'My Account > Orders > Rename Favorite > Cancel',
  screen_level1: 'My Account',
  screen_level2: 'My Account > Orders',
  screen_level3: 'My Account > Orders',
  screen_level4: 'My Account > Orders',
  level2_breadcrumb: 'My Account > Orders',
  level3_aggregation: 'My Account > Orders'
});

export const onViewMoreOrdersClick = (): BaseAnalytics => ({
  beacon_id: BeaconIds.ACCOUNT_MANAGEMENT.ORDERS.ORDER_HISTORY.VIEWMORE,
  event: 'link_click',
  screen_name: 'My Account > Orders',
  event_category: 'My Account > Orders',
  event_action: 'View More Orders',
  event_label: 'My Account > Orders > View More Orders',
  screen_level1: 'My Account',
  screen_level2: 'My Account > Orders',
  screen_level3: 'My Account > Orders',
  screen_level4: 'My Account > Orders',
  level2_breadcrumb: 'My Account > Orders',
  level3_aggregation: 'My Account > Orders'
});

export const onCreateAccountForgotPassClick = (
  eventAction: 'Create an Account' | 'Forgot Password'
): BaseAnalytics => ({
  beacon_id: BeaconIds.SIGN_IN_RAIL.SIGN_IN.CREATE_ACCOUNT_FORGOT_PASS_CLICK,
  event: 'link_click',
  screen_name: 'Sign In Form',
  event_category: 'Sign In Form',
  event_action: eventAction,
  event_label: `Sign In Form > ${eventAction}`,
  screen_level1: 'Sign In Form',
  screen_level2: 'Sign In Form',
  screen_level3: 'Sign In Form',
  screen_level4: 'Sign In Form',
  level2_breadcrumb: 'Sign In Form',
  level3_aggregation: 'Sign In Form'
});

export const onCreateAccountPageView = (): PageViewAnalytics => ({
  beacon_id: BeaconIds.ACCOUNT_MANAGEMENT.CREATE_ACCOUNT.PAGE_VIEW,
  event: 'screen_view',
  screen_name: 'Registration Form',
  screen_level1: 'Registration Form',
  screen_level2: 'Registration Form',
  screen_level3: 'Registration Form',
  screen_level4: 'Registration Form',
  level2_breadcrumb: 'Registration Form',
  level3_aggregation: 'Registration Form'
});

export const onTextMeDealsClick = (): BaseAnalytics => ({
  beacon_id: BeaconIds.ACCOUNT_MANAGEMENT.CREATE_ACCOUNT.TEXT_ME_DEALS_CLICK,
  event: 'link_click',
  screen_name: 'Registration Form',
  event_category: 'Registration Form',
  event_action: 'Text-me-deals',
  event_label: 'Registration Form > Text-me-deals',
  screen_level1: 'Registration Form',
  screen_level2: 'Registration Form',
  screen_level3: 'Registration Form',
  screen_level4: 'Registration Form',
  level2_breadcrumb: 'Registration Form',
  level3_aggregation: 'Registration Form'
});

export const onCreateAccountRegistrationSuccess = (): BaseAnalytics => ({
  beacon_id:
    BeaconIds.ACCOUNT_MANAGEMENT.CREATE_ACCOUNT.REGISTRATION_FORM_SUCCESS,
  event: 'button_click',
  screen_name: 'Registration Form',
  event_category: 'Registration Form',
  event_action: 'Registration Success',
  event_label: 'Registration Form > Registration Success',
  screen_level1: 'Registration Form',
  screen_level2: 'Registration Form',
  screen_level3: 'Registration Form',
  screen_level4: 'Registration Form',
  level2_breadcrumb: 'Registration Form',
  level3_aggregation: 'Registration Form'
});

export const onCreateAccountCancelClick = (): BaseAnalytics => ({
  beacon_id: BeaconIds.ACCOUNT_MANAGEMENT.CREATE_ACCOUNT.CANCEL_CLICK,
  event: 'button_click',
  screen_name: 'Registration Form',
  event_category: 'Registration Form',
  event_action: 'Cancel Create Account',
  event_label: 'Registration Form > Cancel Create Account',
  screen_level1: 'Registration Form',
  screen_level2: 'Registration Form',
  screen_level3: 'Registration Form',
  screen_level4: 'Registration Form',
  level2_breadcrumb: 'Registration Form',
  level3_aggregation: 'Registration Form'
});

export const onCreateAccountClick = (): BaseAnalytics => ({
  beacon_id: BeaconIds.ACCOUNT_MANAGEMENT.CREATE_ACCOUNT.CREATE_ACCOUNT_CLICK,
  event: 'button_click',
  screen_name: 'Registration Form',
  event_category: 'Registration Form',
  event_action: 'Create Account',
  event_label: 'Registration Form > Create Account',
  screen_level1: 'Registration Form',
  screen_level2: 'Registration Form',
  screen_level3: 'Registration Form',
  screen_level4: 'Registration Form',
  level2_breadcrumb: 'Registration Form',
  level3_aggregation: 'Registration Form'
});

export const resetPasswordEmailSubmittedError = (
  errorMessage: string
): ErrorAnalytics => ({
  beacon_id: BeaconIds.SIGN_IN_RAIL.RESET_PASSWORD.EMAIL_SUBMITTED_ERROR,
  event: 'error_message',
  screen_name: 'Sign In Form',
  event_category: 'Sign In Form > Reset Password Form',
  event_action: 'Form Error',
  event_label: 'Sign In Form > Reset Password Form > Form Error',
  screen_level1: 'Sign In Form',
  screen_level2: 'Sign In Form > Reset Password Form',
  screen_level3: 'Sign In Form > Reset Password Form',
  screen_level4: 'Sign In Form > Reset Password Form',
  level2_breadcrumb: 'Sign In Form > Reset Password Form',
  level3_aggregation: 'Sign In Form > Reset Password Form',
  error_message: errorMessage
});

export const resetPasswordEmailSubmittedSuccess = (): BaseAnalytics => ({
  beacon_id: BeaconIds.SIGN_IN_RAIL.RESET_PASSWORD.EMAIL_SUBMITTED_SUCCESS,
  event: 'button_click',
  screen_name: 'Sign In Form > Reset Password Form',
  event_category: 'Sign In Form > Reset Password Form',
  event_action: 'Form Success',
  event_label: 'Sign In Form > Reset Password Form > Form Success',
  screen_level1: 'Sign In Form',
  screen_level2: 'Sign In Form > Reset Password Form',
  screen_level3: 'Sign In Form > Reset Password Form',
  screen_level4: 'Sign In Form > Reset Password Form',
  level2_breadcrumb: 'Sign In Form > Reset Password Form',
  level3_aggregation: 'Sign In Form > Reset Password Form'
});
export const onHutRewardsOptOut = (): BaseAnalytics => ({
  beacon_id: BeaconIds.ACCOUNT_MANAGEMENT.HUT_REWARDS.OPT_OUT,
  event: 'link_click',
  screen_name: 'My Account > Hut Rewards',
  event_category: 'My Account > Hut Rewards',
  event_action: 'Opt out of Hut Rewards',
  event_label: 'My Account > Hut Rewards > Opt out of Hut Rewards',
  screen_level1: 'My Account',
  screen_level2: 'My Account > Hut Rewards',
  screen_level3: 'My Account > Hut Rewards',
  screen_level4: 'My Account > Hut Rewards',
  level2_breadcrumb: 'My Account > Hut Rewards',
  level3_aggregation: 'My Account > Hut Rewards'
});

export const onToggleEmailPromotions = (): BaseAnalytics => ({
  beacon_id:
    BeaconIds.ACCOUNT_MANAGEMENT.PROFILE.CLICK_TOGGLE_SEND_EMAIL_PROMOTIONS,
  event: 'button_click',
  screen_name: 'My Account > Profile',
  event_category: 'My Account > Profile',
  event_action: 'Email Updates and Offers',
  event_label: 'My Account > Profile > Email Updates and Offers',
  screen_level1: 'My Account',
  screen_level2: 'My Account > Profile',
  screen_level3: 'My Account > Profile',
  screen_level4: 'My Account > Profile',
  level2_breadcrumb: 'My Account > Profile',
  level3_aggregation: 'My Account > Profile'
});

export const onFooterLinkClick = (screenName: string, linkDisplayText: string): BaseAnalytics => ({
  event: 'global_link',
  screen_name: screenName,
  event_category: 'Global Footer',
  event_action: linkDisplayText,
  event_label: `Global Footer > ${linkDisplayText}`,
  screen_level1: screenName,
  screen_level2: screenName,
  screen_level3: screenName,
  screen_level4: screenName,
  level2_breadcrumb: screenName,
  level3_aggregation: screenName,
  beacon_id: BeaconIds.GLOBAL.FOOTER_LINKS
});

export const clickAddNewDebitCreditCard = (): BaseAnalytics => ({
  beacon_id: BeaconIds.ACCOUNT_MANAGEMENT.PROFILE.CLICK_ADD_NEW_DEBIT_CREDIT_CARD,
  event: 'link_click',
  screen_name: 'My Account > Profile',
  event_category: 'My Account > Profile',
  event_action: '[Add Credit Card, Add Gift Card]',
  event_label: 'My Account > Profile > [Add Credit Card, Add Gift Card]',
  screen_level1: 'My Account',
  screen_level2: 'My Account > Profile',
  screen_level3: 'My Account > Profile',
  screen_level4: 'My Account > Profile',
  level2_breadcrumb: 'My Account > Profile',
  level3_aggregation: 'My Account > Profile'
});

export const clickConfirmOnProfileModalDeleteCard = (isGiftCard: boolean): BaseAnalytics => (isGiftCard
  ? ({
    beacon_id: BeaconIds.ACCOUNT_MANAGEMENT.PROFILE.CLICK_CONFIRM_ON_MODAL_DELETE_GIFT_CARD,
    event: 'button_click',
    screen_name: 'My Account > Profile',
    event_category: 'My Account > Profile',
    event_action: 'Delete Gift Card > Yes, Delete Card',
    event_label: 'My Account > Profile > Delete Gift Card > Yes, Delete Address',
    screen_level1: 'My Account',
    screen_level2: 'My Account > Profile',
    screen_level3: 'My Account > Profile',
    screen_level4: 'My Account > Profile',
    level2_breadcrumb: 'My Account > Profile',
    level3_aggregation: 'My Account > Profile'
  })
  : ({
    beacon_id: BeaconIds.ACCOUNT_MANAGEMENT.PROFILE.CLICK_CONFIRM_ON_MODAL_DELETE_DEBIT_CREDIT_CARD,
    event: 'button_click',
    screen_name: 'My Account > Profile',
    event_category: 'My Account > Profile',
    event_action: 'Delete Credit Card > Yes, Delete Card',
    event_label: 'My Account > Profile > Delete Credit Card > Yes, Delete Address',
    screen_level1: 'My Account',
    screen_level2: 'My Account > Profile',
    screen_level3: 'My Account > Profile',
    screen_level4: 'My Account > Profile',
    level2_breadcrumb: 'My Account > Profile',
    level3_aggregation: 'My Account > Profile'
  }));

export const clickCancelOnProfileModalDeleteCard = (isGiftCard: boolean): BaseAnalytics => (isGiftCard
  ? ({
    beacon_id: BeaconIds.ACCOUNT_MANAGEMENT.PROFILE.CLICK_CANCEL_ON_MODAL_DELETE_GIFT_CARD,
    event: 'button_click',
    screen_name: 'My Account > Profile',
    event_category: 'My Account > Profile',
    event_action: 'Delete Gift Card > Card',
    event_label: 'My Account > Profile > Delete Gift Card > Cancel',
    screen_level1: 'My Account',
    screen_level2: 'My Account > Profile',
    screen_level3: 'My Account > Profile',
    screen_level4: 'My Account > Profile',
    level2_breadcrumb: 'My Account > Profile',
    level3_aggregation: 'My Account > Profile'
  })
  : ({
    beacon_id: BeaconIds.ACCOUNT_MANAGEMENT.PROFILE.CLICK_CANCEL_ON_MODAL_DELETE_DEBIT_CREDIT_CARD,
    event: 'button_click',
    screen_name: 'My Account > Profile',
    event_category: 'My Account > Profile',
    event_action: 'Delete Credit Card > Card',
    event_label: 'My Account > Profile > Delete Credit Card > Cancel',
    screen_level1: 'My Account',
    screen_level2: 'My Account > Profile',
    screen_level3: 'My Account > Profile',
    screen_level4: 'My Account > Profile',
    level2_breadcrumb: 'My Account > Profile',
    level3_aggregation: 'My Account > Profile'
  }));

export const signInFormView = (): PageViewAnalytics => ({
  event: 'screen_view',
  beacon_id: BeaconIds.SIGN_IN_RAIL.SIGN_IN.PAGE_VIEW,
  form_name: 'Sign In',
  screen_name: 'Sign In Form',
  screen_level1: 'Sign In Form',
  screen_level2: 'Sign In Form',
  screen_level3: 'Sign In Form',
  screen_level4: 'Sign In Form',
  level2_breadcrumb: 'Sign In Form',
  level3_aggregation: 'Sign In Form'
});

export const onSuccessfulDtTrackerLoad = (): DragonTailIframeAnalytics => ({
  beacon_id: '53.17.02',
  event: 'screen_view',
  stage_name: 'Received',
  stage_range: '15-30'
});

export const onErrorLoadingDtTracker = (): DragonTailIframeAnalytics => ({
  beacon_id: '53.18.02',
  event: 'DragonTail load failure'
});
