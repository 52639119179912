const stub = {
    "success": true,
    "refresh_required": true,
    "removedToppings": "",
    "user_state": {
        "storeLatitude": "42.019827",
        "storeLongitude": "-87.684864",
        "action": "",
        "deliveryAddress": "",
        "currentSavedLocationIndex": null,
        "StoreNumber": "032451",
        "occasion": "C",
        "zipcode": "60202",
        "wingstreetleadmkt": 0,
        "user_state_abbrev": "U",
        "menu": [
            "pizza",
            "pzones",
            "wings",
            "sides",
            "pasta",
            "desserts",
            "drinks"
        ]
    },
    "user_location": "",
    "cc_accepted": [
        "VI",
        "MC",
        "DI",
        "AM"
    ],
    "lead_market": true,
    "store_info": {
        "032451": {
            "address": "951 Howard St.",
            "city": "Evanston",
            "state": "IL",
            "state_name": "Illinois",
            "zip": 60202,
            "phone": "(847) 869-7500",
            "status": "DC",
            "carryout_open": "10:00AM",
            "carryout_close": "12:00AM",
            "carryout_future_order": true,
            "delivery_open": "10:00AM",
            "delivery_close": "12:00AM",
            "delivery_future_order": true,
            "open_for_delivery": true,
            "open_for_carryout": true,
            "closure_reason": "",
            "onlineStatus": "online",
            "firstTimeDiscount": false,
            "currentlyOpen": true,
            "deliveryAvailable": true,
            "carryoutAvailable": true,
            "acceptFutureOrders": true,
            "landmark": null,
            "futuredelivery": true,
            "futurecarryout": true,
            "pizzaEvoTestStore": true,
            "offlineStatusMsg": null,
            "loyalty": "Y",
            "tmp_offline_msg": "",
            "store_lastUpdate": "",
            "showDeliveryTime": "Y",
            "promiseTimeDel": 30,
            "promiseTimeLastUpdateDel": {
                "date": "2020-04-06 14:33:00.000000",
                "timezone_type": 3,
                "timezone": "US/Central"
            },
            "showCarryoutTime": "Y",
            "promiseTimeCo": 15,
            "promiseTimeLastUpdateCo": {
                "date": "2020-04-06 14:09:00.000000",
                "timezone_type": 3,
                "timezone": "US/Central"
            },
            "promiseTime": 30
        },
        "gps_enabled": "N",
        "loyalty_enabled": "Y",
        "yum_ord_api": "Y",
        "ord_track_ver": "2",
        "acquisition_store": true,
        "lineup": {
            "configCode": "LE_National_5NUP",
            "creativeCode": "LE_5NUP"
        }
    },
    "vco_data": {
        "vcoGood": false,
        "has_vco_coupon": false,
        "signed_in": false
    },
    "pi_enabled": false
};

export default stub;
