import angularHost from '../../../embedded-web2/src/embedding-framework/angular-adapters/angularHost';

const angularRouter: Router = {
  goToRoute(route: string): void {
    if (route === 'deals') {
      angularHost.goToUiState('index.menu.page', { page: 'deals' });
    } else if (route === 'home') {
      angularHost.goToUiState('index.home');
    } else {
      throw new Error('Unknown angular route');
    }
  }
};

export default angularRouter;
