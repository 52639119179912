import nextJsRouter from './nextJsRouter';

export function getRouter() : Router {
  return process.env.REACT_APP_EMBEDDED === 'true'
    ? lazyLoadAngularRouter()
    : nextJsRouter;
}

function lazyLoadAngularRouter() : Router {
  // lazy-requiring this because the angular host
  // blows up if Nextjs tries to run it server-side
  // (currently depends on the global window object, which is not available server-side)
  /* eslint-disable global-require */
  return require('./angularRouter').default;
}

export default getRouter();
