import {
  GetItemUnitPriceByDisplayablePriceParams,
  GetMaxOrderLimitForOccasionParams
} from '../types';

export const getMaxOrderLimitForOccasion = ({
  occasionList,
  occasionId
}: GetMaxOrderLimitForOccasionParams): number => {
  const maxOrderForOccasion = occasionList?.find(
    (maxOrderLimit) => maxOrderLimit.occasionId === occasionId
  );

  return maxOrderForOccasion?.maxOrder || Infinity;
};

// TODO: This calculation is correct for phdapi but we probably need to check it is the same for Yum
export const getItemUnitPriceByDisplayablePrice = ({
  itemDisplayablePrice,
  itemQuantity
}: GetItemUnitPriceByDisplayablePriceParams): number => parseInt(itemDisplayablePrice, 10) / itemQuantity;

export const formatPrice = (price: number) => `$${(price / 100).toFixed(2)}`;
