import { TokenResponse } from '@openid/appauth';
import standaloneApiClient from '../../api/standaloneApiClient';
import StandaloneEndpoints from '../../api/standaloneApiClient/endpoints';
import { AuthService } from './types';

export interface YumRefreshTokenData {
  refreshToken?: string;
  expiresAt?: string;
}

export type PrepareYumGuestToCustomerBody = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

const authService: AuthService = {
  async getYumAccessTokenExpiration(cookieName?: string): Promise<ClientResult<number>> {
    try {
      const {
        expirationDate
      } = await standaloneApiClient.get(StandaloneEndpoints.GET_ACCESS_TOKEN_EXP_IN, { cookieName: String(cookieName) });

      if (!expirationDate) {
        throw new Error();
      }

      return { result: expirationDate, error: false };
    } catch {
      return { result: 0, error: true };
    }
  },

  async refreshYumAccessToken(): Promise<ClientResult<number | null>> {
    try {
      const { expirationDate } = await standaloneApiClient.post(
        {},
        StandaloneEndpoints.REFRESH_ACCESS_TOKEN
      );

      if (!expirationDate) {
        throw new Error();
      }

      return { result: expirationDate, error: false };
    } catch {
      return { result: null, error: true };
    }
  },

  async refreshYumPaymentToken(): Promise<ClientResult<number | null>> {
    try {
      const { expirationDate } = await standaloneApiClient.post(
        {},
        StandaloneEndpoints.REFRESH_PAYMENT_TOKEN
      );

      if (!expirationDate) {
        throw new Error('No expiration date found.');
      }

      return { result: expirationDate, error: false };
    } catch {
      return { result: null, error: true };
    }
  },

  async createYumGuestToken(): Promise<ClientResult<void>> {
    try {
      await standaloneApiClient.post({}, StandaloneEndpoints.CREATE_YUM_GUEST_TOKEN);

      return { error: false };
    } catch {
      return { error: true };
    }
  },

  async createYumPaymentToken(): Promise<ClientResult<void>> {
    try {
      await standaloneApiClient.post({}, StandaloneEndpoints.CREATE_YUM_PAYMENT_TOKEN);

      return { error: false };
    } catch {
      return { error: true };
    }
  },

  async saveYumAccessToken(data: TokenResponse): Promise<ClientResult<void>> {
    try {
      await standaloneApiClient.post(data, StandaloneEndpoints.SAVE_ACCESS_TOKEN);

      return { error: false };
    } catch {
      return { error: true };
    }
  },

  async removeYumTokens(isPaymentOnly = false): Promise<ClientResult<void>> {
    try {
      await standaloneApiClient.remove(StandaloneEndpoints.REMOVE_YUM_TOKENS, { paymentOnly: String(isPaymentOnly) });

      return { error: false };
    } catch {
      return { error: true };
    }
  },

  async getCookieValue(cookieName: string): Promise<string | null> {
    if (!cookieName) {
      return null;
    }
    try {
      const { cookieValue } = await standaloneApiClient.post({ cookieName }, StandaloneEndpoints.GET_COOKIE_VALUE);

      return cookieValue || null;
    } catch {
      return null;
    }
  },

  async prepareYumGuestToCustomer(body: PrepareYumGuestToCustomerBody): Promise<ClientResult<string>> {
    try {
      const { transitionCode } = await standaloneApiClient.post(body, StandaloneEndpoints.PREPARE_YUM_GUEST_TO_CUSTOMER);

      return { error: false, result: transitionCode };
    } catch {
      return { error: true };
    }
  },

  async logoutYum(): Promise<ClientResult<void>> {
    try {
      await standaloneApiClient.post({}, StandaloneEndpoints.LOGOUT_YUM);

      return { error: false };
    } catch {
      return { error: true };
    }
  }
};

export default authService;
