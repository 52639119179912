import { makeStyles } from '@material-ui/core/styles';
import { largeStartBreakpoint, mobileStartBreakpoint } from '../../../materialUi/theme';

const styles = makeStyles((theme) => ({
  root: {
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.3)',
    marginBottom: '0',
    width: 'auto',
    height: '48px',
    justifyContent: 'center',
    position: 'sticky',
    top: 0,
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      height: '72px'
    }
  },
  titleItem: {
    marginLeft: '15px'
  },
  title: {
    fontFamily: 'PizzaHutFont',
    fontSize: '32px',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    marginTop: '6px',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      fontSize: '48px'
    }
  },
  subtitle: {
    fontFamily: 'open_sans_bold',
    fontSize: '18px',
    textTransform: 'none',
    letterSpacing: 'normal',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      fontSize: '20px',
      lineHeight: '24px'
    }
  },
  closeIconItem: {
    position: 'absolute',
    top: '8px',
    right: '7px',
    zIndex: 1201,
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      top: '18px'
    },
    [theme.breakpoints.between(mobileStartBreakpoint, largeStartBreakpoint)]: {
      top: '8px'
    }
  },
  closeIcon: {
    minWidth: '40px'
  },
  productName: {
    margin: '0',
    fontSize: '18px',
    fontFamily: 'open_sans_bold',
    lineHeight: 1.2,
    color: '#131313',

    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      fontSize: '20px'
    }
  },
  price: {
    marginTop: '6px',
    marginBottom: '12px'
  },
  description: {
    fontFamily: 'open_sans',
    lineHeight: '21px',
    [theme.breakpoints.up(mobileStartBreakpoint)]: {
      marginTop: '20px'
    }
  }
}));

export default styles;
