const nutritionForProduct = (
  nutrition: Partial<Nutrition> | undefined | null,
  includeProductName: boolean
): string => {
  if (!nutrition) return '';
  const { calories, unit, servings } = nutrition;
  if ((calories || calories === 0) && unit && servings) {
    const numberOfServings = parseInt(servings, 10);
    const productName = includeProductName ? `${nutrition.name}, ` : '';
    const caloriesAndServings = `${calories} cal/${unit}, ${servings}`;
    const numberOfUnits = `${numberOfServings > 1 ? `${unit}s` : unit}`;

    return `${productName}${caloriesAndServings} ${numberOfUnits}`;
  }

  return '';
};

export default nutritionForProduct;
