import React from 'react';
import { useSelector } from 'react-redux';
import { localizationSelectors } from '@/localization/localizationSelectors';
import { useCartDeleteItem } from '../../hooks/useDeleteItem';
import { useCartUpdateItem } from '../../hooks/useCartUpdateItem';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import { presentationalCartSelectors } from '@/clientCore/redux/selectors/clientCorePresentational/cart/presentationalCartSelectors';

export const CCCartOperationsBoard = () => {
  // Legacy Selectors
  const storeDetails = useSelector(localizationSelectors.storeDetails);

  // CC Selectors
  const cartId = useSelector(orderSelectors.cartId);
  const cart = useSelector(orderSelectors.cart);
  const isCartLoading = useSelector(presentationalCartSelectors.isCartLoading);

  const { handleDeleteCartItem, isDeletingProduct } = useCartDeleteItem();
  const { handleUpdateCartItemQuantity, isUpdatingProduct } = useCartUpdateItem();

  return (
    <div
      style={{
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        width: '400px',
        minHeight: '150px',
        padding: '20px',
        bottom: 0,
        left: 0,
        zIndex: 2,
        background: '#fff'
      }}
      data-testid="cart-operations-board"
    >
      <span>StoreID: {storeDetails?.storeNumber ?? 'Need To Localize.'}</span>
      <hr />
      <span>CartId: {cartId}</span>
      <span>Cart Loading: {isCartLoading.toString()}</span>
      <span>Cart Updating: {isUpdatingProduct.toString()}</span>
      <span>Cart Deleting: {isDeletingProduct.toString()}</span>
      <hr />
      {cart?.items?.map((item) => (
        <div style={{ padding: 5, border: 'solid 1px #000' }}>
          <div>{item?.name}</div>
          <> Quantity: {item.quantity}</>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <button type="button" onClick={() => handleDeleteCartItem(item)}>
              Delete
            </button>
            <button
              type="button"
              onClick={() => handleUpdateCartItemQuantity(item)}
            >
              Increase Quantity
            </button>
            <button
              type="button"
              onClick={() => handleUpdateCartItemQuantity(item, false)}
            >
              Decrease Quantity
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};
