import { Category } from '@pizza-hut-us-development/client-core';
import formattedPrice from '@/common/formattedPrice';
import checkAvailability from '@/graphql/helpers/checkAvailability';
import { OccasionApi } from '@/localization/constants';
import { DealMenu } from '@/menu/deals/types';
import { MenuRecipe } from '@/menu/pizza/pizzaMenuTypes';
import { PARENT_DEAL } from '@/deals/constants';

export const transformGetDealsData = (
  data: Category,
  occasion: OccasionApi,
  storeTimeZone: string
): DealMenu => ({
  deals: data?.products
    .map((product) => {
      const ccAvailProduct = product.type === PARENT_DEAL
        ? { ...product, available: true, availableInOtherOccasion: true }
        : checkAvailability(product, occasion, storeTimeZone);

      return {
        allergenDisclaimer: ccAvailProduct.allergenDisclaimer,
        availability: ccAvailProduct.availability,
        available: ccAvailProduct.available,
        availableInOtherOccasion: ccAvailProduct.availableInOtherOccasion,
        displayPrice: ccAvailProduct.price
          ? formattedPrice(ccAvailProduct.price)
          : '',
        hidden: ccAvailProduct.hidden,
        id: ccAvailProduct.id,
        imageURL: ccAvailProduct.imageURL,
        legalText: ccAvailProduct.legalText,
        name: ccAvailProduct.name,
        outOfStock: ccAvailProduct.outOfStock,
        price: ccAvailProduct.price,
        priority: ccAvailProduct.displayOrder,
        productDescription: ccAvailProduct.description,
        type: ccAvailProduct.type
      };
    })
    .filter((product) => product.available && !product.hidden) as MenuRecipe[]
});
