/* eslint-disable @typescript-eslint/no-explicit-any */
import gql from 'graphql-tag';
import ProductId from '@/common/ProductId';

const cheeseModifierId = 'stores/032441/items/oZdFnakjfK35J5Jx2RB0Nw/groups/_SUOfD-UKsrRJmOB4fc13w/modifiers/aiFBUX90doiqxK4t_eZESA';
const originalPanCrustId = 'laU8PjYArbfYmABxjujGmQ';

const clientSideTypeDefs = gql`
  extend type Item {
    totalCalories: String!
  }
`;

const clientSideResolvers = (enabled:boolean): any => ({
  Item: {
    totalCalories() {
      return '1550';
    },
    nutrition(parent: any) {
      if (!enabled) { return parent.nutrition; }
      return parent.id === cheeseModifierId ? [{ calories: 100 }] : parent.nutrition;
    },
    isPanCrust(parent: { id: string; isPanCrust: boolean }) {
      if (!enabled) return parent.isPanCrust;
      return (new ProductId(parent.id).globalId === originalPanCrustId);
    },
    internalName(parent: { name: string; internalName: string }) {
      if (!enabled) return parent.internalName;
      return parent.name.toLowerCase();
    }
  }
});

export { clientSideResolvers, clientSideTypeDefs };
