import { createSlice } from '@reduxjs/toolkit';
import { CheckoutStateType } from '@/clientCore/redux/checkout/type';
import { TIPS_PANEL } from '@/clientCore/checkout/components/PaymentOptions/TipsPanel/constants';
import { ApplyableGiftCard } from '@/clientCore/checkout/types';

const initialState: CheckoutStateType = {
  tipPercentage: TIPS_PANEL.customTipZeroPercent,
  totalWithTip: 0,
  appliedGiftCards: []
};

export const CheckoutStateSlice = createSlice({
  initialState,
  name: 'CheckoutState',
  reducers: {
    setTipValue: (
      state: CheckoutStateType,
      {
        payload: { tipValue }
      }: {
        payload: {
          tipValue: number;
        };
      }
    ) => ({
      ...state,
      tipValue
    }),
    setTipPercentage: (
      state: CheckoutStateType,
      {
        payload: { tipPercentage }
      }: {
        payload: {
          tipPercentage: string;
        };
      }
    ) => ({
      ...state,
      tipPercentage
    }),
    setTotalWithTip: (
      state: CheckoutStateType,
      {
        payload: { totalWithTip }
      }: {
        payload: {
          totalWithTip: number;
        };
      }
    ) => ({
      ...state,
      totalWithTip
    }),
    setAppliedGiftCards: (
      state: CheckoutStateType,
      {
        payload: { giftCard }
      }: {
        payload: {
          giftCard: ApplyableGiftCard;
        };
      }
    ) => ({
      ...state,
      appliedGiftCards: [...state.appliedGiftCards, giftCard]
    }),
    updateAppliedGiftCards: (
      state: CheckoutStateType,
      {
        payload: { giftCards }
      }: {
        payload: {
          giftCards: ApplyableGiftCard[];
        };
      }
    ) => ({
      ...state,
      appliedGiftCards: giftCards
    })
  }
});

export const checkoutStateReducer = CheckoutStateSlice.reducer;

export const {
  setTipValue,
  setTipPercentage,
  setTotalWithTip,
  updateAppliedGiftCards,
  setAppliedGiftCards
} = CheckoutStateSlice.actions;
